import { useContext, useMemo } from 'react';
import { BigNumber } from 'ethers';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import {
  oracleNativeBalance,
  calculateCollateral,
  borrowLimitPercentage,
  underlyingBalance,
  getNetRate,
  rateToApy,
} from 'cream/utils';
import { reduce } from 'lodash';

export type UserBorrowSummary = {
  totalBorrowBalanceInNative: BigNumber;
  borrowLimitPct: string;
  borrowLimitInNative: BigNumber;
  basePrice: number;
  netAPY: string;
};

export const useUserBorrowSummary = (): UserBorrowSummary => {
  const { protocol } = useContext(ProtocolContext);
  const { markets, allMarketStats, allUserTokenStats, basePrice } =
    useContext(MarketContext);

  const totalBorrowBalanceInNative = useMemo<BigNumber>(
    () =>
      reduce(
        markets,
        (acc, market, index) => {
          const userTokenStats = allUserTokenStats[index];
          const marketStats = allMarketStats[index];
          if (userTokenStats.borrowBalance.isZero()) {
            return acc;
          }

          const nativeBalance = oracleNativeBalance(
            userTokenStats.borrowBalance,
            marketStats.underlyingPrice
          );

          return acc.add(nativeBalance);
        },
        BigNumber.from(0)
      ),
    [allMarketStats, allUserTokenStats, markets]
  );

  const totalRateInNative = useMemo<BigNumber>(
    () =>
      reduce(
        markets,
        (acc, market, index) => {
          const userTokenStats = allUserTokenStats[index];
          const marketStats = allMarketStats[index];

          if (userTokenStats.borrowBalance.isZero()) {
            return acc;
          }

          const nativeBorrowBalance = oracleNativeBalance(
            userTokenStats.borrowBalance,
            marketStats.underlyingPrice
          );

          const nativeBorrowRate = nativeBorrowBalance.mul(
            marketStats.borrowRate
          );

          return acc.add(nativeBorrowRate);
        },
        BigNumber.from(0)
      ),
    [allMarketStats, allUserTokenStats, markets]
  );

  const borrowLimitInNative = useMemo<BigNumber>(
    () =>
      reduce(
        markets,
        (acc, market, index) => {
          const userTokenStats = allUserTokenStats[index];
          const marketStats = allMarketStats[index];
          if (
            !userTokenStats.collateralEnabled ||
            userTokenStats.crTokenBalance.eq(0)
          ) {
            return acc;
          }

          const collateralUnderlyingBalance = underlyingBalance(
            userTokenStats.collateralBalance,
            marketStats.exchangeRate
          );
          const nativeSupplyBalance = oracleNativeBalance(
            collateralUnderlyingBalance,
            marketStats.underlyingPrice
          );

          return acc.add(
            calculateCollateral(
              nativeSupplyBalance,
              marketStats.collateralFactor
            )
          );
        },
        BigNumber.from(0)
      ),
    [allMarketStats, allUserTokenStats, markets]
  );

  const borrowLimitPct = useMemo<string>(
    () =>
      borrowLimitPercentage(borrowLimitInNative, totalBorrowBalanceInNative),
    [borrowLimitInNative, totalBorrowBalanceInNative]
  );

  const netAPY = useMemo(() => {
    const netRate = getNetRate(totalBorrowBalanceInNative, totalRateInNative);
    return rateToApy(netRate, protocol.blocksPerYear, 2, true);
  }, [protocol.blocksPerYear, totalBorrowBalanceInNative, totalRateInNative]);

  return {
    totalBorrowBalanceInNative,
    borrowLimitPct,
    borrowLimitInNative,
    basePrice,
    netAPY,
  };
};

export default useUserBorrowSummary;
