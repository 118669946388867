import { Category } from '../Category';
import { Market } from '../Type';

const FantomMarkets: Market[] = [
  {
    name: 'Iron Bank Wrapped Fantom',
    address: '0xd528697008aC67A21818751A5e3c58C8daE54696',
    symbol: 'iWFTM',
    underlyingAddress: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    underlyingDecimal: 18,
    underlyingName: 'Fantom',
    underlyingSymbol: 'WFTM',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Ethereum',
    address: '0xcc3E89fBc10e155F1164f8c9Cf0703aCDe53f6Fd',
    symbol: 'iETH',
    underlyingAddress: '0x74b23882a30290451A17c44f4F05243b6b58C76d',
    underlyingDecimal: 18,
    underlyingName: 'Ethereum',
    underlyingSymbol: 'ETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Bitcoin',
    address: '0x20CA53E2395FA571798623F1cFBD11Fe2C114c24',
    symbol: 'iBTC',
    underlyingAddress: '0x321162Cd933E2Be498Cd2267a90534A804051b11',
    underlyingDecimal: 8,
    underlyingName: 'Bitcoin',
    underlyingSymbol: 'BTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Dai Stablecoin',
    address: '0x04c762a5dF2Fa02FE868F25359E0C259fB811CfE',
    symbol: 'iDAI',
    underlyingAddress: '0x8D11eC38a3EB5E956B052f67Da8Bdc9bef8Abf3E',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank USD Coin',
    address: '0x328A7b4d538A2b3942653a9983fdA3C12c571141',
    symbol: 'iUSDC',
    underlyingAddress: '0x04068DA6C83AFCFA0e13ba15A6696662335D5B75',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank yearn.finance',
    address: '0x0980f2F0D2af35eF2c4521b2342D59db575303F7',
    symbol: 'iYFI',
    underlyingAddress: '0x29b0Da86e484E1C0029B56e817912d778aC0EC69',
    underlyingDecimal: 18,
    underlyingName: 'yearn.finance',
    underlyingSymbol: 'YFI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Frapped USDT',
    address: '0x70faC71debfD67394D1278D98A29dea79DC6E57A',
    symbol: 'iFUSDT',
    underlyingAddress: '0x049d68029688eAbF473097a2fC38ef61633A3C7A',
    underlyingDecimal: 6,
    underlyingName: 'Frapped USDT',
    underlyingSymbol: 'FUSDT',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Magic Internet Money',
    address: '0x46F298D5bB6389ccb6C1366bB0C8a30892CA2f7C',
    symbol: 'iMIM',
    underlyingAddress: '0x82f0B8B456c1A451378467398982d4834b6829c1',
    underlyingDecimal: 18,
    underlyingName: 'Magic Internet Money',
    underlyingSymbol: 'MIM',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Frax',
    address: '0x2919Ec3e7B35fB0C8597A5f806fb1f59c540EAb4',
    symbol: 'iFRAX',
    underlyingAddress: '0xdc301622e621166BD8E82f2cA0A26c13Ad0BE355',
    underlyingDecimal: 18,
    underlyingName: 'Frax',
    underlyingSymbol: 'FRAX',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank TerraUSD',
    address: '0xf13252C1044Aa83b910C77322e67387E187F64cA',
    symbol: 'iUST',
    underlyingAddress: '0xe2D27f06F63d98b8e11b38b5b08A75D0c8dD62B9',
    underlyingDecimal: 6,
    underlyingName: 'TerraUSD',
    underlyingSymbol: 'UST',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Sushi',
    address: '0xB1FD648D8CA4bE22445963554b85AbbFC210BC83',
    symbol: 'iSUSHI',
    underlyingAddress: '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
    underlyingDecimal: 18,
    underlyingName: 'Sushi',
    underlyingSymbol: 'SUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Aave',
    address: '0x79EA17bEE0a8dcb900737E8CAa247c8358A5dfa1',
    symbol: 'iAAVE',
    underlyingAddress: '0x6a07A792ab2965C72a5B8088d3a069A7aC3a993B',
    underlyingDecimal: 18,
    underlyingName: 'Aave',
    underlyingSymbol: 'AAVE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank ChainLink',
    address: '0x4eCEDdF62277eD78623f9A94995c680f8fd6C00e',
    symbol: 'iLINK',
    underlyingAddress: '0xb3654dc3D10Ea7645f8319668E8F54d2574FBdC8',
    underlyingDecimal: 18,
    underlyingName: 'ChainLink',
    underlyingSymbol: 'LINK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Synthetix Network',
    address: '0x1cc6Cf8455f7783980B1ee06ecD4ED9acd94e1c7',
    symbol: 'iSNX',
    underlyingAddress: '0x56ee926bD8c72B2d5fa1aF4d9E4Cbb515a1E3Adc',
    underlyingDecimal: 18,
    underlyingName: 'Synthetix Network',
    underlyingSymbol: 'SNX',
    category: Category.gov,
    zeroAllowance: false,
  },
];

export default FantomMarkets;
