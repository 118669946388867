import { useMutation, useQueries } from '@tanstack/react-query';
import BN from 'bignumber.js';
import {
  IBRewardClaimable,
  IBStakingInfo,
  IBUserStaked,
  RewardRate,
} from 'cream/Type';
import { sameAddress, underlyingBalance } from 'cream/utils';
import { fromUnixTime, isPast } from 'date-fns';
import { BigNumber, ethers, utils } from 'ethers';
import { AlertSeverity, useAlert } from 'hooks/useAlert';
import { find, isEmpty, map, reduce, some } from 'lodash';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { CreamContext } from 'providers/CreamProvider';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import { useCallback, useContext, useMemo } from 'react';
import useIbPrice from './useIbPrice';

export type IBTokenRewardData = {
  tokenAddress: string;
  tokenDecimal: number;
  underlyingSymbol: string;
  underlyingDecimal: number;
  underlyingAddress: string;
  totalSupply: BigNumber;
  supplyRatePerBlock: BigNumber;
  exchangeRate: BigNumber;
  rewardRate: BigNumber;
  userStakedBalance: BigNumber;
  userStakedBalanceUSD: BN;
  userClaimableIBAmount: BN;
  userClaimableIBAmountInUSD: BN;
  stakingAPY: BN;
  nativeAPY: BN;
  netAPY: BN;
  isWrappedBaseAsset: boolean;
};

interface UseIBReward {
  rewardItems: {
    value: IBStakingInfo[];
    isFetching: boolean;
  };
  tokenRewardData: IBTokenRewardData[];
  allClaimableReward: {
    value: BN;
    valueInUSD: BN;
    isLoading: boolean;
  };
  isLoading: boolean;
  totalStakeBalance: BN;
  totalStakeBalanceInUSD: BN;
  totalNetAPY: BN;
  refresh: () => void;
  claimAllRewards: () => void;
  isClaimingAllRewards: boolean;
}

const rateToApy = (rate: BigNumber, blocksPerYear: number): BN => {
  if (!rate || !blocksPerYear) {
    return new BN(0);
  }
  const bn = BN.clone({ POW_PRECISION: 6 });
  return new bn(utils.formatEther(rate).toString())
    .plus(1)
    .pow(blocksPerYear)
    .minus(1);
};

export const formatAPY: (value: BN) => string = (value) =>
  value.multipliedBy(100).toNumber().toFixed(2) + '%';

const getRewardRateByAddress = (
  rewardRates: RewardRate[],
  tokenAddress: string
): BigNumber => {
  try {
    const matchReward = find(rewardRates, (rateItem) =>
      sameAddress(rateItem.rewardTokenAddress, tokenAddress)
    );
    return matchReward ? matchReward.rate : BigNumber.from(0);
  } catch (error) {
    return BigNumber.from(0);
  }
};

export const useIBReward = (): UseIBReward => {
  const { walletAddress } = useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);
  const { markets, allMarketStats } = useContext(MarketContext);
  const { cream } = useContext(CreamContext);
  const { addTx } = useContext(TxContext);
  const { showAlert } = useAlert();

  const fetchStakingRewardItems = useCallback(async (): Promise<
    IBStakingInfo[]
  > => {
    if (!cream) {
      return [];
    }

    return await cream.IBRewardStakingInfo();
  }, [cream]);

  const fetchUserStakedList = useCallback(async (): Promise<IBUserStaked[]> => {
    if (!cream || !walletAddress) {
      return [];
    }

    return await cream.IBRewardUserStaked(walletAddress);
  }, [cream, walletAddress]);

  const fetchTotalClaimableReward = useCallback(async (): Promise<BN> => {
    if (!cream || !walletAddress) {
      return new BN(0);
    }

    const claimableRewards: IBRewardClaimable[] =
      await cream.getClaimableIBRewards(walletAddress, [protocol.ibAddress]);

    if (!claimableRewards) {
      return new BN(0);
    }

    return reduce(
      claimableRewards,
      (acc, rewardClaimable) =>
        acc.plus(new BN(utils.formatUnits(rewardClaimable.amount, 18))),
      new BN(0)
    );
  }, [cream, protocol.ibAddress, walletAddress]);

  const { ibPrice, isLoadingIbPrice } = useIbPrice();

  const [
    { data: stakingRewardItems, isLoading: isLoadingStakingRewardItems },
    {
      data: userStakedList,
      isLoading: isLoadingUserStakedList,
      refetch: refetchUserBalance,
    },
    {
      data: totalClaimableIBReward,
      isLoading: isLoadingTotalClaimableIBReward,
      refetch: refetchTotalClaimableIBReward,
    },
  ] = useQueries({
    queries: [
      {
        queryKey: ['staking-info', protocol.networkId, walletAddress],
        queryFn: fetchStakingRewardItems,
        enabled: !!cream,
      },
      {
        queryKey: ['user-staked-list', protocol.networkId, walletAddress],
        queryFn: fetchUserStakedList,
        enabled: !!cream,
      },
      {
        queryKey: ['total-claimable-IBs', protocol.networkId, walletAddress],
        queryFn: fetchTotalClaimableReward,
        enabled: !!cream,
      },
    ],
  });

  const fetchRewardExpiredDates = useCallback(async (): Promise<
    { stakingToken: string; expiredAt: number | null }[]
  > => {
    if (isEmpty(stakingRewardItems) || !cream) {
      return [];
    }

    const results = await Promise.all(
      map(stakingRewardItems, async ({ stakingTokenAddress }) => {
        const expiredDate = await cream.IBRewardPeriodFinish(
          stakingTokenAddress,
          protocol.ibAddress
        );

        if (expiredDate === null || expiredDate.isZero()) {
          return {
            stakingToken: stakingTokenAddress,
            expiredAt: null,
          };
        }

        return {
          stakingToken: stakingTokenAddress,
          expiredAt: expiredDate.toNumber(),
        };
      })
    );

    return results;
  }, [cream, protocol.ibAddress, stakingRewardItems]);

  const [{ data: claimableIBs }, { data: expiredDates }] = useQueries({
    queries: [
      {
        queryKey: ['claimableIBs', stakingRewardItems, walletAddress],
        queryFn: async (): Promise<
          { stakingToken: string; amount: BigNumber }[]
        > => {
          if (!stakingRewardItems || !cream || !walletAddress) {
            return [];
          }

          return await Promise.all(
            map(stakingRewardItems, async ({ stakingTokenAddress }) => {
              const earned = await cream.IBRewardEarned(
                walletAddress,
                stakingTokenAddress,
                protocol.ibAddress
              );
              return {
                stakingToken: stakingTokenAddress,
                amount: earned,
              };
            })
          );
        },
        initialData: [],
        enabled: !!cream && !!stakingRewardItems && !!walletAddress,
      },
      {
        queryKey: ['expiredDates', stakingRewardItems],
        queryFn: fetchRewardExpiredDates,
        initialData: [],
        enabled: !!cream && !isEmpty(stakingRewardItems),
      },
    ],
  });

  const getStakingAPY = (
    ibPrice: number,
    rewardRate: BigNumber,
    totalSupply: BigNumber,
    supplyExchangeRate: BigNumber,
    supplyTokenUnderlyingPrice: BigNumber
  ): BN => {
    if (
      !ibPrice ||
      rewardRate.isZero() ||
      totalSupply.isZero() ||
      supplyExchangeRate.isZero() ||
      supplyTokenUnderlyingPrice.isZero()
    ) {
      return new BN(0);
    }

    const supplyUsdValue = new BN(totalSupply.toString())
      .multipliedBy(supplyExchangeRate.toString())
      .div(ethers.constants.WeiPerEther.toString())
      .multipliedBy(supplyTokenUnderlyingPrice.toString())
      .div(ethers.constants.WeiPerEther.toString())
      .div(ethers.constants.WeiPerEther.toString());

    if (supplyUsdValue.isZero()) {
      return new BN(0);
    }

    return new BN(rewardRate.toString())
      .div(ethers.constants.WeiPerEther.toString())
      .multipliedBy(365 * 86400)
      .multipliedBy(ibPrice)
      .div(supplyUsdValue);
  };

  const tokenRewardData = useMemo(() => {
    return reduce(
      stakingRewardItems,
      (
        acc: IBTokenRewardData[],
        {
          stakingTokenAddress,
          totalSupply,
          exchangeRate,
          supplyRatePerBlock,
          rewardRates,
        }
      ) => {
        const rewardRate = getRewardRateByAddress(
          rewardRates,
          protocol.ibAddress
        );

        const tokenInfo = find(markets, ({ address }) =>
          sameAddress(address, stakingTokenAddress)
        );

        const marketStat = find(allMarketStats, ({ address }) =>
          sameAddress(address, stakingTokenAddress)
        );

        const userClaimableIB = find(claimableIBs, (claimableIB) =>
          sameAddress(claimableIB.stakingToken, stakingTokenAddress)
        );

        const userStakedData = find(userStakedList, (stakeInfo) =>
          sameAddress(stakeInfo.stakingTokenAddress, stakingTokenAddress)
        );

        const expiredData = find(expiredDates, ({ stakingToken }) =>
          sameAddress(stakingToken, stakingTokenAddress)
        );

        const isExpired: boolean =
          !!expiredData &&
          expiredData.expiredAt !== null &&
          isPast(fromUnixTime(expiredData.expiredAt));

        const stakingAPY = isExpired
          ? new BN(0)
          : getStakingAPY(
              ibPrice || 0,
              rewardRate,
              totalSupply,
              marketStat?.exchangeRate || BigNumber.from(0),
              marketStat?.underlyingPrice || BigNumber.from(0)
            );

        const nativeAPY = rateToApy(supplyRatePerBlock, protocol.blocksPerYear);

        const userStakedBalance = userStakedData
          ? underlyingBalance(userStakedData.balance, exchangeRate)
          : BigNumber.from(0);

        const userStakedBalanceUSD =
          userStakedBalance && tokenInfo && marketStat
            ? new BN(
                utils.formatUnits(
                  userStakedBalance,
                  tokenInfo.underlyingDecimal
                )
              ).multipliedBy(
                utils.formatUnits(
                  marketStat.underlyingPrice,
                  36 - tokenInfo.underlyingDecimal
                )
              )
            : new BN(0);

        const userClaimableIBAmount = userClaimableIB
          ? new BN(utils.formatUnits(userClaimableIB?.amount, 18))
          : new BN(0);

        const userClaimableIBAmountInUSD = userClaimableIBAmount
          ? userClaimableIBAmount.multipliedBy(ibPrice || 0)
          : new BN(0);

        const temp: IBTokenRewardData = {
          tokenAddress: stakingTokenAddress,
          tokenDecimal: 0,
          underlyingSymbol: tokenInfo?.underlyingSymbol || '',
          underlyingDecimal: tokenInfo?.underlyingDecimal || 18,
          underlyingAddress: tokenInfo?.underlyingAddress || '',
          isWrappedBaseAsset: tokenInfo?.isWrappedBaseAsset || false,
          totalSupply,
          exchangeRate,
          supplyRatePerBlock,
          rewardRate,
          userClaimableIBAmount,
          userClaimableIBAmountInUSD,
          userStakedBalance,
          userStakedBalanceUSD,
          stakingAPY,
          nativeAPY,
          netAPY: stakingAPY.plus(nativeAPY),
        };
        return acc.concat(temp);
      },
      []
    );
  }, [
    allMarketStats,
    claimableIBs,
    ibPrice,
    markets,
    protocol.blocksPerYear,
    protocol.ibAddress,
    stakingRewardItems,
    userStakedList,
    expiredDates,
  ]);

  const totalStakeBalance = useMemo<BN>(() => {
    if (!tokenRewardData) {
      return new BN(0);
    }
    return reduce(
      tokenRewardData,
      (acc: BN, { userStakedBalance, underlyingDecimal }) => {
        const amount = utils.formatUnits(userStakedBalance, underlyingDecimal);
        return acc.plus(amount);
      },
      new BN(0)
    );
  }, [tokenRewardData]);

  const totalStakeBalanceInUSD = useMemo<BN>(() => {
    if (!tokenRewardData) {
      return new BN(0);
    }
    return reduce(
      tokenRewardData,
      (acc: BN, { userStakedBalanceUSD }) => {
        return acc.plus(userStakedBalanceUSD);
      },
      new BN(0)
    );
  }, [tokenRewardData]);

  const totalNetAPY = useMemo<BN>(() => {
    if (!tokenRewardData) {
      return new BN(0);
    }

    return reduce(
      tokenRewardData,
      (acc, { netAPY, userStakedBalanceUSD }) => {
        if (netAPY.isZero()) {
          return acc;
        }
        if (!totalStakeBalanceInUSD || totalStakeBalanceInUSD.isZero()) {
          return acc;
        }
        return acc.plus(
          netAPY
            .multipliedBy(userStakedBalanceUSD)
            .dividedBy(totalStakeBalanceInUSD)
            .shiftedBy(2)
        );
      },
      new BN(0)
    );
  }, [tokenRewardData, totalStakeBalanceInUSD]);

  const allClaimableReward = useMemo(() => {
    if (!totalClaimableIBReward || totalClaimableIBReward.isZero()) {
      return {
        value: new BN(0),
        valueInUSD: new BN(0),
        isLoading: isLoadingTotalClaimableIBReward,
      };
    }

    if (!ibPrice) {
      return {
        value: totalClaimableIBReward,
        valueInUSD: new BN(0),
        isLoading: isLoadingTotalClaimableIBReward,
      };
    }

    return {
      value: totalClaimableIBReward,
      valueInUSD: totalClaimableIBReward.multipliedBy(ibPrice),
      isLoading: isLoadingTotalClaimableIBReward,
    };
  }, [ibPrice, isLoadingTotalClaimableIBReward, totalClaimableIBReward]);

  const { mutate: claimAllRewards, isLoading: isClaimingAllRewards } =
    useMutation({
      mutationFn: async () => {
        if (!cream) {
          throw new Error('no cream');
        }
        return await cream.claimAllRewards();
      },
      onSuccess: async (data) => {
        if (!data) {
          return;
        }

        addTx(data.hash, `Claim all IB reward`);
        await data.wait(1);
        refetchTotalClaimableIBReward();
        refetchUserBalance();
      },
      onError: () =>
        showAlert({
          message: 'Claim all rewards Failed',
          severity: AlertSeverity.Error,
        }),
    });

  return {
    rewardItems: {
      value: stakingRewardItems || [],
      isFetching: isLoadingStakingRewardItems,
    },
    tokenRewardData,
    allClaimableReward,
    totalStakeBalance,
    totalStakeBalanceInUSD,
    totalNetAPY,
    isLoading: some([
      isLoadingStakingRewardItems,
      isLoadingUserStakedList,
      isLoadingTotalClaimableIBReward,
      isLoadingIbPrice,
    ]),
    refresh: refetchUserBalance,
    claimAllRewards,
    isClaimingAllRewards,
  };
};

export default useIBReward;
