import { BigNumber, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import PriceOracleABI from './ABIs/priceOracle';

class PriceOracle {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, PriceOracleABI, provider);
    this.address = address;
  }

  async getUnderlyingPrice(cToken: string): Promise<BigNumber> {
    return this.contract.getUnderlyingPrice(cToken);
  }
}

export default PriceOracle;
