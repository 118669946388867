import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import WithdrawModal from 'components/Modals/WithdrawModal';
import Table from 'components/Table';
import TokenIconSymbol from 'components/TokenIconSymbol';
import useMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import {
  UserSupplyMarketData,
  useUserSupplyMarketData,
} from 'hooks/useUserSupplyMarketData';
import React, { useMemo } from 'react';
import ApyPopover from './ApyPopover';
import CollateralSwitch from './CollateralSwitch';
import CollateralToolTip from './CollateralToolTip';
import StEthApyPopover, { isWstEthAddress } from './StEthApyPopover';
import UserMarket from './UserMarket';

export const UserSupplyMarket = () => {
  const { isMobile } = useMediaQuery();
  const { supplyData, isLoading } = useUserSupplyMarketData();
  const { presentModal } = useModal();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<UserSupplyMarketData>();
    return [
      columnHelper.accessor('asset', {
        header: 'Asset',
        cell: (props) => <TokenIconSymbol symbol={props.getValue()} />,
      }),
      columnHelper.accessor('apy', {
        header: 'APY',
        cell: ({
          row: {
            original: { apy, stakingRewardData, supplyRate, address },
          },
        }) => (
          <Stack spacing={1} alignItems="end">
            <Text>{apy}</Text>
            {stakingRewardData && <ApyPopover rewardData={stakingRewardData} />}
            {isWstEthAddress(address) && (
              <StEthApyPopover supplyOrBorrowRate={supplyRate} />
            )}
          </Stack>
        ),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('balance', {
        header: 'Balance',
        cell: (props) => props.getValue(),
        meta: { isNumeric: true },
      }),
      columnHelper.display({
        id: 'collateral',
        header: () => (
          <Box textAlign="center">
            Collateral
            <CollateralToolTip />
          </Box>
        ),
        cell: ({ row: { original } }) => (
          <Box
            textAlign="center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <CollateralSwitch
              enabled={original.collateral}
              marketAddress={original.address}
              disabled={original.disabled}
            />
          </Box>
        ),
      }),
      columnHelper.display({
        id: 'withdrawAction',
        header: '',
        cell: ({ row: { original } }) => (
          <Button
            size="xs"
            minW="5.25rem"
            onClick={() =>
              presentModal({
                children: (
                  <WithdrawModal
                    marketAddress={original.address}
                    isNative={false}
                  />
                ),
              })
            }
          >
            Withdraw
          </Button>
        ),
        meta: { isHidden: isMobile },
      }),
    ];
  }, [isMobile, presentModal]);

  return (
    <UserMarket
      header="Your Supplies"
      table={
        <Table
          columns={columns}
          data={supplyData}
          onRowClick={
            isMobile
              ? ({ original }) =>
                  presentModal({
                    children: (
                      <WithdrawModal
                        marketAddress={original.address}
                        isNative={false}
                      />
                    ),
                  })
              : undefined
          }
        />
      }
      isLoading={isLoading}
    />
  );
};

export default UserSupplyMarket;
