import { useMutation, useQueries } from '@tanstack/react-query';
import BN from 'bignumber.js';
import Erc20ABI from 'cream/contract/ABIs/erc20';
import VeIBABI from 'cream/contract/ABIs/veIB';
import VeIBClaimableABI from 'cream/contract/ABIs/veIBClaimable';
import MultiCall from 'cream/contract/MultiCall';
import { Call } from 'cream/contract/types';
import { sameAddress } from 'cream/utils';
import { fromUnixTime } from 'date-fns';
import { BigNumber, ethers } from 'ethers';
import { AlertSeverity, useAlert } from 'hooks/useAlert';
import { filter, find, isEmpty, map, some } from 'lodash';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { CreamContext } from 'providers/CreamProvider';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import { useCallback, useContext, useMemo } from 'react';
import useIbPrice from './useIbPrice';

export type VeIBClaimable = {
  address: string;
  symbol: string;
  decimals: number;
  token: string;
};

export type NftClaimable = {
  id: number;
  amount: BigNumber;
  amountInUSD?: BN;
};

export type UserVeIBClaimable = {
  nftClaimables: NftClaimable[];
} & VeIBClaimable;

export type VeIBStats = {
  userVeIBs: VeIB[];
  userClaimables: UserVeIBClaimable[];
};

export type VeIB = {
  tokenId: BigNumber;
  lockedEnd: Date;
  lockedIBBalance: BigNumber;
  veIBBalance: BigNumber;
};

export const useVeIB = () => {
  const { walletAddress, provider } = useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);
  const { addTx } = useContext(TxContext);
  const { cream } = useContext(CreamContext);
  const { markets, allMarketStats } = useContext(MarketContext);
  const { showAlert } = useAlert();
  const { veIBClaimables, veIBAddress, ibAddress } = protocol;

  const buildVeIBCall = useCallback(
    <T>(fn: string, params: T[]): Call => ({
      address: veIBAddress,
      abi: VeIBABI,
      fn,
      params,
    }),
    [veIBAddress]
  );

  const buildIBCall = useCallback(
    <T>(fn: string, params: T[]): Call => ({
      address: ibAddress || '',
      abi: Erc20ABI,
      fn,
      params,
    }),
    [ibAddress]
  );

  const fetchUserIBBalance = useCallback(async (): Promise<BigNumber> => {
    const multicall = new MultiCall(protocol.multiCallAddress, provider);
    if (!walletAddress) {
      return BigNumber.from(0);
    }
    const [[ibBalance]] = await multicall.executeCalls([
      buildIBCall('balanceOf', [walletAddress]),
    ]);

    return ibBalance;
  }, [buildIBCall, protocol.multiCallAddress, provider, walletAddress]);

  const fetchSupplyInfo = useCallback(async (): Promise<{
    totalIBLocked: BigNumber;
    totalSupply: BigNumber;
  }> => {
    const multicall = new MultiCall(protocol.multiCallAddress, provider);
    const [[totalIBLocked], [totalSupply]] = await multicall.executeCalls([
      buildVeIBCall('supply', []),
      buildVeIBCall('totalSupply', []),
    ]);

    return { totalIBLocked, totalSupply };
  }, [buildVeIBCall, protocol.multiCallAddress, provider]);

  const { ibPrice } = useIbPrice();

  const [
    { data: userVeIBList, isLoading: isLoadingUserVeIBList },
    { data: veIBSupplyInfo },
    { data: userIBBalance },
  ] = useQueries({
    queries: [
      {
        queryKey: ['user-veIBs', walletAddress],
        queryFn: async (): Promise<VeIB[]> => {
          if (!protocol.veIBAddress || !walletAddress) {
            return [];
          }
          const multicall = new MultiCall(protocol.multiCallAddress, provider);
          const [nftsLength] = await multicall.executeCalls([
            buildVeIBCall('balanceOf', [walletAddress]),
          ]);

          const arr = map(Array(parseInt(nftsLength)), (value, index) => index);

          return await Promise.all(
            map(arr, async (idx) => {
              const [[tokenIndex]] = await multicall.executeCalls([
                buildVeIBCall('tokenOfOwnerByIndex', [walletAddress, idx]),
              ]);
              const [locked, [lockValue]] = await multicall.executeCalls([
                buildVeIBCall('locked', [tokenIndex]),
                buildVeIBCall('balanceOfNFT', [tokenIndex]),
              ]);

              return {
                tokenId: tokenIndex,
                lockedEnd: fromUnixTime(locked.end),
                lockedIBBalance: locked.amount,
                veIBBalance: lockValue,
              };
            })
          );
        },
        enabled: !!cream && !!walletAddress && !!protocol.veIBAddress,
      },
      {
        queryKey: ['veIB-supply-info', protocol.networkId],
        queryFn: fetchSupplyInfo,
        enabled: !!cream,
      },
      {
        queryKey: ['ib-balance', walletAddress, protocol.networkId],
        queryFn: fetchUserIBBalance,
        enabled: !!walletAddress,
      },
    ],
  });

  const fetchClaimableItems = useCallback(async (): Promise<
    UserVeIBClaimable[]
  > => {
    const multicall = new MultiCall(protocol.multiCallAddress, provider);
    if (isEmpty(userVeIBList)) {
      return [];
    }
    const nftIds = map(userVeIBList, (veIB) => veIB.tokenId.toNumber());

    const allUserClaimableList: UserVeIBClaimable[] = await Promise.all(
      map(veIBClaimables, async ({ address, symbol, decimals, token }) => {
        const claimableAmounts = await multicall.executeCalls(
          map(nftIds, (id) => ({
            address,
            abi: VeIBClaimableABI,
            fn: 'claimable',
            params: [id],
          }))
        );

        const nftClaimables = map(nftIds, (id, index) => {
          const amount: BigNumber = claimableAmounts[index][0];
          if (token === ibAddress) {
            return {
              id,
              amount,
              amountInUSD: new BN(
                ethers.utils.formatUnits(amount, decimals)
              ).multipliedBy(ibPrice || 0),
            };
          }

          const marketData = find(markets, ({ address }) =>
            sameAddress(address, token)
          );

          const marketStatData = find(allMarketStats, ({ address }) =>
            sameAddress(address, token)
          );

          if (!marketData || !marketStatData) {
            return {
              id,
              amount,
              amountInUSD: undefined,
            };
          }
          const amountInUSD = new BN(ethers.utils.formatUnits(amount, decimals))
            .multipliedBy(
              ethers.utils.formatUnits(marketStatData.exchangeRate, 18)
            )
            .shiftedBy(decimals - marketData.underlyingDecimal)
            .multipliedBy(
              ethers.utils.formatUnits(marketStatData.underlyingPrice, 18)
            )
            .shiftedBy(marketData.underlyingDecimal - 18);

          return {
            id,
            amount,
            amountInUSD,
          };
        });

        return {
          address,
          token,
          symbol,
          decimals,
          nftClaimables,
        };
      })
    );
    // only keep claimable
    return filter(allUserClaimableList, ({ nftClaimables }) =>
      some(nftClaimables, ({ amount }) => amount.gt(0))
    );
  }, [
    allMarketStats,
    ibAddress,
    ibPrice,
    markets,
    protocol.multiCallAddress,
    provider,
    userVeIBList,
    veIBClaimables,
  ]);

  const [
    { data: userClaimables, isLoading: isLoadingUserClaimables, refetch },
  ] = useQueries({
    queries: [
      {
        queryKey: ['user-claimable-items', userVeIBList],
        queryFn: fetchClaimableItems,
        enabled: !!userVeIBList,
      },
    ],
  });

  const avgTimeLocked = useMemo<number>(() => {
    if (!veIBSupplyInfo || veIBSupplyInfo.totalIBLocked.isZero()) {
      return 0;
    }
    const avgYear = new BN(veIBSupplyInfo.totalSupply.toString())
      .div(veIBSupplyInfo.totalIBLocked.toString())
      .multipliedBy(4)
      .toNumber();
    return avgYear * 365 * 24 * 60 * 60;
  }, [veIBSupplyInfo]);

  const claimAll = useCallback(async () => {
    if (!cream) {
      return;
    }

    if (!userClaimables) {
      return;
    }

    const distributors = [];
    const tokenIds = [];

    for (const claimableItem of userClaimables) {
      for (const nftClaimables of claimableItem.nftClaimables) {
        if (nftClaimables.amount.gt(0)) {
          distributors.push(claimableItem.address);
          tokenIds.push(nftClaimables.id);
        }
      }
    }
    return await cream?.veIBClaim(distributors, tokenIds);
  }, [cream, userClaimables]);

  const { mutate: claimAllRewards, isLoading: isClaimingRewards } = useMutation(
    {
      mutationFn: claimAll,
      onSuccess: async (data) => {
        if (!data) {
          return;
        }
        addTx(data.hash, 'Claim veIB rewards');
        await data.wait();
        refetch();
      },
      onError: () =>
        showAlert({ message: 'Claim Failed', severity: AlertSeverity.Error }),
    }
  );
  return {
    userClaimables: userClaimables || [],
    isLoadingUserClaimables,
    userVeIBList: userVeIBList || [],
    isLoadingUserVeIBList,
    userIBBalance: userIBBalance || BigNumber.from(0),
    ibPrice: ibPrice || 0,
    avgTimeLocked,
    claimAllRewards,
    isClaimingRewards,
  };
};

export default useVeIB;
