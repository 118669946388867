import { Box, Button, Grid, GridItem, GridItemProps } from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Header,
  Row,
  RowData,
  Table as TableType,
  useReactTable,
} from '@tanstack/react-table';
import React, { Fragment } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

declare module '@tanstack/table-core' {
  interface ColumnMeta<TData extends RowData, TValue> {
    isNumeric?: boolean;
    isHidden?: boolean;
  }
}

export interface TableProps<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  // See https://github.com/TanStack/table/issues/4382
  columns: ColumnDef<T, any>[];
  data: T[];
  enableSorting?: boolean;
  enableExpanding?: boolean;
  onRowClick?: (row: Row<T>, table: TableType<T>) => void;
  renderSubcomponent?: (row: Row<T>) => React.ReactNode;
  templateColumns?: string;
  enableHoverBgColor?: boolean;
  headerProps?: GridItemProps;
  // control the padding on the left and right side of the table
  py?: number;
}

function Table<T>({
  columns,
  data,
  enableSorting = false,
  enableExpanding = false,
  onRowClick,
  renderSubcomponent,
  templateColumns,
  enableHoverBgColor,
  headerProps = {},
  py = 2,
}: TableProps<T>): JSX.Element {
  const table = useReactTable({
    data,
    columns,
    enableSorting,
    enableSortingRemoval: false,
    enableExpanding,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const renderHeader = (header: Header<T, unknown>): React.ReactNode => {
    if (header.isPlaceholder) return null;

    // if header is not sortable
    if (!header.column.getCanSort())
      return flexRender(header.column.columnDef.header, header.getContext());

    return (
      <Button
        variant="link"
        fontSize="inherit"
        fontWeight="inherit"
        color="inherit"
        textTransform="inherit"
        textDecoration="inherit"
        // isActive={!!header.column.getIsSorted()}
        onClick={() => header.column.toggleSorting()}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}{' '}
        {{
          asc: <FaCaretUp />,
          desc: <FaCaretDown />,
        }[header.column.getIsSorted() as string] ?? null}
      </Button>
    );
  };

  const cellPadding = 2;

  return (
    <Box maxW="full" overflow="auto">
      <Grid
        templateColumns={
          templateColumns ||
          `repeat(${columns.filter((c) => !c.meta?.isHidden).length}, auto)`
        }
      >
        {table.getHeaderGroups().map((headerGroup) => (
          <Box key={headerGroup.id} display="contents">
            {headerGroup.headers
              .filter((header) => !header.column.columnDef.meta?.isHidden)
              .map((header) => (
                <GridItem
                  key={header.id}
                  p={cellPadding}
                  color="gray.500"
                  alignSelf="center"
                  lineHeight="1rem"
                  fontWeight={700}
                  fontSize="xs"
                  textAlign={
                    header.column.columnDef.meta?.isNumeric ? 'end' : 'start'
                  }
                  _first={{ ps: py }}
                  _last={{ pe: py }}
                  {...headerProps}
                >
                  {renderHeader(header)}
                </GridItem>
              ))}
          </Box>
        ))}
        {table.getRowModel().rows.map((row) => {
          const isExpanded = row.getIsExpanded();
          return (
            <Fragment key={row.id}>
              <Box
                display="contents"
                cursor={onRowClick ? 'pointer' : 'unset'}
                onClick={() => onRowClick && onRowClick(row, table)}
                sx={{
                  '&:hover > *': {
                    bgColor: enableHoverBgColor
                      ? 'background.header'
                      : undefined,
                  },
                }}
              >
                {row
                  .getVisibleCells()
                  .filter((cell) => !cell.column.columnDef.meta?.isHidden)
                  .map((cell) => (
                    <GridItem
                      key={cell.id}
                      p={cellPadding}
                      display="flex"
                      alignSelf="stretch"
                      alignItems="center"
                      textAlign={
                        cell.column.columnDef.meta?.isNumeric ? 'end' : 'start'
                      }
                      fontWeight={500}
                      fontSize="md"
                      bgColor={isExpanded ? 'background.header' : 'unset'}
                      _first={{ ps: py }}
                      _last={{ pe: py }}
                    >
                      <Box w="full">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </Box>
                    </GridItem>
                  ))}
              </Box>
              {enableExpanding && isExpanded && renderSubcomponent && (
                <Box
                  display="contents"
                  bgColor={isExpanded ? 'background.header' : 'unset'}
                >
                  <GridItem
                    colSpan={row.getVisibleCells().length}
                    fontWeight={500}
                    fontSize="md"
                    bgColor={isExpanded ? 'background.header' : 'unset'}
                    _first={{ ps: py }}
                    _last={{ pe: py }}
                    p={2}
                  >
                    {renderSubcomponent(row)}
                  </GridItem>
                </Box>
              )}
            </Fragment>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Table;
