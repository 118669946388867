import { useBreakpointValue, StackDirection } from '@chakra-ui/react';

export const StackDirectionBreakPoint: StackDirection = [
  'column',
  'column',
  'column',
  'row',
];

export const SimpleGridBreakPoint = [1, 1, 1, 2];

export const useMediaQuery = () => {
  const isMobile = useBreakpointValue({
    base: true,
    xs: true,
    sm: true,
    md: true,
    lg: false,
  });
  return {
    isMobile,
  };
};

export default useMediaQuery;
