export const IBApiUrl = 'https://api.ib.xyz';
export const devIBApiUrl = 'https://api.ib.xyz';

export const IBMarketDataUrl = `${
  process.env.REACT_APP_TESTNET ? devIBApiUrl : IBApiUrl
}/api/v1/itoken`;
export const IBMarketChangeDataUrl = `${
  process.env.REACT_APP_TESTNET ? devIBApiUrl : IBApiUrl
}/api/v1/changes`;
export const IBMarketHistoryDataUrl = `${
  process.env.REACT_APP_TESTNET ? devIBApiUrl : IBApiUrl
}/api/v1/history`;
export const VeIbRewardAprDataUrl = `${IBApiUrl}/api/v1/fee-distribution-apr`;

export const BnbUsdPriceEndpoint =
  'https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd';
export const EthUsdPriceEndpoint =
  'https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd';
export const MaticUsdPriceEndpoint =
  'https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd';
export const CreamUsdPriceEndpoint =
  'https://api.coingecko.com/api/v3/simple/price?ids=cream-2&vs_currencies=usd';
export const FtmUsdPriceEndpoint =
  'https://api.coingecko.com/api/v3/simple/price?ids=fantom&vs_currencies=usd';
export const IbUSDPriceEndpoint =
  'https://api.coingecko.com/api/v3/simple/price?ids=iron-bank&vs_currencies=usd';

// Failure (uint256 error, uint256 info, uint256 detail)
export const failureSignature =
  '0x45b96fe442630264581b197e84bbada861235052c5a1aadfff9ea4e40a969aa0';

export const nativeAddress = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';

export const secondsPerYear = 31536000;

export const fourYearsInSecond = 4 * 365 * 86400;
