import { Box, Flex, Heading, Spinner } from '@chakra-ui/react';
import Announcement from 'components/Announcement';
import TopBar from 'components/Navigation/TopBar';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useIsIpBlocked } from '../hooks/useIsIpBlocked';

const Page = () => {
  const { data: isIpBlocked, isLoading } = useIsIpBlocked();

  if (isLoading) {
    return (
      <Flex h="100vh" alignItems="center" justifyContent="center">
        <Spinner />
      </Flex>
    );
  }

  if (isIpBlocked) {
    return (
      <Flex h="100vh" alignItems="center" justifyContent="center">
        <Heading textAlign="center">
          Sorry, our service is not offered in your region.
        </Heading>
      </Flex>
    );
  }

  return (
    <Box>
      <TopBar />
      <Box>
        <Box w="full" as="main">
          <Announcement />
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Page;
