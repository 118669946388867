import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import {
  commify,
  displayChange,
  displayFactor,
  getExpectedBorrowLimit,
  nativeBalanceToUsd,
  rateToApy,
  tokenPrice,
} from 'cream/utils';
import { ethers } from 'ethers';
import useCream from 'hooks/useCream';
import useMarketData from 'hooks/useMarketData';
import useModal from 'hooks/useModal';
import useUserBorrowSummary from 'hooks/useUserBorrowSummary';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import { useContext, useMemo, useState } from 'react';
import BalanceInput from '../BalanceInput';
import TokenIconSymbol from '../TokenIconSymbol';
import Block from './components/Block';
import ErrorMessage from './components/ErrorMessage';
import Info from './components/Info';

interface WithdrawModalProps {
  marketAddress: string;
  isNative: boolean;
}

const WithdrawModal = ({
  marketAddress,
  isNative,
}: WithdrawModalProps): JSX.Element => {
  const { protocol } = useContext(ProtocolContext);
  const { connected } = useContext(ConnectionContext);
  const { addTx } = useContext(TxContext);
  const { dismissModal } = useModal();

  const cream = useCream();
  const [market, marketStats, userTokenStats] = useMarketData(marketAddress);
  const [isWrapped, setIsWrapped] = useState<boolean>(!isNative);

  const [amount, setAmount] = useState('');
  const withdrawAmount = ethers.utils.parseUnits(
    amount || '0',
    market.underlyingDecimal
  );

  const userBorrowSummary = useUserBorrowSummary();
  const borrowLimitInUsd = nativeBalanceToUsd(
    userBorrowSummary.borrowLimitInNative,
    userBorrowSummary.basePrice,
    2
  );
  const newBorrowLimit = getExpectedBorrowLimit(
    userBorrowSummary,
    marketStats,
    userTokenStats,
    withdrawAmount,
    false
  );

  const withdraw = async () => {
    let response;
    const isNative: boolean = market.isWrappedBaseAsset === true && !isWrapped;
    if (withdrawAmount.eq(userTokenStats.underlyingBalance)) {
      // Withdraw full amount.
      response = await cream?.redeem(
        market,
        userTokenStats.crTokenBalance,
        isNative
      );
    } else {
      response = await cream?.redeemUnderlying(
        market,
        withdrawAmount,
        isNative
      );
    }
    if (response) {
      addTx(
        response.hash,
        `Withdraw ~${commify(amount)} ${
          isNative ? protocol.chainConfig.token : market.underlyingSymbol
        }`
      );
      dismissModal();
    }
  };

  const isReady = useMemo<boolean>(() => {
    if (withdrawAmount.lt(0)) {
      // Invalid withdraw amount.
      return false;
    }

    if (userTokenStats.underlyingBalance.lt(withdrawAmount)) {
      // Protocol balance is less than withdraw amount.
      return false;
    }

    if (
      newBorrowLimit.newBorrowBalanceInNative.gt(
        newBorrowLimit.newBorrowLimitInNative
      )
    ) {
      // Borrow limit is fully used.
      return false;
    }

    if (marketStats.cash.lt(withdrawAmount)) {
      // Insufficient cash.
      return false;
    }

    if (withdrawAmount.isZero()) {
      return false;
    }
    return true;
  }, [
    marketStats.cash,
    newBorrowLimit.newBorrowBalanceInNative,
    newBorrowLimit.newBorrowLimitInNative,
    userTokenStats.underlyingBalance,
    withdrawAmount,
  ]);

  const errorMessage = useMemo<string>(() => {
    if (withdrawAmount.lt(0)) {
      // Invalid withdraw amount.
      return 'Invalid Amount';
    }

    if (userTokenStats.underlyingBalance.lt(withdrawAmount)) {
      // Protocol balance is less than withdraw amount.
      return 'Insufficient Balance';
    }

    if (
      newBorrowLimit.newBorrowBalanceInNative.gt(
        newBorrowLimit.newBorrowLimitInNative
      )
    ) {
      // Borrow limit is fully used.
      return 'Insufficient Collateral';
    }

    if (marketStats.cash.lt(withdrawAmount)) {
      // Insufficient cash.
      return 'Insufficient Liquidity';
    }

    return '';
  }, [
    marketStats.cash,
    newBorrowLimit.newBorrowBalanceInNative,
    newBorrowLimit.newBorrowLimitInNative,
    userTokenStats.underlyingBalance,
    withdrawAmount,
  ]);

  return (
    <ModalContent>
      <ModalHeader>Withdraw</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={amount}
            symbol={market.underlyingSymbol}
            decimals={market.underlyingDecimal}
            maxBalance={userTokenStats.underlyingBalance}
            usdRate={tokenPrice(
              marketStats.underlyingPrice,
              market.underlyingDecimal,
              userBorrowSummary.basePrice
            )}
            onChange={setAmount}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {market.wrappedAssetSymbol && (
            <HStack>
              <Switch
                isChecked={!isWrapped}
                onChange={(e) => {
                  setIsWrapped(!e.target.checked);
                }}
              />
              <Text>Withdraw as</Text>
              <TokenIconSymbol symbol={market.wrappedAssetSymbol} />
            </HStack>
          )}
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Supply Stats
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Supply APY'}
                  value={rateToApy(
                    marketStats.supplyRate,
                    protocol.blocksPerYear
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Collateral
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Collateral Factor'}
                  value={displayFactor(marketStats.collateralFactor)}
                />
                <Info
                  title={'Borrow Limit Used'}
                  value={displayChange(
                    userBorrowSummary.borrowLimitPct,
                    newBorrowLimit.newBorrowLimitPct
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Stack spacing={4}>
            <Button
              disabled={!isReady || !connected}
              onClick={() => withdraw()}
            >
              Withdraw
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};

export default WithdrawModal;
