import { Flex, Spacer, HStack, Link, Button } from '@chakra-ui/react';
import { NavLink, useNavigate } from 'react-router-dom';
import { map } from 'lodash';
import { useNavigationItems } from 'hooks/useNavigationItems';
import NetworkSwitch from './NetworkSwitch';
import Wallet from 'components/Wallet';
import Logo from './Logo';

export type Nav = {
  title: string;
  link: string;
  isActive?: (pathName: string) => boolean;
  isExternal?: boolean;
};

interface DesktopNavBarProps {
  isHomePage?: boolean;
}

const DesktopNavBar = ({ isHomePage = false }: DesktopNavBarProps) => {
  const navigate = useNavigate();
  const navigationList = useNavigationItems(isHomePage);

  return (
    <Flex
      as="header"
      pos="sticky"
      top={0}
      zIndex={30}
      w="full"
      bgColor="background.header"
      px={6}
      py={4}
      alignItems="center"
      gap={1}
    >
      <Logo />
      <HStack as="nav" ml={20} spacing={10}>
        {map(navigationList, (nav) => {
          return nav.isExternal ? (
            <Link key={nav.title} variant="nav" href={nav.link} target="_blank">
              {nav.title}
            </Link>
          ) : (
            <Link key={nav.title} as={NavLink} variant="nav" to={nav.link} end>
              {nav.title}
            </Link>
          );
        })}
      </HStack>
      <Spacer />
      {isHomePage ? (
        <Button minW="8rem" onClick={() => navigate('/lending')}>
          APP
        </Button>
      ) : (
        <>
          <NetworkSwitch />
          <Wallet />
        </>
      )}
    </Flex>
  );
};

export default DesktopNavBar;
