import {
  Box,
  Container,
  StatGroup,
  HStack,
  Stack,
  StackDivider,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Text,
} from '@chakra-ui/react';
import BN from 'bignumber.js';
import useUserBorrowSummary from 'hooks/useUserBorrowSummary';
import useUserSupplySummary from 'hooks/useUserSupplySummary';
import { StackDirectionBreakPoint } from 'hooks/useMediaQuery';
import { useMemo } from 'react';
import { ProgressBar } from 'components/ProgressBar';
import { nativeToUSD } from 'cream/utils';

export const StatusBanner = () => {
  const {
    totalSupplyBalanceInNative,
    netAPY,
    basePrice: supplyBasePrice,
  } = useUserSupplySummary();
  const {
    borrowLimitInNative,
    basePrice: borrowBasePrice,
    totalBorrowBalanceInNative,
  } = useUserBorrowSummary();

  const borrowLimitUsed = useMemo<number>(() => {
    if (borrowLimitInNative.isZero()) {
      return 0;
    }
    const percentage = new BN(totalBorrowBalanceInNative.toString())
      .dividedBy(borrowLimitInNative.toString())
      .shiftedBy(2)
      .dp(2)
      .toNumber();

    return percentage;
  }, [borrowLimitInNative, totalBorrowBalanceInNative]);

  return (
    <Box bgColor="background.dark" w="full">
      <Container maxW="container.lg">
        <Stack
          py={10}
          direction={StackDirectionBreakPoint}
          alignItems="flex-start"
          justify="space-between"
          divider={<StackDivider borderColor="gray.700" />}
          columnGap={14}
          rowGap={6}
        >
          <StatGroup
            flexGrow={1}
            flexShrink={2}
            justifyContent="space-between"
            gap={8}
            rowGap={6}
          >
            <Stat flexGrow={0}>
              <StatLabel>Net APY</StatLabel>
              <StatNumber>
                {netAPY}
                <Text as="span" fontSize="md">
                  %
                </Text>
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Supply Balance</StatLabel>
              <StatNumber>
                <Text as="span" fontSize="md">
                  $
                </Text>
                {nativeToUSD(totalSupplyBalanceInNative, supplyBasePrice, 2)}
              </StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Borrow Balance</StatLabel>
              <StatNumber>
                <Text as="span" fontSize="md">
                  $
                </Text>
                {nativeToUSD(totalBorrowBalanceInNative, borrowBasePrice)}
              </StatNumber>
            </Stat>
          </StatGroup>
          <StatGroup
            flexGrow={1}
            flexShrink={1}
            maxW={{ base: undefined, lg: '350px' }}
            minW="250px"
            w="full"
          >
            <Stat flexGrow={1}>
              <StatLabel>Borrow Limit Used</StatLabel>
              <StatNumber>
                <HStack justifyContent="space-between" alignItems="flex-end">
                  <Box>{borrowLimitUsed}%</Box>
                  <Box>
                    <Text variant="statCaption">Borrow Limit:</Text>
                    <Text variant="statCaption">
                      ${nativeToUSD(borrowLimitInNative, borrowBasePrice, 2)}
                    </Text>
                  </Box>
                </HStack>
              </StatNumber>
              <StatHelpText mt={2}>
                <ProgressBar
                  value={borrowLimitUsed}
                  references={[{ label: 'Safe Limit', value: 80 }]}
                />
              </StatHelpText>
            </Stat>
          </StatGroup>
        </Stack>
      </Container>
    </Box>
  );
};

export default StatusBanner;
