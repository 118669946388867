import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  container: {},
  thumb: {},
  track: {
    bg: 'gray.700',
    _checked: {
      bg: 'background.greenLinear',
    },
  },
});

export default defineMultiStyleConfig({ baseStyle });
