import React, { useState, useContext, useEffect, useMemo } from 'react';
import { Flex, Icon, Box, Text, Stack, HStack } from '@chakra-ui/react';
import { isPast } from 'date-fns';
import { filter, includes, isEmpty, map } from 'lodash';
import { FantomProtocol } from 'cream/Protocols';
import { FaTimes } from 'react-icons/fa';
import { ProtocolContext } from 'providers/ProtocolProvider';

type AnnouncementObject = {
  id: string;
  content: React.ReactNode;
  expires?: Date;
  networks: number[];
};

const announcements: AnnouncementObject[] = [
  {
    id: 'fantom-pause',
    content: (
      <Text>Supply and borrow are paused on Fantom until further notice</Text>
    ),
    networks: [FantomProtocol.networkId],
  },
];

const Announcement = () => {
  const { protocol } = useContext(ProtocolContext);
  const [dismissed, setDismissed] = useState<string[]>([]);

  useEffect(() => {
    const dismissedAnnouncements = JSON.parse(
      window.localStorage.getItem('dismissed_announce') || '[]'
    );
    if (!isEmpty(dismissedAnnouncements)) {
      setDismissed(dismissedAnnouncements);
    }
  }, []);

  const dismiss = (dismissId: string) => {
    const newDismissed = dismissed.concat(dismissId);
    setDismissed(newDismissed);
    window.localStorage.setItem(
      'dismissed_announce',
      JSON.stringify(newDismissed)
    );
  };

  const filteredAnnouncements = useMemo(
    () =>
      filter(announcements, ({ id, expires, networks }) => {
        if (includes(dismissed, id)) {
          return false;
        }
        if (expires && isPast(expires)) {
          return false;
        }

        if (!isEmpty(networks) && !includes(networks, protocol.networkId)) {
          return false;
        }
        return true;
      }),
    [dismissed, protocol.networkId]
  );

  return isEmpty(filteredAnnouncements) ? null : (
    <Flex
      alignItems="center"
      justifyContent="center"
      bgColor="#cdc5ff"
      w="full"
      color="black"
      px={2}
      py={6}
    >
      <Stack>
        {map(filteredAnnouncements, ({ content, id }) => (
          <HStack key={id}>
            <Box>{content}</Box>
            <Icon
              as={FaTimes}
              color="black"
              onClick={() => dismiss(id)}
              right={2}
              position="absolute"
              cursor="pointer"
            />
          </HStack>
        ))}
      </Stack>
    </Flex>
  );
};

export default Announcement;
