import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { BigNumber } from 'ethers';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';

const useNativeBalance = () => {
  const { provider, walletAddress, networkId, connected } =
    useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);

  return useQuery<BigNumber>(
    ['native-balance', networkId, walletAddress],
    async (): Promise<BigNumber> => {
      if (!walletAddress) {
        return BigNumber.from(0);
      }
      return await provider.getBalance(walletAddress);
    },
    {
      enabled: connected,
      refetchInterval: protocol.refreshRate * 1000,
    }
  );
};

export default useNativeBalance;
