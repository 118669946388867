import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    '.react-calendar': {
      bgColor: 'background.dark',
      color: 'gray.100',
      button: {
        _disabled: {
          bgColor: 'gray.900',
          _hover: {
            bgColor: 'gray.900',
          },
        },
        _hover: {
          bgColor: 'primary.500',
        },
      },
      '.react-calendar__tile--active': {
        bgColor: 'primary.700',
      },
    },
  },
});
