import { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface VeIBCardProps {
  isLoading?: boolean;
  children: ReactNode;
}
export const VeIBCard = ({ children }: VeIBCardProps) => {
  return (
    <Box bgColor="background.darkGreen" p={6}>
      {children}
    </Box>
  );
};
export default VeIBCard;
