import { useState, useContext, useCallback, useMemo } from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Stack,
  Text,
} from '@chakra-ui/react';
import BN from 'bignumber.js';
import { useQuery } from '@tanstack/react-query';
import { BigNumber, ethers } from 'ethers';
import { isAfter, differenceInSeconds } from 'date-fns';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { TxContext } from 'providers/TxProvider';
import { VeIB } from 'hooks/useVeIB';
import useErc20Approve, { ApproveState } from 'hooks/useErc20Approve';
import useCream from 'hooks/useCream';
import { useAlert, AlertSeverity } from 'hooks/useAlert';
import useModal from 'hooks/useModal';
import VeIBStakeDurationPicker from 'components/VeIBStakeDurationPicker';
import BalanceInput from 'components/BalanceInput';
import NumberedButton from 'components/NumberedButton';
import Block from './components/Block';
import Info from './components/Info';
import ErrorMessage from './components/ErrorMessage';
import { displayBalance, getStakeRatioByLockTime } from 'cream/utils';

interface VeIBManageModalProps {
  veIBStats: VeIB;
  ibPrice: number;
  userIBBalance: BigNumber;
}

function VeIBManageModal(props: VeIBManageModalProps): JSX.Element {
  const { veIBStats, userIBBalance, ibPrice } = props;
  const { protocol } = useContext(ProtocolContext);
  const { connected } = useContext(ConnectionContext);
  const { addTx } = useContext(TxContext);
  const { approveState, approveAll, allowance } = useErc20Approve(
    protocol.ibAddress,
    protocol.veIBAddress
  );
  const { dismissModal } = useModal();
  const cream = useCream();
  const { showAlert } = useAlert();
  const [stakeAmount, setStakeAmount] = useState<string>('0');
  const [lockTime, setLockTime] = useState<Date>(veIBStats.lockedEnd);

  const amount = useMemo(
    () => ethers.utils.parseUnits(stakeAmount || '0', 18),
    [stakeAmount]
  );

  const { data: veIBRatio } = useQuery<number>(
    ['ratio', lockTime],
    () => getStakeRatioByLockTime(lockTime),
    { refetchInterval: 1000, initialData: 0 }
  );

  const increaseStakeAmount = useCallback(
    async (amount: BigNumber) => {
      try {
        if (!cream) {
          throw new Error('No increase veIB amount function');
        }

        const response = await cream?.veIBIncreaseAmount(
          veIBStats.tokenId,
          amount
        );

        if (response) {
          addTx(
            response.hash,
            `Stake ${new BN(amount.toString()).toFormat(4)} IB to veIB`
          );
        }
      } catch (error) {
        console.error(error);
        showAlert({
          message: 'Increase Stake Amount Failed',
          severity: AlertSeverity.Error,
        });
      }
    },
    [addTx, cream, showAlert, veIBStats.tokenId]
  );

  const increaseLockTime = useCallback(
    async (lockTime: Date) => {
      try {
        if (!cream) {
          throw new Error('No increase veIB lock time function');
        }

        const response = await cream?.veIBIncreaseUnlockTime(
          veIBStats.tokenId,
          differenceInSeconds(lockTime, new Date())
        );
        if (response) {
          addTx(response.hash, `Increase veIB lock time`);
        }
      } catch (error) {
        console.error(error);
        showAlert({
          message: 'Increase lock lime Failed',
          severity: AlertSeverity.Error,
        });
      }
    },
    [addTx, cream, showAlert, veIBStats.tokenId]
  );

  const handleConfirm = useCallback(async () => {
    if (!amount.isZero()) {
      await increaseStakeAmount(amount);
    }

    if (isAfter(lockTime, veIBStats.lockedEnd)) {
      await increaseLockTime(lockTime);
    }

    dismissModal();
  }, [
    dismissModal,
    increaseLockTime,
    increaseStakeAmount,
    lockTime,
    amount,
    veIBStats.lockedEnd,
  ]);

  const errorMessage = useMemo<string>(() => {
    if (amount.lt(0)) {
      return 'Invalid Amount';
    }

    if (userIBBalance.lt(amount)) {
      return 'Insufficient Balance';
    }

    if (allowance.lt(amount)) {
      return 'Insufficient Allowance';
    }
    return '';
  }, [allowance, amount, userIBBalance]);

  return (
    <ModalContent>
      <ModalHeader>Manage veIB Lock</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={stakeAmount}
            maxBalance={userIBBalance}
            symbol="IB"
            decimals={18}
            onChange={setStakeAmount}
            usdRate={ibPrice}
          />
          <VeIBStakeDurationPicker lockDate={lockTime} onChange={setLockTime} />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Stake stats
              </Text>
              <Stack spacing={2}>
                <Info
                  title="Staked Balance"
                  value={`${displayBalance(
                    veIBStats.lockedIBBalance,
                    18,
                    4
                  )} IB`}
                />
                <Info
                  title="Mint ratio"
                  value={`~${veIBRatio.toFixed(3)} veIB per IB`}
                />
                <Info
                  title="Total veIB"
                  value={`${displayBalance(veIBStats.veIBBalance, 18, 4)} veIB`}
                />
              </Stack>
            </Stack>
          </Block>
          <Stack spacing={4}>
            <NumberedButton
              n={1}
              onClick={() => approveAll()}
              disabled={
                (approveState === ApproveState.APPROVED &&
                  allowance.gte(amount)) ||
                !connected
              }
            >
              Approve
            </NumberedButton>
            <NumberedButton
              n={2}
              onClick={() => handleConfirm()}
              disabled={approveState !== ApproveState.APPROVED || !connected}
            >
              Confirm
            </NumberedButton>
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
}

export default VeIBManageModal;
