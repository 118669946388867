import { JsonRpcProvider } from '@ethersproject/providers';
import type { Chain } from '@web3-onboard/common';
import AvalancheIcon from 'assets/img/token/avax.svg';
import FantomIcon from 'assets/img/token/ftm.svg';
import OptimismIcon from 'assets/img/token/op.svg';
import EthereumIcon from 'assets/img/token/weth.png';
import OptimismMarkets from 'cream/markets/optimism';
import { VeIBClaimable } from 'hooks/useVeIB';
// import OptimismKovanMarkets from 'cream/markets/optimismKovan';
import AvalancheMarkets from './markets/avalanche';
import FantomMarkets from './markets/fantom';
import IronBankMarkets from './markets/ironbank';
import { LongTermPool, Market } from './Type';

export type Protocol = {
  name: string;
  networkId: number;
  defaultProvider: JsonRpcProvider;
  blocksPerYear: number;
  refreshRate: number;
  markets: Market[];
  lpMarkets: Market[];
  comptrollerName: string;
  explorer: string;
  getBasePrice: () => Promise<number>;

  ibAddress: string;
  ibLayerZeroBridgeAddress: string;
  compoundLensAddress: string;
  comptrollerAddress: string;
  multiCallAddress: string;
  maximillionAddress: string;
  chainConfig: Chain;
  sushiAddress: string;
  pancakeAddress: string;
  longTermPools: LongTermPool[];
  nativeContractAddress: string;

  liquidityMiningAddress: string;
  liquidityMiningRewardAddress: string;
  theGraphApi: string;

  veIBAddress: string;
  veIBClaimables: VeIBClaimable[];
  veIBClaimHelper: string;
  stakingRewardHelperAddress: string;
  stakingRewardFactoryAddress: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
};

export const IronBankProtocol: Protocol = {
  name: 'Ethereum',
  networkId: 1,
  defaultProvider: new JsonRpcProvider('https://cloudflare-eth.com/'),
  blocksPerYear: 2628000,
  refreshRate: 15,
  markets: IronBankMarkets,
  lpMarkets: [],
  comptrollerName: 'ethereum',
  explorer: 'https://etherscan.io',
  getBasePrice: async () => 1,
  ibAddress: '',
  ibLayerZeroBridgeAddress: '',
  compoundLensAddress: '0x328A7b4d538A2b3942653a9983fdA3C12c571141',
  comptrollerAddress: '0xAB1c342C7bf5Ec5F02ADEA1c2270670bCa144CbB',
  multiCallAddress: '0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441',
  maximillionAddress: '',
  chainConfig: {
    id: '0x1',
    label: 'Ethereum Mainnet',
    rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    token: 'ETH',
  },
  nativeContractAddress: '',
  sushiAddress: '0x6b3595068778dd592e39a122f4f5a5cf09c90fe2',
  pancakeAddress: '',
  longTermPools: [],
  liquidityMiningAddress: '',
  liquidityMiningRewardAddress: '',
  theGraphApi:
    'https://gateway-arbitrum.network.thegraph.com/api/6bee5a4b87e6f1624ca8098b0308fe0f/subgraphs/id/5XDK77KqWtqEBmR1xoMKyypK7RkgPvK61c1yNw3j8SYs',
  veIBAddress: '',
  veIBClaimables: [],
  veIBClaimHelper: '',
  stakingRewardHelperAddress: '',
  stakingRewardFactoryAddress: '',
  icon: EthereumIcon,
};

export const FantomProtocol: Protocol = {
  name: 'Fantom',
  networkId: 250,
  defaultProvider: new JsonRpcProvider('https://rpc.ftm.tools/'),
  blocksPerYear: 31536000,
  refreshRate: 10,
  markets: FantomMarkets,
  lpMarkets: [],
  comptrollerName: 'fantom',
  explorer: 'https://ftmscan.com',
  getBasePrice: async () => 1,
  ibAddress: '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
  ibLayerZeroBridgeAddress: '0x15aB2Cc164d7817Ac2a5b2A6362AbeE088B7F2df',
  compoundLensAddress: '0xCEb1cE674f38398432d20bc8f90345E91Ef46fd3',
  comptrollerAddress: '0x4250A6D3BD57455d7C6821eECb6206F507576cD2',
  multiCallAddress: '0x11e516AA99eD87D3076a5e5Fc842FA63e181F4C2',
  maximillionAddress: '',
  chainConfig: {
    id: '0xfa',
    label: 'Fantom Opera',
    rpcUrl: 'https://rpc.ftm.tools/',
    token: 'FTM',
  },
  sushiAddress: '0xae75A438b2E0cB8Bb01Ec1E1e376De11D44477CC',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '0xa9d61326709B5C2D5897e0753998DFf7F1e974Fe',
  liquidityMiningRewardAddress: '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
  theGraphApi:
    'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-portfolio-fantom',
  veIBAddress: '0xBe33aD085e4a5559e964FA8790ceB83905062065',
  veIBClaimables: [
    {
      address: '0x31A1D83C715F4bd6fE7A26f1Ce279Cec15011AE9',
      token: '',
      symbol: 'iUSDC',
      decimals: 8,
    },
    {
      address: '0x3Af7c11d112C1C730E5ceE339Ca5B48F9309aCbC',
      token: '',
      symbol: 'IB',
      decimals: 18,
    },
    {
      address: '0xB634c662296a4BA117A422bFE6742B75989Bd714',
      token: '',
      symbol: 'SOLID',
      decimals: 18,
    },
  ],
  veIBClaimHelper: '0x9c8264Cb356377Bb1d263f6e0EE03E977b3CAdAB',
  stakingRewardHelperAddress: '0x874C01c2d1767EFA01Fa54b2Ac16be96fAd5a742',
  stakingRewardFactoryAddress: '0x17533a1bDe957979E3977EbbFBC31E6deeb25C7d',
  icon: FantomIcon,
};

export const AvalancheProtocol: Protocol = {
  name: 'Avalanche',
  networkId: 43114,
  defaultProvider: new JsonRpcProvider('https://api.avax.network/ext/bc/C/rpc'),
  blocksPerYear: 31536000,
  refreshRate: 10,
  markets: AvalancheMarkets,
  lpMarkets: [],
  comptrollerName: 'avalanche',
  explorer: 'https://cchain.explorer.avax.network',
  getBasePrice: async () => 1,
  ibAddress: '',
  ibLayerZeroBridgeAddress: '',
  compoundLensAddress: '0x5b4058A9000e86fe136Ac896352C4DFD539E32a1',
  comptrollerAddress: '0x2eE80614Ccbc5e28654324a66A396458Fa5cD7Cc',
  multiCallAddress: '0xa00FB557AA68d2e98A830642DBbFA534E8512E5f',
  maximillionAddress: '0x2429ba194A2eC3B55F30E143DcC206DbEdB5845C',
  chainConfig: {
    id: '0xa86a',
    label: 'Avalanche Mainnet',
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    token: 'AVAX',
  },
  sushiAddress: '',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '',
  liquidityMiningRewardAddress: '',
  theGraphApi:
    'https://api.thegraph.com/subgraphs/name/creamfinancedev/cream-avalanche',
  veIBAddress: '',
  veIBClaimables: [],
  veIBClaimHelper: '',
  stakingRewardHelperAddress: '',
  stakingRewardFactoryAddress: '',
  icon: AvalancheIcon,
};

export const OptimismProtocol: Protocol = {
  name: 'Optimism',
  networkId: 10,
  defaultProvider: new JsonRpcProvider(
    'https://rpc.ankr.com/optimism/9c8bbf51eff4c9c81fa7c77b23b7efa23ae6baf8e83a0fac84187d4d79d65569'
  ),
  blocksPerYear: 31536000,
  refreshRate: 5,
  markets: OptimismMarkets,
  lpMarkets: [],
  comptrollerName: 'optimism',
  explorer: 'https://optimistic.etherscan.io/',
  getBasePrice: async () => 1,
  ibAddress: '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
  ibLayerZeroBridgeAddress: '',
  compoundLensAddress: '0xc640A79205706062Bf0277A466821A6130A8f78E',
  comptrollerAddress: '0xE0B57FEEd45e7D908f2d0DaCd26F113Cf26715BF',
  multiCallAddress: '0xeAa6877139d436Dc6d1f75F3aF15B74662617B2C',
  maximillionAddress: '0x2aC63723a576f89b628D514Ff671300801dc1702',
  chainConfig: {
    id: '0xa',
    label: ' Optimism',
    rpcUrl: 'https://mainnet.optimism.io',
    token: 'ETH',
  },
  sushiAddress: '',
  pancakeAddress: '',
  longTermPools: [],
  nativeContractAddress: '',
  liquidityMiningAddress: '0x9c8264Cb356377Bb1d263f6e0EE03E977b3CAdAB',
  liquidityMiningRewardAddress: '',
  theGraphApi:
    'https://api.thegraph.com/subgraphs/name/ibdotxyz/iron-bank-optimism',
  veIBAddress: '0x707648dfbf9df6b0898f78edf191b85e327e0e05',
  veIBClaimables: [
    {
      address: '0xFdE79c1e8510eE19360B71f2561766Cf2C757Fc7',
      token: '0x1d073cf59ae0c169cbc58b6fdd518822ae89173a',
      symbol: 'iUSDC',
      decimals: 8,
    },
    {
      address: '0x5402508a800db6b72792b80623193e38839a9e24',
      token: '0x00a35FD824c717879BF370E70AC6868b95870Dfb',
      symbol: 'IB',
      decimals: 18,
    },
  ],
  veIBClaimHelper: '0xB795d08676e70f09E3217EB25D742D4ee6e987e0',
  stakingRewardHelperAddress: '0x4edA3E4F9E42A7DD5c853E8b857271fae88E7209',
  stakingRewardFactoryAddress: '0x35F70CE60f049A8c21721C53a1dFCcB5bF4a1Ea8',
  icon: OptimismIcon,
};

// export const OptimismKovanProtocal: Protocol = {
//   name: 'Optimism Kovan',
//   networkId: 69,
//   defaultProvider: new JsonRpcProvider(
//     'https://opt-kovan.g.alchemy.com/v2/ldNd-vXpjCcqWGLJp2wywRtV9LZVL8JH'
//   ),
//   blocksPerYear: 31536000,
//   refreshRate: 15,
//   // TODO: replace with correct value
//   markets: OptimismKovanMarkets,
//   lpMarkets: [],
//   comptrollerName: 'optimism',
//   explorer: 'https://kovan-optimistic.etherscan.io/',
//   // TODO: replace with correct value
//   getBasePrice: async () => 1,
//   // TODO: replace with correct value
//   ibAddress: '',
//   compoundLensAddress: '0x1866Df5C255b86C35E674E87241DB9CD95F58639',
//   comptrollerAddress: '0x45554FB30706bEec25d1F0eC4323F7346e52F302',
//   multiCallAddress: '0x91c88479F21203444D2B20Aa001f951EC8CF2F68',
//   maximillionAddress: '',
//   chainConfig: {
//     id: '0x45',
//     label: ' Optimistic Kovan',
//     rpcUrl: 'https://kovan.optimism.io/',
//     token: 'ETH',
//   },
//   sushiAddress: '',
//   pancakeAddress: '',
//   longTermPools: [],
//   nativeContractAddress: '',
//   liquidityMiningAddress: '',
//   liquidityMiningRewardAddress: '',
//   // TODO: replace with correct value
//   theGraphApi: '',
//   veIBAddress: '',
//   veIBClaimables: [],
//   veIBClaimHelper: '',
//   stakingRewardHelperAddress: '',
//   stakingRewardFactoryAddress: '',
// };

export const ProductionProtocols = [
  IronBankProtocol,
  FantomProtocol,
  AvalancheProtocol,
  OptimismProtocol,
];

export const DevProtocols = [
  IronBankProtocol,
  FantomProtocol,
  AvalancheProtocol,
  OptimismProtocol,
  // OptimismKovanProtocal,
];

export function getProtocolByName(name: string): Protocol | undefined {
  const protocolList =
    process.env.REACT_APP_TESTNET === 'production'
      ? ProductionProtocols
      : DevProtocols;
  return protocolList.find((p) => p.name === name);
}

export function getProtocolNetworkIdHex(protocol: Protocol): string {
  return '0x' + protocol.networkId.toString(16);
}

export const getNetworkIdByNumber = (id: number): string =>
  `0x${id.toString(16)}`;

export const getNetworkIdByHex = (id: string): number => parseInt(id, 16);

export const getProtocols = (): Protocol[] =>
  process.env.REACT_APP_TESTNET === 'production'
    ? ProductionProtocols
    : DevProtocols;

export function onBoardChains(): Chain[] {
  const protocolList =
    process.env.REACT_APP_TESTNET === 'production'
      ? ProductionProtocols
      : DevProtocols;
  return protocolList.map((p) => {
    return p.chainConfig;
  });
}
