import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export function useIsIpBlocked() {
  return useQuery(
    ['is-taiwan-ip'],
    async () => {
      const { status } = await axios({
        method: 'get',
        url: 'https://testip.ib.xyz/',
        validateStatus: (status) => status < 500,
      });

      return status === 403;
    },
    { cacheTime: Infinity }
  );
}
