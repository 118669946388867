import { BigNumber, Contract, ethers, Signer } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider } from '@ethersproject/providers';
import { GAS_MULTIPLIER } from 'cream/contract/constants';
import Erc20ABI from './ABIs/erc20';

class Erc20 {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, Erc20ABI, provider);
    this.address = address;
  }

  connect(signer: Signer): void {
    this.contract = this.contract.connect(signer);
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.totalSupply();
  }

  async approve(
    spender: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.approve(spender, amount);
    return this.contract.approve(spender, amount, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async approveAll(spender: string): Promise<TransactionResponse> {
    return this.approve(spender, ethers.constants.MaxUint256);
  }

  async transfer(
    recipient: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    return this.contract.transfer(recipient, amount);
  }

  async allowance(owner: string, spender: string): Promise<BigNumber> {
    return this.contract.allowance(owner, spender);
  }

  async balanceOf(account: string): Promise<BigNumber> {
    return this.contract.balanceOf(account);
  }

  async decimals(): Promise<number> {
    return await this.contract.decimals();
  }
}

export default Erc20;
