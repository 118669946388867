import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tabsAnatomy.keys);

const baseStyle = definePartsStyle({});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    line: {
      tab: {
        pb: 6,
        fontSize: 18,
        color: 'gray.100',
        _selected: {
          color: 'primary.100',
          borderColor: 'primary.100',
          fontWeight: 700,
        },
      },
      tablist: {
        borderColor: 'rgba(150, 255, 177, 0.25)',
        borderBottom: '1px solid',
      },
    },
  },
});
