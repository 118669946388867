import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider } from '@ethersproject/providers';
import stakingRewardABI from 'cream/contract/ABIs/stakingRewards';
import stakingRewardsFactoryABI from 'cream/contract/ABIs/stakingRewardsFactory';
import StakingRewardHelperABI from 'cream/contract/ABIs/stakingRewardsHelper';
import { GAS_MULTIPLIER } from 'cream/contract/constants';
import { Protocol } from 'cream/Protocols';
import {
  IBRewardClaimable,
  IBRewardTokenInfo,
  IBStakingInfo,
  IBUserStaked,
} from 'cream/Type';
import { BigNumber, Contract, Signer } from 'ethers';

class IBStakingReward {
  private contract: Contract;
  private factoryContract: Contract;
  private provider: JsonRpcProvider | Signer;

  address: string;
  factoryAddress: string;

  constructor(protocol: Protocol, provider: JsonRpcProvider | Signer) {
    const address = protocol.stakingRewardHelperAddress;
    const factoryAddress = protocol.stakingRewardFactoryAddress;
    this.provider = provider;
    if (!address) {
      throw new Error('invalid StakingRewardHelper address');
    }

    this.contract = new Contract(address, StakingRewardHelperABI, provider);
    this.factoryContract = new Contract(
      factoryAddress,
      stakingRewardsFactoryABI,
      provider
    );
    this.address = address;
    this.factoryAddress = factoryAddress;
  }

  getStakingRewards(stakingToken: string): Promise<string> {
    return this.factoryContract.getStakingRewards(stakingToken);
  }

  getRewardTokenInfo(rewardTokenAddress: string): Promise<IBRewardTokenInfo> {
    return this.contract.getRewardTokenInfo(rewardTokenAddress);
  }

  getStakingInfo(): Promise<IBStakingInfo[]> {
    return this.contract.getStakingInfo([]);
  }

  getUserClaimableRewards(
    account: string,
    rewardTokens: string[]
  ): Promise<IBRewardClaimable[]> {
    return this.contract.getUserClaimableRewards(account, rewardTokens);
  }

  getUserStaked(account: string): Promise<IBUserStaked[]> {
    return this.contract.getUserStaked(account);
  }

  async getPeriodFinish(
    stakingRewardContractAddress: string,
    rewardToken: string
  ): Promise<BigNumber> {
    const stakingRewards = new Contract(
      stakingRewardContractAddress,
      stakingRewardABI,
      this.provider
    );
    return stakingRewards.periodFinish(rewardToken);
  }

  async getEarned(
    account: string,
    stakingRewardsAddress: string,
    rewardToken: string
  ): Promise<BigNumber> {
    const stakingRewards = new Contract(
      stakingRewardsAddress,
      stakingRewardABI,
      this.provider
    );
    return stakingRewards.earned(rewardToken, account);
  }

  async stake(
    underlying: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.stake(underlying, amount);
    return this.contract.stake(underlying, amount, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async stakeNative(amount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.stakeNative({ value: amount });
    return this.contract.stakeNative({
      value: amount,
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async unstake(
    stakingReward: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.unstake(stakingReward, amount);
    return this.contract.unstake(stakingReward, amount, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async claimAllRewards(): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimAllRewards();
    return this.contract.claimAllRewards({
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async claimRewards(stakingRewards: string[]): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimRewards(stakingRewards);
    return this.contract.claimRewards(stakingRewards, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async seize(token: string, amount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.seize(token, amount);
    return this.contract.seize(token, amount, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async transferOwnership(newOwner: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.transferOwnership(newOwner);
    return this.contract.transferOwnership(newOwner, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async renounceOwnership(): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.renounceOwnership();
    return this.contract.renounceOwnership({
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async exit(
    stakingRewards: string[],
    toNative: boolean
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.exit(stakingRewards, toNative);
    return this.contract.exit(stakingRewards, toNative, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async exitAll(toNative: boolean): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.exitAll();
    return this.contract.exitAll(toNative, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }
}

export default IBStakingReward;
