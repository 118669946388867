import { useContext, useMemo } from 'react';
import { BigNumber } from 'ethers';
import { reduce, find } from 'lodash';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import useUserBorrowSummary from 'hooks/useUserBorrowSummary';
import { useIBReward, IBTokenRewardData } from 'hooks/useIBReward';
import {
  displayBalance,
  distributionApy,
  getExpectedBorrowLimit,
  rateToApy,
  totalSupplyInUsd,
  underlyingBalance,
  getUnderlyingBalanceInUSD,
  sameAddress,
} from 'cream/utils';

export type UserSupplyMarketData = {
  asset: string;
  address: string;
  apy: string;
  distributionApy: string;
  supplyRate: BigNumber;
  balance: string;
  balanceInUSD: string;
  collateral: boolean;
  collateralBalance: string;
  collateralBalanceInUSD: string;
  disabled: boolean;
  stakingRewardData?: IBTokenRewardData;
};

interface UseUserSupplyMarketDataAPI {
  supplyData: UserSupplyMarketData[];
  isLoading: boolean;
}

export const useUserSupplyMarketData = (): UseUserSupplyMarketDataAPI => {
  const {
    markets,
    allMarketStats,
    allUserTokenStats,
    lmRewardsStats,
    basePrice,
    isLoading,
  } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);
  const userBorrowSummary = useUserBorrowSummary();
  const { tokenRewardData } = useIBReward();

  const supplyData: UserSupplyMarketData[] = useMemo(
    () =>
      reduce(
        allUserTokenStats,
        (acc: UserSupplyMarketData[], userTokenStats, index) => {
          const marketStats = allMarketStats[index];
          const market = markets[index];

          if (userTokenStats.crTokenBalance.eq(0)) {
            return acc;
          }
          const newBorrowLimitForWithdrawFull = getExpectedBorrowLimit(
            userBorrowSummary,
            marketStats,
            userTokenStats,
            userTokenStats.underlyingBalance,
            false
          );
          const disabled =
            marketStats.collateralFactor.eq(0) || // 0 collateral factor
            userTokenStats.borrowBalance.gt(
              newBorrowLimitForWithdrawFull.newBorrowLimitInNative
            ); // borrow limit check
          const underlyingCollateralBalance = underlyingBalance(
            userTokenStats.collateralBalance,
            marketStats.exchangeRate
          );

          const speeds = lmRewardsStats[index].rewardSpeeds;
          const totalSupply = totalSupplyInUsd(
            marketStats.supply,
            marketStats.underlyingPrice,
            marketStats.exchangeRate,
            basePrice
          );

          const stakingRewardData =
            protocol.networkId === 10
              ? find(tokenRewardData, (token) =>
                  sameAddress(token.tokenAddress, market.address)
                )
              : undefined;

          return acc.concat({
            asset: market.underlyingSymbol,
            address: market.address,
            apy: rateToApy(marketStats.supplyRate, protocol.blocksPerYear),
            stakingRewardData,
            distributionApy: distributionApy(speeds, totalSupply, true),
            supplyRate: marketStats.supplyRate,
            balance: displayBalance(
              userTokenStats.underlyingBalance,
              market.underlyingDecimal,
              2
            ),
            balanceInUSD: getUnderlyingBalanceInUSD(
              userTokenStats.underlyingBalance,
              market.underlyingDecimal,
              marketStats.underlyingPrice
            ),
            collateralBalance: displayBalance(
              underlyingCollateralBalance,
              market.underlyingDecimal,
              2
            ),
            collateralBalanceInUSD: getUnderlyingBalanceInUSD(
              userTokenStats.collateralBalance,
              market.underlyingDecimal,
              marketStats.underlyingPrice
            ),
            collateral: userTokenStats.collateralEnabled,
            disabled,
          });
        },
        []
      ),
    [
      allMarketStats,
      allUserTokenStats,
      basePrice,
      lmRewardsStats,
      markets,
      protocol.blocksPerYear,
      protocol.networkId,
      tokenRewardData,
      userBorrowSummary,
    ]
  );

  return {
    supplyData,
    isLoading,
  };
};

export default useUserSupplyMarketData;
