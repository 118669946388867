import { useCallback, useContext, useMemo, useState } from 'react';
import {
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { BigNumber, ethers } from 'ethers';
import { addDays, differenceInSeconds } from 'date-fns';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import useCream from 'hooks/useCream';
import useErc20Approve, { ApproveState } from 'hooks/useErc20Approve';
import useModal from 'hooks/useModal';
import useAlert, { AlertSeverity } from 'hooks/useAlert';
import BalanceInput from 'components/BalanceInput';
import VeIBStakeDurationPicker from 'components/VeIBStakeDurationPicker';
import NumberedButton from 'components/NumberedButton';
import Block from './components/Block';
import Info from './components/Info';
import ErrorMessage from './components/ErrorMessage';
import { commify, roundToThursday, getStakeRatioByLockTime } from 'cream/utils';

interface VeIBStakeModalProps {
  userIBBalance: BigNumber;
  ibPrice: number;
}

function VeIBStakeModal(props: VeIBStakeModalProps): JSX.Element {
  const { userIBBalance, ibPrice } = props;
  const { addTx } = useContext(TxContext);
  const { connected } = useContext(ConnectionContext);
  const { dismissModal } = useModal();
  const { showAlert } = useAlert();
  const { protocol } = useContext(ProtocolContext);
  const { approveState, allowance, approveAll } = useErc20Approve(
    protocol.ibAddress,
    protocol.veIBAddress
  );
  const cream = useCream();

  const [stakeAmount, setStakeAmount] = useState<string>('0');
  const [lockTime, setLockTime] = useState<Date>(
    roundToThursday(addDays(new Date(), 7))
  );

  const { data: veIBRatio } = useQuery<number>(
    ['ratio', lockTime],
    () => getStakeRatioByLockTime(lockTime),
    { refetchInterval: 1000, initialData: 0 }
  );

  const amount = useMemo<BigNumber>(
    () => ethers.utils.parseUnits(stakeAmount || '0', 18),
    [stakeAmount]
  );

  const errorMessage = useMemo<string>(() => {
    if (amount.lt(0)) {
      return 'Invalid Amount';
    }

    if (userIBBalance.lt(amount)) {
      return 'Insufficient Balance';
    }

    if (allowance.lt(amount)) {
      return 'Insufficient Allowance';
    }

    return '';
  }, [allowance, amount, userIBBalance]);

  const stake = useCallback(async () => {
    try {
      const response = await cream?.veIBStake(
        amount,
        differenceInSeconds(lockTime, new Date())
      );
      if (response) {
        addTx(response.hash, `Stake ${commify(stakeAmount)} IB to veIB`);
        dismissModal();
      }
    } catch (error) {
      console.warn('VeIB stake error', error);
      showAlert({
        severity: AlertSeverity.Error,
        message: 'VeIB Stake Failed',
      });
    }
  }, [addTx, amount, cream, dismissModal, lockTime, showAlert, stakeAmount]);

  const receivingVeIB: string = useMemo(() => {
    return (veIBRatio * Number(stakeAmount)).toFixed(2);
  }, [veIBRatio, stakeAmount]);

  return (
    <ModalContent>
      <ModalHeader>veIB Staking</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={stakeAmount}
            maxBalance={userIBBalance}
            symbol="IB"
            decimals={18}
            onChange={setStakeAmount}
            usdRate={ibPrice}
          />
          <VeIBStakeDurationPicker
            stakeAmount={stakeAmount}
            lockDate={lockTime}
            onChange={setLockTime}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Stake Stats
              </Text>
              <Stack spacing={2}>
                <Info
                  title="Mint Ratio"
                  value={`~${veIBRatio.toFixed(3)} veIB per IB`}
                />
                <Info title="You'll receive" value={`${receivingVeIB} veIB`} />
              </Stack>
            </Stack>
          </Block>
          <Stack spacing={4}>
            <NumberedButton
              disabled={
                (approveState === ApproveState.APPROVED &&
                  allowance.gte(amount)) ||
                !connected
              }
              onClick={approveAll}
              n={1}
            >
              Approve
            </NumberedButton>
            <NumberedButton
              disabled={
                approveState !== ApproveState.APPROVED ||
                !!errorMessage ||
                !connected
              }
              onClick={stake}
              n={2}
            >
              Stake
            </NumberedButton>
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
}

export default VeIBStakeModal;
