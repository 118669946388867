import { BigNumber, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import CompoundLensABI from './ABIs/compoundLens';
import { CTokenMetadata, CTokenBalances } from './types';

class CompoundLens {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, CompoundLensABI, provider);
    this.address = address;
  }

  cTokenMetadata(cTokens: string): Promise<CTokenMetadata[]> {
    return this.contract.callStatic.cTokenMetadata(cTokens);
  }

  cTokenMetadataAll(cTokens: string[]): Promise<CTokenMetadata[]> {
    return this.contract.callStatic.cTokenMetadataAll(cTokens);
  }

  cTokenBalancesAll(
    cTokens: string[],
    account: string
  ): Promise<CTokenBalances[]> {
    return this.contract.callStatic.cTokenBalancesAll(cTokens, account);
  }

  getClaimableSushiRewards(
    cTokens: string[],
    sushi: string,
    account: string
  ): Promise<BigNumber[]> {
    try {
      // not all lendsContract implements `getClaimableSushiRewards`
      return this.contract.callStatic.getClaimableSushiRewards(
        cTokens,
        sushi,
        account
      );
    } catch {
      return Promise.resolve([]);
    }
  }

  getClaimableCakeRewards(
    cTokens: string[],
    cake: string,
    account: string
  ): Promise<BigNumber[]> {
    try {
      // not all lendsContract implements `getClaimableCakeRewards`
      return this.contract.callStatic.getClaimableCakeRewards(
        cTokens,
        cake,
        account
      );
    } catch {
      return Promise.resolve([]);
    }
  }
}

export default CompoundLens;
