import { BigNumber, Contract, Signer } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider } from '@ethersproject/providers';
import { Protocol } from 'cream/Protocols';
import { GAS_MULTIPLIER } from 'cream/contract/constants';
import VeIBABI from 'cream/contract/ABIs/veIB';

class VeIB {
  private contract: Contract;

  address: string;

  constructor(protocol: Protocol, provider: JsonRpcProvider | Signer) {
    const address = protocol.veIBAddress;
    if (address === '') {
      throw new Error('invalid veIB address');
    }

    this.contract = new Contract(address, VeIBABI, provider);
    this.address = address;
  }

  balanceOf(account: string): Promise<BigNumber> {
    return this.contract.balanceOf(account);
  }

  unlockTime(account: string): Promise<BigNumber> {
    return this.contract.locked__end(account);
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.totalSupply();
  }

  supply(): Promise<BigNumber> {
    return this.contract.supply();
  }

  async stake(
    amount: BigNumber,
    lockDuration: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.create_lock(
      amount,
      lockDuration
    );
    return this.contract.create_lock(amount, lockDuration, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async increaseAmount(
    tokenId: BigNumber,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.increase_amount(
      tokenId,
      amount
    );
    return this.contract.increase_amount(tokenId, amount, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async increaseUnlockTime(
    tokenId: BigNumber,
    duration: BigNumber
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.increase_unlock_time(
      tokenId,
      duration
    );
    return this.contract.increase_unlock_time(tokenId, duration, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async withdraw(tokenId: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.withdraw(tokenId);
    return this.contract.withdraw(tokenId, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }
}

export default VeIB;
