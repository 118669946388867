import React, { useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { ConnectionContext } from 'providers/ConnectionProvider';
import TxButton from './TxButton';

const Wallet: React.FC = () => {
  const { connected, connectWallet } = useContext(ConnectionContext);

  return connected ? (
    <TxButton />
  ) : (
    <Button minW={32} size="xs" onClick={() => connectWallet()}>
      Connect
    </Button>
  );
};

export default Wallet;
