import { Category } from '../Category';
import { Market } from '../Type';

const IronBankMarkets: Market[] = [
  {
    name: 'Yearn Wrapped Ether',
    address: '0x41c84c0e2EE0b740Cf0d31F63f3B6F627DC6b393',
    symbol: 'cyWETH',
    underlyingAddress: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Ether',
    underlyingSymbol: 'WETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Tether USD',
    address: '0x48759F220ED983dB51fA7A8C0D2AAb8f3ce4166a',
    symbol: 'cyUSDT',
    underlyingAddress: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    underlyingDecimal: 6,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT',
    category: Category.stable,
    zeroAllowance: true,
  },
  {
    name: 'Yearn USD Coin',
    address: '0x76Eb2FE28b36B3ee97F3Adae0C69606eeDB2A37c',
    symbol: 'cyUSDC',
    underlyingAddress: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Dai Stablecoin',
    address: '0x8e595470Ed749b85C6F7669de83EAe304C2ec68F',
    symbol: 'cyDAI',
    underlyingAddress: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Wrapped liquid staked Ether 2.0',
    address: '0xbC6B6c837560D1fE317eBb54E105C89f303d5AFd',
    symbol: 'iWSTETH',
    underlyingAddress: '0x7f39C581F595B53c5cb19bD0b3f8dA6c935E2Ca0',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped liquid staked Ether 2.0',
    underlyingSymbol: 'wstETH',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Wrapped BTC',
    address: '0x8Fc8BFD80d6A9F17Fb98A373023d72531792B431',
    symbol: 'cyWBTC',
    underlyingAddress: '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599',
    underlyingDecimal: 8,
    underlyingName: 'Wrapped BTC',
    underlyingSymbol: 'WBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Aave Token',
    address: '0x30190a3B52b5AB1daF70D46D72536F5171f22340',
    symbol: 'cyAAVE',
    underlyingAddress: '0x7Fc66500c84A76Ad7e9c93437bFc5Ac33E2DDaE9',
    underlyingDecimal: 18,
    underlyingName: 'Aave Token',
    underlyingSymbol: 'AAVE',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn ChainLink Token',
    address: '0xE7BFf2Da8A2f619c2586FB83938Fa56CE803aA16',
    symbol: 'cyLINK',
    underlyingAddress: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    underlyingDecimal: 18,
    underlyingName: 'ChainLink Token',
    underlyingSymbol: 'LINK',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Convex Token',
    address: '0xE0B57FEEd45e7D908f2d0DaCd26F113Cf26715BF',
    symbol: 'iCVX',
    underlyingAddress: '0x4e3FBD56CD56c3e72c1403e103b45Db9da5B9D2B',
    underlyingDecimal: 18,
    underlyingName: 'Convex Token',
    underlyingSymbol: 'CVX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Curve DAO Token',
    address: '0xB8c5af54bbDCc61453144CF472A9276aE36109F9',
    symbol: 'cyCRV',
    underlyingAddress: '0xD533a949740bb3306d119CC777fa900bA034cd52',
    underlyingDecimal: 18,
    underlyingName: 'Curve DAO Token',
    underlyingSymbol: 'CRV',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn yearn.finance',
    address: '0xFa3472f7319477c9bFEcdD66E4B948569E7621b9',
    symbol: 'cyYFI',
    underlyingAddress: '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e',
    underlyingDecimal: 18,
    underlyingName: 'yearn.finance',
    underlyingSymbol: 'YFI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Synthetix Network Token',
    address: '0x12A9cC33A980DAa74E00cc2d1A0E74C57A93d12C',
    symbol: 'cySNX',
    underlyingAddress: '0xC011a73ee8576Fb46F5E1c5751cA3B9Fe0af2a6F',
    underlyingDecimal: 18,
    underlyingName: 'Synthetix Network Token',
    underlyingSymbol: 'SNX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Uniswap',
    address: '0xFEEB92386A055E2eF7C2B598c872a4047a7dB59F',
    symbol: 'cyUNI',
    underlyingAddress: '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984',
    underlyingDecimal: 18,
    underlyingName: 'Uniswap',
    underlyingSymbol: 'UNI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn SushiToken',
    address: '0x226F3738238932BA0dB2319a8117D9555446102f',
    symbol: 'cySUSHI',
    underlyingAddress: '0x6B3595068778DD592e39A122f4f5a5cF09C90fE2',
    underlyingDecimal: 18,
    underlyingName: 'SushiToken',
    underlyingSymbol: 'SUSHI',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Yearn DefiPulse Index',
    address: '0x7736Ffb07104c0C400Bb0CC9A7C228452A732992',
    symbol: 'cyDPI',
    underlyingAddress: '0x1494CA1F11D487c2bBe4543E90080AeBa4BA3C2b',
    underlyingDecimal: 18,
    underlyingName: 'DefiPulse Index',
    underlyingSymbol: 'DPI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn STASIS EURS Token',
    address: '0xA8caeA564811af0e92b1E044f3eDd18Fa9a73E4F',
    symbol: 'cyEURS',
    underlyingAddress: '0xdB25f211AB05b1c97D595516F45794528a807ad8',
    underlyingDecimal: 2,
    underlyingName: 'STASIS EURS Token',
    underlyingSymbol: 'EURS',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Synth sUSD',
    address: '0xa7c4054AFD3DbBbF5bFe80f41862b89ea05c9806',
    symbol: 'cySUSD',
    underlyingAddress: '0x57Ab1ec28D129707052df4dF418D58a2D46d5f51',
    underlyingDecimal: 18,
    underlyingName: 'Synth sUSD',
    underlyingSymbol: 'sUSD',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Magic Internet Money',
    address: '0x9e8E207083ffd5BDc3D99A1F32D1e6250869C1A9',
    symbol: 'cyMIM',
    underlyingAddress: '0x99D8a9C45b2ecA8864373A26D1459e3Dff1e17F3',
    underlyingDecimal: 18,
    underlyingName: 'Magic Internet Money',
    underlyingSymbol: 'MIM',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn EUR',
    address: '0x00e5c0774A5F065c285068170b20393925C84BF3',
    symbol: 'cyEUR',
    underlyingAddress: '0x96E61422b6A9bA0e068B6c5ADd4fFaBC6a4aae27',
    underlyingDecimal: 18,
    underlyingName: 'Iron Bank EUR',
    underlyingSymbol: 'ibEUR',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn GBP',
    address: '0xecaB2C76f1A8359A06fAB5fA0CEea51280A97eCF',
    symbol: 'cyGBP',
    underlyingAddress: '0x69681f8fde45345C3870BCD5eaf4A05a60E7D227',
    underlyingDecimal: 18,
    underlyingName: 'Iron Bank GBP',
    underlyingSymbol: 'ibGBP',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn JPY',
    address: '0x215F34af6557A6598DbdA9aa11cc556F5AE264B1',
    symbol: 'cyJPY',
    underlyingAddress: '0x5555f75e3d5278082200Fb451D1b6bA946D8e13b',
    underlyingDecimal: 18,
    underlyingName: 'Iron Bank JPY',
    underlyingSymbol: 'ibJPY',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn AUD',
    address: '0x86BBD9ac8B9B44C95FFc6BAAe58E25033B7548AA',
    symbol: 'cyAUD',
    underlyingAddress: '0xFAFdF0C4c1CB09d430Bf88c75D88BB46DAe09967',
    underlyingDecimal: 18,
    underlyingName: 'Iron Bank AUD',
    underlyingSymbol: 'ibAUD',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn KRW',
    address: '0x3c9f5385c288cE438Ed55620938A4B967c080101',
    symbol: 'cyKRW',
    underlyingAddress: '0x95dfdc8161832e4ff7816ac4b6367ce201538253',
    underlyingDecimal: 18,
    underlyingName: 'Iron Bank KRW',
    underlyingSymbol: 'ibKRW',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn CHF',
    address: '0x1b3E95E8ECF7A7caB6c4De1b344F94865aBD12d5',
    symbol: 'cyCHF',
    underlyingAddress: '0x1CC481cE2BD2EC7Bf67d1Be64d4878b16078F309',
    underlyingDecimal: 18,
    underlyingName: 'Iron Bank CHF',
    underlyingSymbol: 'ibCHF',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Y3CRV',
    address: '0x7589C9E17BCFcE1Ccaa1f921196FDa177F0207Fc',
    symbol: 'cyY3CRV',
    underlyingAddress: '0x9cA85572E6A3EbF24dEDd195623F188735A5179f',
    underlyingDecimal: 18,
    underlyingName: 'yearn Curve.fi DAI/USDC/USDT',
    underlyingSymbol: 'y3Crv',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Yearn mStable USD',
    address: '0xBE86e8918DFc7d3Cb10d295fc220F941A1470C5c',
    symbol: 'cyMUSD',
    underlyingAddress: '0xe2f2a5C287993345a840Db3B0845fbC70f5935a5',
    underlyingDecimal: 18,
    underlyingName: 'mStable USD',
    underlyingSymbol: 'mUSD',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Yearn DefiDollar',
    address: '0x297d4Da727fbC629252845E96538FC46167e453A',
    symbol: 'cyDUSD',
    underlyingAddress: '0x5BC25f649fc4e26069dDF4cF4010F9f706c23831',
    underlyingDecimal: 18,
    underlyingName: 'DefiDollar',
    underlyingSymbol: 'DUSD',
    category: Category.legacy,
    zeroAllowance: false,
  },
  {
    name: 'Yearn Compound USD Coin',
    address: '0x950027632FbD6aDAdFe82644BfB64647642B6C09',
    symbol: 'cyCUSDC',
    underlyingAddress: '0x39AA39c021dfbaE8faC545936693aC917d5E7563',
    underlyingDecimal: 8,
    underlyingName: 'Compound USD Coin',
    underlyingSymbol: 'cUSDC',
    category: Category.legacy,
    zeroAllowance: false,
  },
  // {
  //   name: 'Iron Bank ApeCoin',
  //   address: '0xd2b0D3594427e0c57C39e3455E2FB2dFED1e0B99',
  //   symbol: 'iAPE',
  //   underlyingAddress: '0x4d224452801ACEd8B2F0aebE155379bb5D594381',
  //   underlyingDecimal: 18,
  //   underlyingName: 'ApeCoin',
  //   underlyingSymbol: 'APE',
  //   category: Category.gov,
  //   zeroAllowance: false,
  // },
];
export default IronBankMarkets;
