import { BigNumber, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import CreamETH2ABI from './ABIs/creamETH2';
import { GAS_MULTIPLIER } from './constants';

class CreamETH2 {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, CreamETH2ABI, provider);
    this.address = address;
  }

  oracle(): Promise<string> {
    return this.contract.callStatic.oracle();
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.callStatic.totalSupply();
  }

  balanceOf(account: string): Promise<BigNumber> {
    return this.contract.callStatic.balanceOf(account);
  }

  accumulated(): Promise<BigNumber> {
    return this.contract.callStatic.accumulated();
  }

  async deposit(amount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.deposit({ value: amount });
    return this.contract.deposit({
      gasLimit: gas.mul(GAS_MULTIPLIER),
      value: amount,
    });
  }
}

export default CreamETH2;
