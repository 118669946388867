import {
  Box,
  Button,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react';
import TokenIconSymbol from 'components/TokenIconSymbol';
import { IBTokenRewardData } from 'hooks/useIBReward';
import useTokenIcon from '../../../hooks/useTokenIcon';

interface ApyPopoverProps {
  rewardData: IBTokenRewardData;
}

export const ApyPopover = ({ rewardData }: ApyPopoverProps) => {
  const ibIcon = useTokenIcon('ib');

  return (
    <Popover placement="top" variant="apy">
      <PopoverTrigger>
        <Button
          w="max-content"
          size="sm"
          rightIcon={<Image src={ibIcon} alt={'ib'} boxSize={4} />}
          variant="link"
          iconSpacing={1}
        >
          <Text variant="body3">
            {rewardData.stakingAPY.shiftedBy(2).toFormat(2)}%
          </Text>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverHeader>
            <HStack justify="space-between">
              <Text variant="subtitle4">NET APY:</Text>
              <Text variant="subtitle4" color="primary.100">
                {rewardData.netAPY.shiftedBy(2).toFormat(2)}%
              </Text>
            </HStack>
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <Stack spacing={2}>
              <HStack justify="space-between">
                <Text variant="body3">Supply APY:</Text>
                <Text variant="body3" color="primary.100">
                  {rewardData.nativeAPY.shiftedBy(2).toFormat(2)}%
                </Text>
              </HStack>
              <HStack justify="space-between">
                <TokenIconSymbol fontSize="xs" symbol="IB" />
                <Box>
                  <Text variant="body3" as="span">
                    APR{' '}
                  </Text>
                  <Text variant="body3" as="span" color="primary.100">
                    {rewardData.stakingAPY.shiftedBy(2).toFormat(2)}%
                  </Text>
                </Box>
              </HStack>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default ApyPopover;
