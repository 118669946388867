import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {},
  variants: {
    headline1: {
      fontWeight: 700,
      fontSize: '4xl',
      lineHeight: '2.75rem',
    },
    headline2: {
      fontWeight: 700,
      fontSize: '3xl',
      lineHeight: '2.5rem',
    },
    headline3: {
      fontWeight: 700,
      fontSize: '2xl',
      lineHeight: '2.125rem',
    },
    headline4: {
      fontWeight: 700,
      fontSize: 'lg',
      lineHeight: '1.75rem',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 'xl',
      lineHeight: '1.625rem',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 'xl',
      lineHeight: '1.5rem',
    },
    subtitle3: {
      fontWeight: 500,
      fontSize: 'md',
      lineHeight: '1.5rem',
    },
    subtitle4: {
      fontWeight: 500,
      fontSize: 'sm',
    },
    subtitle5: {
      fontWeight: 500,
      fontSize: 'xs',
      lineHeight: '1.125rem',
    },
    subtitle6: {
      fontWeight: 600,
      fontSize: 'sm',
    },
    body3: {
      fontSize: 'xs',
      fontWeight: 400,
      lineHeight: '1rem',
    },
    moduleTitle: {
      fontWeight: 700,
      fontSize: 'lg',
    },
    statCaption: {
      fontFamily: 'Inter',
      fontSize: 'xs',
      fontWeight: 400,
      color: 'gray.300',
    },
  },
});
