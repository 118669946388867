import { Button, Box, Text } from '@chakra-ui/react';
import { Protocol } from 'cream/Protocols';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { useContext } from 'react';

interface SwitchNetworkCardProps {
  targetNetwork: Protocol;
}

const SwitchNetworkCard = ({
  targetNetwork,
}: SwitchNetworkCardProps): JSX.Element => {
  const { connected, connectWallet, changeNetwork } =
    useContext(ConnectionContext);

  return (
    <Box bgColor="background.darkGreen" p={8}>
      <Text variant="subtitle3">
        Only available on {targetNetwork.name} Network, please
        <Button
          mx={2}
          variant="link"
          onClick={() => {
            if (connected) {
              changeNetwork(targetNetwork.networkId);
            } else {
              connectWallet();
            }
          }}
        >
          click here
        </Button>
        to switch network.
      </Text>
    </Box>
  );
};

export default SwitchNetworkCard;
