import { BigNumber, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import SpookyLpAbi from './ABIs/spookyLP';

export interface Reserves {
  _reserve0: string;
  _reserve1: string;
}

class SpookyLP {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, SpookyLpAbi, provider);
    this.address = address;
  }

  async getReserves(): Promise<Reserves> {
    return this.contract.getReserves();
  }

  async token0(): Promise<string> {
    return this.contract.token0();
  }

  async balanceOf(address: string): Promise<BigNumber> {
    return this.contract.balanceOf(address);
  }
}

export default SpookyLP;
