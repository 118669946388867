import { Signer } from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider } from '@ethersproject/providers';
import { GAS_MULTIPLIER } from 'cream/contract/constants';
import { Contract } from 'ethers';
import VeIBClaimHelperABI from './ABIs/veIBClaimHelper';

class VeIBClaimHelper {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, VeIBClaimHelperABI, provider);
    this.address = address;
  }

  async claim(
    distributors: string[],
    tokenIds: number[]
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claim(distributors, tokenIds);
    return this.contract.claim(distributors, tokenIds, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }
}

export default VeIBClaimHelper;
