import { useMemo } from 'react';
import { Box, Button, Flex, Text, SkeletonText } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import BN from 'bignumber.js';
import { BigNumber } from 'ethers';
import { isEmpty, reduce } from 'lodash';
import { displayBalance } from 'cream/utils';
import { NftClaimable, UserVeIBClaimable, useVeIB } from 'hooks/useVeIB';
import Table from 'components/Table';
import TokenIconSymbol from 'components/TokenIconSymbol';
import BalanceCell from 'components/BalanceCell';
import VeIBCard from './VeIBCard';

const Reward = () => {
  const {
    userClaimables,
    isLoadingUserClaimables: isLoading,
    claimAllRewards,
    isClaimingRewards,
  } = useVeIB();

  const getTotalClaimable = (
    decimals: number,
    nftClaimableList: NftClaimable[]
  ) =>
    displayBalance(
      reduce(
        nftClaimableList,
        (acc, { amount }) => acc.add(amount),
        BigNumber.from(0)
      ),
      decimals,
      4
    );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<UserVeIBClaimable>();
    return [
      columnHelper.accessor('symbol', {
        header: 'Assets',
        cell: (props) => <TokenIconSymbol symbol={props.getValue()} />,
      }),
      columnHelper.accessor('nftClaimables', {
        header: 'Balance',
        cell: ({ row: { original } }) => {
          const balance = getTotalClaimable(
            original.decimals,
            original.nftClaimables
          );

          const balanceInUSD = reduce(
            original.nftClaimables,
            (acc, { amountInUSD }) => acc.plus(amountInUSD || 0),
            new BN(0)
          );
          return (
            <BalanceCell
              balance={balance}
              usdValue={`$${balanceInUSD.toFormat(2)}`}
            />
          );
        },
        meta: { isNumeric: true },
      }),
    ];
  }, []);

  return (
    <VeIBCard isLoading={isLoading}>
      <Flex justifyContent="space-between" mb={5}>
        <Text variant="headline2" color="primary.100">
          Rewards
        </Text>
        <Button
          size="xs"
          minW="5.75rem"
          onClick={() => claimAllRewards()}
          disabled={isClaimingRewards || isLoading || isEmpty(userClaimables)}
        >
          Claim All
        </Button>
      </Flex>
      <Box>
        {isLoading ? (
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        ) : (
          <Table columns={columns} data={userClaimables} />
        )}
      </Box>
    </VeIBCard>
  );
};

export default Reward;
