import { Icon, HStack, Text } from '@chakra-ui/react';
import { RiErrorWarningLine } from 'react-icons/ri';

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <HStack
      w="full"
      py={3}
      px={2}
      bg="background.redLinear"
      border="1px solid"
      borderColor="red.500"
    >
      <Icon as={RiErrorWarningLine} boxSize={5} color="red.500" />
      <Text variant="subtitle3">{message}</Text>
    </HStack>
  );
};

export default ErrorMessage;
