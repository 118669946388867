import { ChakraProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Page from 'components/Page';
import ScrollToTop from 'components/ScrollToTop';
import Bridge from 'pages/Bridge';
import Farm from 'pages/Farm';
import VeIB from 'pages/VeIB';
import ModalProvider from 'providers/ModalProvider';
import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Lending from './pages/Lending';
import ConnectionProvider from './providers/ConnectionProvider';
import CreamProvider from './providers/CreamProvider';
import MarketProvider from './providers/MarketProvider';
import ProtocolProvider from './providers/ProtocolProvider';
import TxProvider from './providers/TxProvider';

import './styles/IceCreamDatePicker.css';
import { chakraTheme } from './theme';

const queryClient = new QueryClient();

function App(): JSX.Element {
  return (
    <Providers>
      <BrowserRouter>
        <ModalProvider>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Navigate to="/lending" replace />} />
            <Route element={<Page />}>
              <Route path="/lending" element={<Lending />} />
              <Route path="/earn" element={<Farm />} />
              <Route path="/veib" element={<VeIB />} />
              <Route path="/bridge" element={<Bridge />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </ModalProvider>
      </BrowserRouter>
    </Providers>
  );
}

const Providers = ({ children }: { children: React.ReactNode }) => (
  <QueryClientProvider client={queryClient}>
    <ProtocolProvider>
      <ConnectionProvider>
        <TxProvider>
          <CreamProvider>
            <MarketProvider>
              <ChakraProvider theme={chakraTheme}>{children}</ChakraProvider>
            </MarketProvider>
          </CreamProvider>
        </TxProvider>
      </ConnectionProvider>
    </ProtocolProvider>
  </QueryClientProvider>
);

export default App;
