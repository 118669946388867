import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

const api =
  'https://gateway.thegraph.com/api/6bee5a4b87e6f1624ca8098b0308fe0f/subgraphs/id/HXfMc1jPHfFQoccWd7VMv66km75FoxVHDMvsJj5vG5vf';

const query = `
{
  totalRewards(first: 7, orderBy: block, orderDirection: desc) {
    apr
  }
}
`;

type ApiResponse = {
  data: {
    totalRewards: {
      apr: string;
    }[];
  };
};

async function fetch7DaysAverageAPR() {
  const { data } = await axios.post<ApiResponse>(api, { query });
  return (
    data.data.totalRewards
      .map(({ apr }) => Number(apr))
      .reduce((sum, apr) => sum + apr, 0) /
    100 /
    7
  );
}

const useStEthAPR = () => {
  const { data, isLoading } = useQuery(['stEth-apr'], fetch7DaysAverageAPR);

  return {
    data,
    isLoading,
  };
};

export default useStEthAPR;
