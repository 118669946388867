import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Text,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { parseUnits } from 'ethers/lib/utils';
import useApprove, { ApproveState } from 'hooks/useApprove';
import { useContext, useEffect, useMemo, useState } from 'react';
import {
  commify,
  displayChange,
  displayFactor,
  distributionApy,
  getExpectedBorrowLimit,
  rateToApy,
  tokenPrice,
  totalSupplyInUsd,
} from '../../cream/utils';
import useCream from '../../hooks/useCream';
import useMarketData from '../../hooks/useMarketData';
import useNativeBalance from '../../hooks/useNativeBalance';
import useUserBorrowSummary from '../../hooks/useUserBorrowSummary';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { MarketContext } from '../../providers/MarketProvider';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import { TxContext } from '../../providers/TxProvider';
import useModal from 'hooks/useModal';
import BalanceInput from '../BalanceInput';
import NumberedButton from '../NumberedButton';
import Block from './components/Block';
import Info from './components/Info';
import ErrorMessage from './components/ErrorMessage';

interface SupplyModalProps {
  marketAddress: string;
  isNative: boolean;
}

const SupplyModal = ({
  marketAddress,
  isNative,
}: SupplyModalProps): JSX.Element => {
  const [amount, setAmount] = useState('');
  const [isReady, setIsReady] = useState<boolean>(true);
  const { protocol } = useContext(ProtocolContext);
  const { addTx } = useContext(TxContext);
  const cream = useCream();
  const { dismissModal } = useModal();

  const [
    market,
    marketStats,
    userTokenStats,
    userLiquidityRewards,
    lmRewardStats,
  ] = useMarketData(marketAddress);

  const { approveState, approveAll, allowance, isApproving } =
    useApprove(market);
  const { connected } = useContext(ConnectionContext);

  const { data: nativeBalance } = useNativeBalance();
  const userWalletBalance = useMemo<BigNumber>(() => {
    if (isNative) {
      return nativeBalance || BigNumber.from(0);
    }

    if (!userTokenStats || !userTokenStats.walletBalance) {
      return BigNumber.from(0);
    }

    return userTokenStats.walletBalance;
  }, [isNative, nativeBalance, userTokenStats]);

  const { basePrice } = useContext(MarketContext);
  const totalSupply = totalSupplyInUsd(
    marketStats.supply,
    marketStats.underlyingPrice,
    marketStats.exchangeRate,
    basePrice
  );

  const supplyAmount = parseUnits(amount || '0', market.underlyingDecimal);

  const userBorrowSummary = useUserBorrowSummary();
  const newBorrowLimit = getExpectedBorrowLimit(
    userBorrowSummary,
    marketStats,
    userTokenStats,
    supplyAmount,
    true
  );

  useEffect(() => {
    if (supplyAmount.lt(0)) {
      setIsReady(false);
      return;
    }

    if (userWalletBalance.lt(supplyAmount)) {
      setIsReady(false);
      return;
    }

    if (!isNative && allowance.lt(supplyAmount)) {
      setIsReady(false);
      return;
    }

    if (marketStats.supplyCap.gt(0)) {
      const totalSupply = marketStats.cash
        .add(marketStats.borrow)
        .sub(marketStats.reserves);
      const supplyQuota = marketStats.supplyCap.sub(totalSupply);

      if (supplyQuota.lt(supplyAmount)) {
        setIsReady(false);
        return;
      }
    }

    if (supplyAmount.isZero()) {
      setIsReady(false);
      return;
    }

    setIsReady(true);
  }, [
    allowance,
    isNative,
    marketStats.borrow,
    marketStats.cash,
    marketStats.reserves,
    marketStats.supplyCap,
    supplyAmount,
    userWalletBalance,
  ]);

  const errorMessage = useMemo<string>(() => {
    if (supplyAmount.lt(0)) {
      return 'Invalid Amount';
    }

    if (userWalletBalance.lt(supplyAmount)) {
      return 'Insufficient Balance';
    }

    if (!isNative && allowance.lt(supplyAmount)) {
      return 'Insufficient Allowance';
    }

    if (marketStats.supplyCap.gt(0)) {
      const totalSupply = marketStats.cash
        .add(marketStats.borrow)
        .sub(marketStats.reserves);
      const supplyQuota = marketStats.supplyCap.sub(totalSupply);

      if (supplyQuota.lt(supplyAmount)) {
        return 'Supply Cap Exceeded';
      }
    }

    if (supplyAmount.isZero()) {
      return '';
    }

    return '';
  }, [
    allowance,
    isNative,
    marketStats.borrow,
    marketStats.cash,
    marketStats.reserves,
    marketStats.supplyCap,
    supplyAmount,
    userWalletBalance,
  ]);

  const supply = async () => {
    const response = await cream?.supply(market, supplyAmount, isNative);
    if (response) {
      addTx(
        response.hash,
        `Supply ${commify(amount)} ${
          isNative ? protocol.chainConfig.token : market.underlyingSymbol
        }`
      );
      dismissModal();
    }
  };

  return (
    <ModalContent>
      <ModalHeader>Supply</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={amount}
            symbol={
              isNative
                ? protocol.chainConfig.token || ''
                : market.underlyingSymbol
            }
            decimals={isNative ? 18 : market.underlyingDecimal}
            maxBalance={userWalletBalance}
            usdRate={tokenPrice(
              marketStats.underlyingPrice,
              market.underlyingDecimal,
              userBorrowSummary.basePrice
            )}
            onChange={setAmount}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Supply Stats
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Supply APY'}
                  value={rateToApy(
                    marketStats.supplyRate,
                    protocol.blocksPerYear
                  )}
                />
                <Info
                  title={'Reward APY'}
                  value={distributionApy(
                    lmRewardStats.rewardSpeeds,
                    totalSupply,
                    true
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Collateral
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Collateral Factor'}
                  value={displayFactor(marketStats.collateralFactor)}
                />
                <Info
                  title={'Borrow Limit Used'}
                  value={displayChange(
                    userBorrowSummary.borrowLimitPct,
                    newBorrowLimit.newBorrowLimitPct
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Stack spacing={4}>
            {isNative ? (
              <Button
                onClick={() => supply()}
                disabled={!isReady || !connected}
              >
                Supply
              </Button>
            ) : (
              <>
                <NumberedButton
                  n={1}
                  disabled={
                    !connected &&
                    approveState === ApproveState.APPROVED &&
                    allowance.gte(supplyAmount)
                  }
                  isLoading={isApproving}
                  onClick={() => approveAll()}
                >
                  Approve
                </NumberedButton>
                <NumberedButton
                  n={2}
                  disabled={
                    approveState !== ApproveState.APPROVED ||
                    !isReady ||
                    !connected
                  }
                  onClick={() => supply()}
                >
                  Supply
                </NumberedButton>
              </>
            )}
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};

export default SupplyModal;
