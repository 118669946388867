import { ReactNode } from 'react';
import { Button, Flex, Stack, Text, useClipboard } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { RiFileCopyLine } from 'react-icons/ri';
import useExplorer from 'hooks/useExplorer';
import { shortAddress } from 'cream/utils';

interface AccountProps {
  walletAddress: string;
  ensName: string | null;
  action?: ReactNode;
}

export const Account = ({
  walletAddress,
  ensName,
  action,
}: AccountProps): JSX.Element => {
  const { openAddress } = useExplorer();
  const { onCopy } = useClipboard(walletAddress);

  return (
    <Stack spacing={4}>
      <Text variant="headline4" color="primary.100">
        Account
      </Text>
      <Flex direction="column">
        <Text variant="subtitle5" color="gray.500">
          Connected Wallet Address
        </Text>
        {ensName && (
          <Text variant="subtitle3" mt={2}>
            {ensName}
          </Text>
        )}
        <Button
          variant="link"
          rightIcon={<RiFileCopyLine />}
          color="inherit"
          mt={1}
          justifyContent="flex-start"
          onClick={() => onCopy()}
        >
          <Text variant="subtitle4">{shortAddress(walletAddress)}</Text>
        </Button>
      </Flex>
      <Button
        variant="link"
        rightIcon={<FaExternalLinkAlt />}
        mt={1}
        justifyContent="flex-start"
        onClick={() => openAddress(walletAddress)}
      >
        <Text variant="subtitle4">View on explorer</Text>
      </Button>
      {action}
    </Stack>
  );
};

export default Account;
