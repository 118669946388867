import { Container, Box, Grid, GridItem } from '@chakra-ui/react';
import { FantomProtocol, OptimismProtocol } from 'cream/Protocols';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { useContext } from 'react';
import { includes } from 'lodash';
import Reward from './Reward';
import UserVeIB from './UserVeIB';
import SwitchNetworkCard from 'components/SwitchNetworkCard';

function VeIB(): JSX.Element {
  const { protocol } = useContext(ProtocolContext);

  return (
    <Box bgColor="background.light" w="full" py={{ base: 6, lg: 10 }} px={0}>
      <Container>
        {includes(
          [FantomProtocol.networkId, OptimismProtocol.networkId],
          protocol.networkId
        ) ? (
          <Grid templateColumns="repeat(5, 1fr)" gap={10}>
            <GridItem colSpan={{ base: 5, lg: 3 }}>
              <UserVeIB />
            </GridItem>
            <GridItem colSpan={{ base: 5, lg: 2 }}>
              <Reward />
            </GridItem>
          </Grid>
        ) : (
          <SwitchNetworkCard targetNetwork={OptimismProtocol} />
        )}
      </Container>
    </Box>
  );
}

export default VeIB;
