import { tableAnatomy } from '@chakra-ui/anatomy';
import { defineStyle } from '@chakra-ui/react';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const numericStyles = defineStyle({
  '&[data-is-numeric=true]': {
    textAlign: 'end',
  },
});

const baseStyle = definePartsStyle({
  table: { p: '4' },
  td: {
    fontSize: 'md',
    fontWeight: 500,
    ...numericStyles,
  },
  th: {
    color: 'gray.500',
    ...numericStyles,
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  sizes: {
    md: definePartsStyle({
      th: { px: '2', py: '2' },
      td: { px: '2', py: '2' },
    }),
  },
});
