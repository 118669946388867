import { Category } from 'cream/Category';
import { Market } from 'cream/Type';

const OptimismMarkets: Market[] = [
  {
    name: 'Iron Bank Wrapped Ether',
    address: '0x17533a1bDe957979E3977EbbFBC31E6deeb25C7d',
    symbol: 'iWETH',
    underlyingAddress: '0x4200000000000000000000000000000000000006',
    underlyingDecimal: 18,
    underlyingName: 'Wrapped Ether',
    underlyingSymbol: 'WETH',
    category: Category.major,
    zeroAllowance: false,
    isWrappedBaseAsset: true,
    wrappedAssetSymbol: 'ETH',
  },
  {
    name: 'Iron Bank USD Coin',
    address: '0x1d073cf59Ae0C169cbc58B6fdD518822ae89173a',
    symbol: 'iUSDC',
    underlyingAddress: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
    underlyingDecimal: 6,
    underlyingName: 'USD Coin',
    underlyingSymbol: 'USDC',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Tether USD',
    address: '0x874C01c2d1767EFA01Fa54b2Ac16be96fAd5a742',
    symbol: 'iUSDT',
    underlyingAddress: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
    underlyingDecimal: 6,
    underlyingName: 'Tether USD',
    underlyingSymbol: 'USDT',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Dai Stablecoin',
    address: '0x049E04bEE77cFfB055f733A138a2F204D3750283',
    symbol: 'iDAI',
    underlyingAddress: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
    underlyingDecimal: 18,
    underlyingName: 'Dai Stablecoin',
    underlyingSymbol: 'DAI',
    category: Category.stable,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Wrapped BTC',
    address: '0xcdb9b4db65C913aB000b40204248C8A53185D14D',
    symbol: 'iWBTC',
    underlyingAddress: '0x68f180fcCe6836688e9084f035309E29Bf0A2095',
    underlyingDecimal: 8,
    underlyingName: 'Wrapped BTC',
    underlyingSymbol: 'WBTC',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Optimism',
    address: '0x4645e0952678E9566FB529D9313f5730E4e1C412',
    symbol: 'iOP',
    underlyingAddress: '0x4200000000000000000000000000000000000042',
    underlyingDecimal: 18,
    underlyingName: 'Optimism',
    underlyingSymbol: 'OP',
    category: Category.major,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Synthetix Network Token',
    address: '0xE724FfA5D30782499086682C8362CB3673bF69ae',
    symbol: 'iSNX',
    underlyingAddress: '0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4',
    underlyingDecimal: 18,
    underlyingName: 'Synthetix Network Token',
    underlyingSymbol: 'SNX',
    category: Category.gov,
    zeroAllowance: false,
  },
  {
    name: 'Iron Bank Synth sUSD',
    address: '0x04F0fd3CD03B17a3E5921c0170ca6dD3952841cA',
    symbol: 'iSUSD',
    underlyingAddress: '0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9',
    underlyingDecimal: 18,
    underlyingName: 'Synth sUSD',
    underlyingSymbol: 'sUSD',
    category: Category.stable,
    zeroAllowance: false,
  },
];

export default OptimismMarkets;
