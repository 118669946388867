import { Container, Stack } from '@chakra-ui/react';
import SwitchNetworkCard from 'components/SwitchNetworkCard';
import { OptimismProtocol } from 'cream/Protocols';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { useContext } from 'react';
import { BeethovenPoolLink } from './components/BeethovenPoolLink';
import IBReward from './components/IBReward';

function Farm(): JSX.Element {
  const { protocol } = useContext(ProtocolContext);
  return (
    <Stack bgColor="background.light" py={{ base: 6, lg: 10 }}>
      <Container>
        {protocol.networkId === 10 ? (
          <Stack spacing={10}>
            <IBReward />
            <BeethovenPoolLink />
          </Stack>
        ) : (
          <SwitchNetworkCard targetNetwork={OptimismProtocol} />
        )}
      </Container>
    </Stack>
  );
}

export default Farm;
