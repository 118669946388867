import { TransactionReceipt, JsonRpcProvider } from '@ethersproject/providers';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { failureSignature } from '../cream/constants';
import { Protocol } from '../cream/Protocols';
import { ConnectionContext } from './ConnectionProvider';
import { ProtocolContext } from './ProtocolProvider';

const waitForReceipt = (
  provider: JsonRpcProvider,
  hash: string,
  onReceive: (hash: string, receipt: TransactionReceipt) => void
) => {
  provider.waitForTransaction(hash).then((receipt: TransactionReceipt) => {
    onReceive(hash, receipt);
  });
};

export type Tx = {
  hash: string;
  summary: string;
  success: boolean;
  confirmed: boolean;
  protocol: Protocol;
  address: string;
  timestamp: number;
  expiry: number;
};

interface Context {
  addTx: (hash: string, summary: string) => void;
  clearPendingTx: () => void;
  txs: Tx[];
}

export const TxContext = createContext<Context>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  addTx: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearPendingTx: () => {},
  txs: [],
});

const TxProvider = ({ children }: { children: React.ReactNode }) => {
  const storage = window.localStorage;
  const { protocol } = useContext(ProtocolContext);
  const { walletAddress, provider } = useContext(ConnectionContext);
  const [txs, setTxs] = useState<Tx[]>([]);

  const storageName = `${protocol}.${walletAddress}.txs`;

  const addTx = (hash: string, summary: string) => {
    if (!walletAddress) {
      return;
    }

    const now = new Date(Date.now()).getTime();
    const ttl = 1 * 24 * 3600 * 1000; // 1 day ttl

    // always get txs from localStorage since user could have added tx from other tab or window
    const txs = getTxs();
    txs.unshift({
      hash,
      summary,
      protocol,
      success: false,
      confirmed: false,
      address: walletAddress,
      timestamp: now,
      expiry: now + ttl,
    });
    storage.setItem(storageName, JSON.stringify(txs));
    setTxs(txs);
    waitForReceipt(provider, hash, onReceiveReceipt);
  };

  const getTxs = useCallback(() => {
    if (!walletAddress) {
      return [];
    }

    const storedTxs = JSON.parse(storage.getItem(storageName) || '[]') as Tx[];
    return storedTxs.sort((a, b) => b.timestamp - a.timestamp);
  }, [storage, storageName, walletAddress]);

  const onReceiveReceipt = (hash: string, receipt: TransactionReceipt) => {
    const storedTxs = getTxs();
    for (const tx of storedTxs) {
      if (tx.hash === hash) {
        const errLog = receipt.logs.find(
          (log) => log.topics.length > 0 && log.topics[0] === failureSignature
        );
        if (errLog) {
          // '0x' + error code (64) + error msg (64) + detail error (64)
          const errCode = errLog.data.substring(2, 66);
          tx.success = parseInt(errCode) === 0;
        } else {
          tx.success = receipt.status === 1;
        }
        tx.confirmed = true;
        break;
      }
    }
    storage.setItem(storageName, JSON.stringify(storedTxs));
    setTxs(storedTxs);
  };

  if (txs.length === 0) {
    const storedTxs = getTxs();
    if (storedTxs.length !== 0) {
      setTxs(storedTxs);

      for (const tx of storedTxs) {
        if (!tx.confirmed) {
          waitForReceipt(provider, tx.hash, onReceiveReceipt);
        }
      }
    }
  }

  const clearPendingTx = () => {
    const confirmedTx = getTxs().filter((tx) => tx.confirmed);
    storage.setItem(storageName, JSON.stringify(confirmedTx));
    setTxs(confirmedTx);
  };

  // clear expired tx
  useEffect(() => {
    const id = setInterval(() => {
      const now = new Date(Date.now()).getTime();
      const storedTxs = getTxs();
      const remainTxs = storedTxs.filter((tx) => tx.expiry > now);
      if (remainTxs.length < storedTxs.length) {
        storage.setItem(storageName, JSON.stringify(remainTxs));
      }
    }, 15 * 1000);
    return () => clearInterval(id);
  }, [getTxs, storage, storageName]);

  const context = {
    addTx,
    clearPendingTx,
    txs,
  };

  return <TxContext.Provider value={context}>{children}</TxContext.Provider>;
};

export default TxProvider;
