import { Modal, ModalOverlay } from '@chakra-ui/react';
import { createContext, ReactNode, useMemo, useState } from 'react';

interface ModalContextProps {
  children: ReactNode;
}

type ModalParams = {
  children: ReactNode;
};

interface ModalProviderContext {
  presentModal: (options: ModalParams) => void;
  dismissModal: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
const EMPTY_FUNC = () => {};

export const ModalContext = createContext<ModalProviderContext>({
  presentModal: EMPTY_FUNC,
  dismissModal: EMPTY_FUNC,
});

const ModalProvider = ({ children }: ModalContextProps) => {
  const [modalContent, setModalContent] = useState<ModalParams | null>(null);
  const isOpen = useMemo<boolean>(() => modalContent !== null, [modalContent]);

  const handlePresent = ({ ...restOptions }: ModalParams) => {
    setModalContent({ ...restOptions });
  };
  const handleDismiss = () => {
    setModalContent(null);
  };

  const [context] = useState<ModalProviderContext>({
    presentModal: handlePresent,
    dismissModal: handleDismiss,
  });

  return (
    <ModalContext.Provider value={context}>
      {children}
      <Modal isOpen={isOpen} onClose={handleDismiss} isCentered>
        <ModalOverlay />
        {modalContent?.children}
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
