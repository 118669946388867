import { useContext, useMemo } from 'react';
import { find, reduce } from 'lodash';
import { BigNumber, ethers } from 'ethers';
import { BigNumber as BN } from 'bignumber.js';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { useIBReward, IBTokenRewardData } from 'hooks/useIBReward';
import useNativeBalance from 'hooks/useNativeBalance';
import { Category } from 'cream/Category';
import {
  distributionApy,
  rateToApy,
  sameAddress,
  totalSupplyInUsd,
  getUnderlyingBalanceInUSD,
} from 'cream/utils';

export type SupplyMarketData = {
  id: string;
  asset: string;
  address: string;
  apy: string;
  distributionApy: string;
  stakingRewardData?: IBTokenRewardData;
  supplyRate: BigNumber;
  wallet: BN;
  walletBalanceInUSD: string;
  category: Category;
  isNative: boolean;
  isCollateral: boolean;
};

export const useSupplyMarketData = (): SupplyMarketData[] => {
  const {
    markets,
    allMarketStats,
    allUserTokenStats,
    lmRewardsStats,
    basePrice,
  } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);
  const { data: nativeBalance, isLoading } = useNativeBalance();
  const { tokenRewardData } = useIBReward();

  const data = useMemo<SupplyMarketData[]>(() => {
    return reduce(
      allMarketStats,
      (
        acc: SupplyMarketData[],
        {
          address,
          supply,
          supplyRate,
          underlyingPrice,
          exchangeRate,
          collateralFactor,
        },
        index
      ) => {
        // Delist EURS
        // TODO: remove from markets after user withdraw and repay
        if (
          sameAddress(address, '0xA8caeA564811af0e92b1E044f3eDd18Fa9a73E4F')
        ) {
          return acc;
        }
        const userTokenStats = allUserTokenStats[index];
        const market = markets[index];

        const speeds = lmRewardsStats[index].rewardSpeeds;
        const totalSupply = totalSupplyInUsd(
          supply,
          underlyingPrice,
          exchangeRate,
          basePrice
        );

        const stakingRewardData =
          protocol.networkId === 10
            ? find(tokenRewardData, (token) =>
                sameAddress(token.tokenAddress, market.address)
              )
            : undefined;

        const supplyData: SupplyMarketData = {
          id: market.address,
          address: market.address,
          apy: rateToApy(supplyRate, protocol.blocksPerYear),
          distributionApy: distributionApy(speeds, totalSupply, true),
          stakingRewardData,
          asset: market.underlyingSymbol,
          supplyRate,
          wallet: new BN(
            ethers.utils.formatUnits(
              userTokenStats.walletBalance,
              market.underlyingDecimal
            )
          ),
          walletBalanceInUSD: getUnderlyingBalanceInUSD(
            userTokenStats.walletBalance,
            market.underlyingDecimal,
            underlyingPrice
          ),
          category: market.category,
          isNative: false,
          isCollateral: !collateralFactor.isZero(),
        };

        if (market.isWrappedBaseAsset) {
          acc.push({
            ...supplyData,
            id: market.address + 'native',
            asset: protocol.chainConfig.token || '',
            wallet:
              isLoading || !nativeBalance
                ? new BN(0)
                : new BN(ethers.utils.formatEther(nativeBalance)),
            walletBalanceInUSD: getUnderlyingBalanceInUSD(
              nativeBalance || BigNumber.from(0),
              market.underlyingDecimal,
              underlyingPrice
            ),
            isNative: true,
          });
        }

        acc.push(supplyData);
        return acc;
      },
      []
    );
  }, [
    allMarketStats,
    allUserTokenStats,
    markets,
    lmRewardsStats,
    basePrice,
    protocol.networkId,
    protocol.blocksPerYear,
    protocol.chainConfig.token,
    tokenRewardData,
    isLoading,
    nativeBalance,
  ]);

  return data;
};

export default useSupplyMarketData;
