import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    px: 6,
    fontWeight: 500,
  },
  sizes: {
    xs: {
      px: 3,
      fontSize: 'xs',
      height: '10',
    },
    sm: {
      fontSize: 'sm',
      height: '40px',
    },
  },
  variants: {
    outline: {
      bgColor: 'primary.900',
      borderColor: 'primary.100',
      border: '1px solid',
      borderRadius: 0,
      color: 'gray.100',
      _hover: {
        bgColor: 'primary.500',
        _disabled: {
          bgColor: 'gray.900',
        },
      },
      _disabled: {
        bgColor: 'gray.700',
        borderColor: 'gray.700',
        color: 'text.disabled',
      },
    },
    ghost: {
      color: 'primary.100',
      _hover: {
        bgColor: 'unset',
      },
      _active: {
        bgColor: 'unset',
      },
    },
    link: {
      color: 'primary.100',
    },
    shortcut: {
      bgColor: 'background.dark',
      borderColor: 'primary.700',
      border: '1px solid',
      color: 'primary.500',
      borderRadius: '88px',
      height: '32px',
      _hover: {
        bgColor: 'primary.900',
        borderColor: 'primary.100',
        color: 'primary.100',
      },
    },
  },
  defaultProps: {
    variant: 'outline',
  },
});
