import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import {
  commify,
  displayChange,
  getExpectedBorrowBalance,
  rateToApy,
  sameAddress,
  tokenPrice,
} from 'cream/utils';
import { BigNumber, ethers } from 'ethers';
import useAlert, { AlertSeverity } from 'hooks/useAlert';
import useApprove, { ApproveState } from 'hooks/useApprove';
import useCream from 'hooks/useCream';
import useMarketData from 'hooks/useMarketData';
import useModal from 'hooks/useModal';
import useNativeBalance from 'hooks/useNativeBalance';
import useUserBorrowSummary from 'hooks/useUserBorrowSummary';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import { useCallback, useContext, useMemo, useState } from 'react';
import { IronBankProtocol } from '../../cream/Protocols';
import BalanceInput from '../BalanceInput';
import NumberedButton from '../NumberedButton';
import TokenIconSymbol from '../TokenIconSymbol';
import Block from './components/Block';
import ErrorMessage from './components/ErrorMessage';
import Info from './components/Info';

interface RepayModalProps {
  marketAddress: string;
}

export const RepayModal = ({ marketAddress }: RepayModalProps): JSX.Element => {
  const [market, marketStats, { borrowBalance, walletBalance }] =
    useMarketData(marketAddress);
  const { addTx } = useContext(TxContext);
  const { dismissModal } = useModal();
  const { showAlert } = useAlert();
  const { protocol } = useContext(ProtocolContext);
  const { connected, walletAddress } = useContext(ConnectionContext);
  const { approveState, approve, approveAll, allowance, isApproving } =
    useApprove(market);

  const cream = useCream();
  const { data: nativeBalance } = useNativeBalance();
  const userBorrowSummary = useUserBorrowSummary();

  const [amount, setAmount] = useState('');
  const [isWrapped, setIsWrapped] = useState<boolean>(true);

  const isNative = useMemo<boolean>(
    () => market.isWrappedBaseAsset === true && !isWrapped,
    [isWrapped, market.isWrappedBaseAsset]
  );

  const repayAmount = ethers.utils.parseUnits(
    amount || '0',
    market.underlyingDecimal
  );

  const newBorrowLimit = getExpectedBorrowBalance(
    userBorrowSummary,
    marketStats,
    repayAmount,
    false
  );

  const insufficientAllowance = useMemo<boolean>(() => {
    if (isNative) {
      return false;
    }
    return allowance.lt('0') || allowance.lt(repayAmount);
  }, [isNative, allowance, repayAmount]);

  const userWalletBalance = useMemo<BigNumber>(() => {
    if (isNative) {
      return nativeBalance || BigNumber.from(0);
    }

    if (!walletBalance) {
      return BigNumber.from(0);
    }

    return walletBalance;
  }, [isNative, nativeBalance, walletBalance]);

  const repay = useCallback(async () => {
    try {
      let response;
      if (!walletAddress) {
        throw new Error('no wallet connect');
      }

      if (repayAmount.eq(borrowBalance)) {
        // Repay full amount.
        if (isNative) {
          // 1.001x of borrow balance should be sufficient for native token to repay full.
          const fullRepayAmount = borrowBalance.mul(1001).div(1000);
          response = await cream?.repayNativeFull(
            walletAddress,
            fullRepayAmount
          );
        } else {
          response = await cream?.repay(
            market,
            ethers.constants.MaxUint256,
            isNative
          );
        }
      } else {
        response = await cream?.repay(market, repayAmount, isNative);
      }

      if (response) {
        addTx(
          response.hash,
          `Repay ~${commify(amount)} ${
            isNative ? protocol.chainConfig.token : market.underlyingSymbol
          }`
        );
        dismissModal();
      }
    } catch (error) {
      console.error(error);
      showAlert({ message: 'Repay Failed', severity: AlertSeverity.Error });
    }
  }, [
    market,
    isNative,
    walletAddress,
    repayAmount,
    borrowBalance,
    cream,
    addTx,
    amount,
    protocol.chainConfig.token,
    dismissModal,
    showAlert,
  ]);

  const errorMessage = useMemo<string>(() => {
    if (repayAmount.lt(0)) {
      return 'Invalid Amount';
    }
    if (userWalletBalance.lt(repayAmount)) {
      return 'Insufficient Balance';
    }

    if (insufficientAllowance) {
      return 'Insufficient Balance';
    }

    if (borrowBalance.lt(repayAmount)) {
      return 'Excessive Repayment';
    }

    if (repayAmount.isZero()) {
      return '';
    }

    return '';
  }, [borrowBalance, insufficientAllowance, repayAmount, userWalletBalance]);

  const isReady = useMemo<boolean>(() => {
    if (repayAmount.lt(0)) {
      // Invalid repay amount.
      return false;
    }

    if (userWalletBalance.lt(repayAmount)) {
      // Wallet balance is less than repay amount.
      return false;
    }

    if (insufficientAllowance) {
      // Insufficient allowance.
      return false;
    }

    if (borrowBalance.lt(repayAmount)) {
      // Excessive Repayment.
      return false;
    }

    if (repayAmount.isZero()) {
      return false;
    }

    return true;
  }, [insufficientAllowance, repayAmount, borrowBalance, userWalletBalance]);

  const isCrv =
    protocol === IronBankProtocol &&
    sameAddress('0xB8c5af54bbDCc61453144CF472A9276aE36109F9', marketAddress);

  const renderCrvButtons = () => {
    return (
      <>
        <NumberedButton
          n={1}
          disabled={
            !insufficientAllowance ||
            !connected ||
            (insufficientAllowance && allowance.eq(0))
          }
          onClick={() => approve(BigNumber.from(0))}
          isLoading={isApproving && !allowance.eq(0)}
        >
          Revoke Approval
        </NumberedButton>
        <NumberedButton
          n={2}
          disabled={
            !insufficientAllowance ||
            !connected ||
            (insufficientAllowance && !allowance.eq(0))
          }
          onClick={() => approveAll()}
          isLoading={isApproving && allowance.eq(0)}
        >
          Approve
        </NumberedButton>
        <NumberedButton
          n={3}
          disabled={
            (approveState !== ApproveState.APPROVED &&
              !insufficientAllowance) ||
            !isReady ||
            !connected
          }
          onClick={() => repay()}
        >
          Repay
        </NumberedButton>
      </>
    );
  };

  return (
    <ModalContent>
      <ModalHeader>Repay</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={amount}
            symbol={market.underlyingSymbol}
            maxBalance={borrowBalance}
            decimals={market.underlyingDecimal}
            usdRate={tokenPrice(
              marketStats.underlyingPrice,
              market.underlyingDecimal,
              userBorrowSummary.basePrice
            )}
            onChange={setAmount}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {market.wrappedAssetSymbol && (
            <HStack>
              <Switch
                isChecked={!isWrapped}
                onChange={(e) => setIsWrapped(!e.target.checked)}
              />
              <Text>Repay as</Text>
              <TokenIconSymbol symbol={market.wrappedAssetSymbol} />
            </HStack>
          )}
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Borrow Stats
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Borrow APY'}
                  value={rateToApy(
                    marketStats.borrowRate,
                    protocol.blocksPerYear
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Collateral
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Borrow Limit Used'}
                  value={displayChange(
                    userBorrowSummary.borrowLimitPct,
                    newBorrowLimit.newBorrowLimitPct
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Stack spacing={4}>
            {isNative ? (
              <Button disabled={!isReady || !connected} onClick={() => repay()}>
                Repay
              </Button>
            ) : isCrv ? (
              renderCrvButtons()
            ) : (
              <>
                <NumberedButton
                  n={1}
                  disabled={!insufficientAllowance || !connected}
                  onClick={() => approveAll()}
                  isLoading={isApproving}
                >
                  Approve
                </NumberedButton>
                <NumberedButton
                  n={2}
                  disabled={
                    (approveState !== ApproveState.APPROVED &&
                      !insufficientAllowance) ||
                    !isReady ||
                    !connected
                  }
                  onClick={() => repay()}
                >
                  Repay
                </NumberedButton>
              </>
            )}
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};

export default RepayModal;
