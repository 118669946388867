import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import {
  SimpleGridBreakPoint,
  StackDirectionBreakPoint,
} from 'hooks/useMediaQuery';
import { ConnectionContext } from 'providers/ConnectionProvider';
import React, { useContext } from 'react';
import BorrowMarket from './components/BorrowMarket';
import StatusBanner from './components/StatusBanner';
import SupplyMarket from './components/SupplyMarket';
import UserBorrowMarket from './components/UserBorrowMarket';
import UserSupplyMarket from './components/UserSupplyMarket';

export const Lending: React.FC = () => {
  const { connected } = useContext(ConnectionContext);
  return (
    <Box bgColor="background.light" w="full">
      <StatusBanner />
      <Container maxW="container.lg">
        <Stack py={10} spacing={10} w="full">
          {connected && (
            <Stack
              direction={StackDirectionBreakPoint}
              p={{ base: 4, lg: 6 }}
              border="1.5px solid"
              borderColor="gray.300"
              bgColor="#1A191C"
              divider={<StackDivider borderColor="gray.700" />}
              spacing={10}
            >
              <Box flex="1 0 0%" minW={0}>
                <UserSupplyMarket />
              </Box>
              <Box flex="1 0 0%" minW={0}>
                <UserBorrowMarket />
              </Box>
            </Stack>
          )}
          <SimpleGrid columns={SimpleGridBreakPoint} spacing={10}>
            <SupplyMarket />
            <BorrowMarket />
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
};

export default Lending;
