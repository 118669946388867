import { Box } from '@chakra-ui/react';
import { map, filter } from 'lodash';

type ProgressReference = {
  label: string;
  value: number;
};

interface ProgressBarProps {
  value: number;
  references?: ProgressReference[];
}

const MaxValue = 100;

export const ProgressBar = ({ value, references }: ProgressBarProps) => {
  return (
    <Box w="full" h="10px">
      <Box
        pos="relative"
        h="full"
        bgColor="background.header"
        border="0.5px"
        borderStyle="solid"
        borderColor="gray.700"
      >
        <Box
          w={`${value >= MaxValue ? MaxValue : value}%`}
          h="full"
          bgGradient="linear-gradient(270deg, #54CF74 0.02%, #062B19 99.98%)"
        />
        {map(
          filter(references, ({ value }) => value <= MaxValue),
          ({ label, value }) => (
            <Box
              key={label}
              pos="absolute"
              w="2px"
              h="full"
              top="0"
              left={`${value}%`}
              bgColor="red.500"
              _before={{
                content: `'${label}'`,
                position: 'absolute',
                width: '100px',
                left: '-50px',
                top: '100%',
                color: 'red.500',
                textAlign: 'center',
                fontsize: '0.75rem',
              }}
            />
          )
        )}
      </Box>
    </Box>
  );
};

export default ProgressBar;
