import {
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Stack,
  Switch,
  Text,
} from '@chakra-ui/react';
import { BigNumber } from 'ethers';
import { commify, parseUnits } from 'ethers/lib/utils';
import useModal from 'hooks/useModal';
import { useContext, useMemo, useState } from 'react';
import {
  displayChange,
  getExpectedBorrowBalance,
  rateToApy,
  tokenNativePrice,
  tokenPrice,
} from '../../cream/utils';
import useCream from '../../hooks/useCream';
import useMarketData from '../../hooks/useMarketData';
import useUserBorrowSummary from '../../hooks/useUserBorrowSummary';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { ProtocolContext } from '../../providers/ProtocolProvider';
import { TxContext } from '../../providers/TxProvider';
import BalanceInput from '../BalanceInput';
import TokenIconSymbol from '../TokenIconSymbol';
import Block from './components/Block';
import Info from './components/Info';
import ErrorMessage from './components/ErrorMessage';

interface BorrowModalProps {
  marketAddress: string;
}

const BorrowModal = ({ marketAddress }: BorrowModalProps): JSX.Element => {
  const [amount, setAmount] = useState('');
  const cream = useCream();
  const { dismissModal } = useModal();

  const [market, marketStats] = useMarketData(marketAddress);
  const { protocol } = useContext(ProtocolContext);
  const userBorrowSummary = useUserBorrowSummary();
  const { connected } = useContext(ConnectionContext);

  const [isWrapped, setIsWrapped] = useState<boolean>(true);
  const borrowAmount = parseUnits(amount || '0', market.underlyingDecimal);
  const newBorrowLimit = getExpectedBorrowBalance(
    userBorrowSummary,
    marketStats,
    borrowAmount,
    true
  );

  const { addTx } = useContext(TxContext);

  const isNative = market.isWrappedBaseAsset === true && !isWrapped;

  const borrow = async () => {
    const response = await cream?.borrow(market, borrowAmount, isNative);
    if (response) {
      addTx(
        response.hash,
        `Borrow ${commify(amount)} ${
          isNative ? protocol.chainConfig.token : market.underlyingSymbol
        }`
      );
      dismissModal();
    }
  };

  const borrowPowerRemain = useMemo<BigNumber>(() => {
    if (
      userBorrowSummary.borrowLimitInNative.gt(
        userBorrowSummary.totalBorrowBalanceInNative
      )
    ) {
      return userBorrowSummary.borrowLimitInNative.sub(
        userBorrowSummary.totalBorrowBalanceInNative
      );
    }

    return BigNumber.from(0);
  }, [
    userBorrowSummary.borrowLimitInNative,
    userBorrowSummary.totalBorrowBalanceInNative,
  ]);

  const priceInNative = tokenNativePrice(
    marketStats.underlyingPrice,
    market.underlyingDecimal
  );
  const borrowPowerRemainInToken = useMemo<BigNumber>(() => {
    if (priceInNative.eq(0)) return BigNumber.from(0);

    return borrowPowerRemain
      .mul(parseUnits('1', market.underlyingDecimal))
      .div(priceInNative);
  }, [borrowPowerRemain, market.underlyingDecimal, priceInNative]);

  const errorMessage = useMemo<string>(() => {
    if (borrowAmount.lt(0)) {
      return 'Invalid borrow amount.';
    }

    if (
      newBorrowLimit.newBorrowBalanceInNative.gt(
        userBorrowSummary.borrowLimitInNative
      )
    ) {
      return 'Borrow limit is fully used.';
    }

    if (
      newBorrowLimit.newBorrowBalanceInNative.gt(
        userBorrowSummary.borrowLimitInNative
          .mul(BigNumber.from(8))
          .div(BigNumber.from(10))
      )
    ) {
      return 'Borrow limit is 80% used.';
    }

    if (marketStats.cash.lt(borrowAmount)) {
      return 'Insufficient cash.';
    }

    if (marketStats.borrowCap.gt(0)) {
      const borrowQuota = marketStats.borrowCap.sub(marketStats.borrow);
      if (borrowQuota.lt(borrowAmount)) {
        return 'Borrow cap reached.';
      }
    }

    return '';
  }, [
    borrowAmount,
    marketStats.borrow,
    marketStats.borrowCap,
    marketStats.cash,
    newBorrowLimit.newBorrowBalanceInNative,
    userBorrowSummary.borrowLimitInNative,
  ]);

  const isReady = useMemo<boolean>(() => {
    if (borrowAmount.lt(0)) {
      // Invalid borrow amount.
      return false;
    }

    if (
      newBorrowLimit.newBorrowBalanceInNative.gt(
        userBorrowSummary.borrowLimitInNative
      )
    ) {
      // Borrow limit is fully used.
      return false;
    }

    if (
      newBorrowLimit.newBorrowBalanceInNative.gt(
        userBorrowSummary.borrowLimitInNative
          .mul(BigNumber.from(8))
          .div(BigNumber.from(10))
      )
    ) {
      // Borrow limit is 80% used.
      return false;
    }

    if (marketStats.cash.lt(borrowAmount)) {
      // Insufficient cash.
      return false;
    }

    if (marketStats.borrowCap.gt(0)) {
      const borrowQuota = marketStats.borrowCap.sub(marketStats.borrow);
      if (borrowQuota.lt(borrowAmount)) {
        // Borrow cap reached.
        return false;
      }
    }

    if (borrowAmount.isZero()) {
      return false;
    }

    return true;
  }, [
    borrowAmount,
    marketStats.borrow,
    marketStats.borrowCap,
    marketStats.cash,
    newBorrowLimit.newBorrowBalanceInNative,
    userBorrowSummary.borrowLimitInNative,
  ]);

  return (
    <ModalContent>
      <ModalHeader>Borrow</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={amount}
            symbol={market.underlyingSymbol}
            maxBalance={borrowPowerRemainInToken}
            decimals={market.underlyingDecimal}
            usdRate={tokenPrice(
              marketStats.underlyingPrice,
              market.underlyingDecimal,
              userBorrowSummary.basePrice
            )}
            onChange={setAmount}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {market.wrappedAssetSymbol && (
            <HStack>
              <Switch
                isChecked={!isWrapped}
                onChange={(e) => setIsWrapped(!e.target.checked)}
              />
              <Text>Borrow as</Text>
              <TokenIconSymbol symbol={market.wrappedAssetSymbol} />
            </HStack>
          )}
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Borrow Stats
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Borrow APY'}
                  value={rateToApy(
                    marketStats.borrowRate,
                    protocol.blocksPerYear
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Block>
            <Stack spacing={6}>
              <Text variant="headline4" color="primary.100">
                Collateral
              </Text>
              <Stack spacing={2}>
                <Info
                  title={'Borrow Limit Used'}
                  value={displayChange(
                    userBorrowSummary.borrowLimitPct,
                    newBorrowLimit.newBorrowLimitPct
                  )}
                />
              </Stack>
            </Stack>
          </Block>
          <Stack spacing={4}>
            <Button onClick={() => borrow()} disabled={!isReady || !connected}>
              Borrow
            </Button>
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
};

export default BorrowModal;
