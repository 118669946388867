import { useContext } from 'react';
import {
  Flex,
  IconButton,
  Icon,
  Spacer,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
  Button,
  Divider,
  Link,
  Box,
} from '@chakra-ui/react';
import { map } from 'lodash';
import { FcMenu } from 'react-icons/fc';
import { NavLink, useNavigate } from 'react-router-dom';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { useNavigationItems } from 'hooks/useNavigationItems';
import { Account } from 'components/Account';
import Logo from './Logo';

interface MobileNavBarProps {
  isHomePage?: boolean;
}

export const MobileNavbar = ({ isHomePage = false }: MobileNavBarProps) => {
  const navigate = useNavigate();
  const { walletAddress, ensName, disconnectWallet, connected, connectWallet } =
    useContext(ConnectionContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigationList = useNavigationItems();

  return (
    <Flex
      as="header"
      pos="sticky"
      top={0}
      zIndex={30}
      w="full"
      bgColor="background.header"
      px={6}
      py={4}
      alignItems="center"
    >
      <Logo />
      <Spacer />
      {isHomePage ? (
        <Button minWidth="5.25rem" onClick={() => navigate('/lending')}>
          App
        </Button>
      ) : connected ? (
        <IconButton
          aria-label="menu"
          size="lg"
          variant="ghost"
          icon={<Icon as={FcMenu} />}
          onClick={() => onOpen()}
        />
      ) : (
        <Button minW={16} size="xs" onClick={() => connectWallet()}>
          Connect
        </Button>
      )}
      <Drawer
        onClose={onClose}
        isOpen={isOpen}
        size="full"
        placement="left"
        isFullHeight
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody bgColor="background.header" p={0}>
            <DrawerCloseButton />
            <Flex direction="column" gap={6} p={4} h="full">
              <Logo />
              {walletAddress && (
                <Account walletAddress={walletAddress} ensName={ensName} />
              )}
              <Divider />
              {map(navigationList, (nav) => {
                return (
                  <Link
                    key={nav.title}
                    as={NavLink}
                    variant="nav"
                    to={nav.link}
                    end
                    onClick={() => onClose()}
                  >
                    {nav.title}
                  </Link>
                );
              })}
              <Box flexGrow={1} />
              <Button
                onClick={() => {
                  disconnectWallet();
                  onClose();
                }}
              >
                Disconnect
              </Button>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default MobileNavbar;
