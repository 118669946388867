import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    nav: {
      fontSize: 'md',
      fontWeight: 500,
      _activeLink: {
        color: 'primary.100',
      },
      _hover: {
        color: 'primary.100',
      },
    },
  },
});
