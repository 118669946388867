import { defineStyleConfig } from '@chakra-ui/react';
import { cssVar } from '@chakra-ui/theme-tools';

const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const baseStyle = {
  border: '1px solid',
  borderColor: 'primary.900',
  bgColor: 'background.popup',
  p: 2,
  [$arrowBg.variable]: '#0C1D14',
  [$arrowShadowColor.variable]: `#0F441D`,
};

// export the component theme
export const tooltipTheme = defineStyleConfig({ baseStyle });

export default tooltipTheme;
