import { BigNumber } from 'ethers';
import { Category } from './Category';

export type Market = {
  name: string;
  symbol: string;
  address: string;
  underlyingName: string;
  underlyingSymbol: string;
  underlyingDecimal: number;
  underlyingAddress: string;
  category: Category;
  zeroAllowance: boolean; // when true, underlying token requires approving 0 before setting to another value
  isWrappedBaseAsset?: boolean;
  wrappedAssetSymbol?: string;
};

export type MarketStats = {
  address: string;
  cash: BigNumber;
  supply: BigNumber;
  borrow: BigNumber;
  reserves: BigNumber;
  exchangeRate: BigNumber;
  supplyRate: BigNumber;
  borrowRate: BigNumber;
  collateralFactor: BigNumber;
  supplyPaused: boolean;
  borrowPaused: boolean;
  underlyingPrice: BigNumber;
  supplyCap: BigNumber;
  borrowCap: BigNumber;
  collateralCap: BigNumber;
  totalCollateralTokens: BigNumber;
  version: number;
};

export type UserTokenStats = {
  address: string;
  walletBalance: BigNumber;
  crTokenBalance: BigNumber;
  underlyingBalance: BigNumber;
  nativeTokenBalance: BigNumber;
  borrowBalance: BigNumber;
  collateralEnabled: boolean;
  collateralBalance: BigNumber;
};

export type BorrowLimit = {
  newBorrowLimitInNative: BigNumber;
  newBorrowLimitInUsd: string;
  newBorrowBalanceInNative: BigNumber;
  newBorrowBalanceInUsd: string;
  newBorrowLimitPct: string;
};

export type UserLiquidityRewards = {
  reward: BigNumber;
  address: string;
};

export type LongTermPoolStats = {
  poolAddress: string;
  lockPeriod: number;
  lpTokenAddress: string;
  endDate: BigNumber;
  rewardRate: BigNumber;
  totalSupplyInPool: BigNumber;
};

export type UserLongTermPoolStats = {
  poolAddress: string;
  allowance: BigNumber;
  walletBalance: BigNumber;
  lpDecimals: number;
  stakedBalance: BigNumber;
  earnedBalance: BigNumber;
};

export type CreamETH2Stats = {
  totalSupply: BigNumber;
  accumulated: BigNumber;
  exchangeRate: BigNumber;
};

export type UserCreamETH2Stats = {
  ethBalance: BigNumber;
  crETHBalance: BigNumber;
};

export type LongTermPool = {
  address: string;
  years: number;
};

export interface RewardTokenInfo {
  rewardTokenAddress: string;
  rewardTokenSymbol: string;
  rewardTokenDecimals: number;
  rewardTokenUSDPrice: number;
}

export type LMRewardSpeeds = {
  rewardTokenAddress: string;
  rewardTokenSymbol: string;
  rewardTokenUSDPrice: number;
  supplySpeed: BigNumber;
  borrowSpeed: BigNumber;
};

export type UnclaimLMReward = {
  rewardToken: RewardTokenInfo;
  amount: BigNumber;
};

export interface RewardSpeed {
  speed: BigNumber;
  start: BigNumber;
  end: BigNumber;
}

export interface RewardSpeedInfo {
  rewardToken: RewardTokenInfo;
  supplySpeed: RewardSpeed;
  borrowSpeed: RewardSpeed;
}

export type LMRewardStats = {
  rewardSpeeds: RewardSpeedInfo[];
};

export enum MarketType {
  Supply = 'SUPPLY',
  Borrow = 'BORROW',
}

export enum TransactionType {
  supply = 'Supply',
  borrow = 'Borrow',
  withdraw = 'Withdraw',
  repay = 'Repay',
  stake = 'Stake',
  unstake = 'Unstake',
  claimReward = 'Claim Reward',
  unknown = '',
}

export enum StakingRewardTransactionType {
  Stake,
  Withdraw,
  ClaimReward,
}

export type ChainConfigParams = {
  chainId: string; // A 0x-prefixed hexadecimal string
  chainName: string;
  nativeCurrency: {
    name: string;
    symbol: string; // 2-6 characters long
    decimals: 18;
  };
  rpcUrls: string[];
  blockExplorerUrls?: string[];
};

export enum TimeUnit {
  Years = 'years',
  Months = 'months',
  Weeks = 'weeks',
  Days = 'days',
}

export type IBUserStaked = {
  stakingTokenAddress: string;
  balance: BigNumber;
};

export type IBRewardClaimable = {
  rewardToken: IBRewardTokenInfo;
  amount: BigNumber;
};

export type IBRewardClaimableIB = {
  stakingToken: string;
  amount: BigNumber;
};

export type IBRewardTokenInfo = {
  rewardTokenAddress: string;
  rewardTokenSymbol: string;
  rewardTokenDecimals: number;
};

export type RewardRate = {
  rewardTokenAddress: string;
  rate: BigNumber;
};

export type IBStakingInfo = {
  stakingTokenAddress: string;
  totalSupply: BigNumber;
  supplyRatePerBlock: BigNumber;
  exchangeRate: BigNumber;
  rewardRates: RewardRate[];
};

export type VeIBReward = {
  address: string;
  symbol: string;
  decimals: number;
  claimable: BigNumber;
  usdValue: number;
};
