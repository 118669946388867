import { HStack, Image, Text } from '@chakra-ui/react';
import logo from 'assets/img/ib.png';
import { useNavigate } from 'react-router-dom';

const Logo = (): JSX.Element => {
  const navigate = useNavigate();

  return (
    <HStack
      alignItems="center"
      spacing={4}
      cursor="pointer"
      onClick={() => navigate('/')}
    >
      <Image src={logo} borderRadius="full" boxSize={10} />
      <Text
        fontFamily="Jockey One"
        fontSize="4xl"
        whiteSpace="nowrap"
        lineHeight="10"
      >
        IRON BANK
      </Text>
    </HStack>
  );
};

export default Logo;
