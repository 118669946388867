import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    bgColor: 'background.header',
    borderColor: 'primary.100',
    border: '1px solid',
  },
  item: {
    fontWeight: 500,
    color: 'gray.100',
    bgColor: 'background.header',
    _hover: {
      bgColor: 'primary.700',
    },
    _active: {
      bgColor: 'primary.700',
    },
    _focus: {
      bgColor: 'primary.700',
    },
  },
});

export default defineMultiStyleConfig({
  baseStyle,
});
