import { BigNumber, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { GAS_MULTIPLIER } from './constants';
import LongTermPoolABI from './ABIs/longTermPool';

class LongTermPool {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, LongTermPoolABI, provider);
    this.address = address;
  }

  lpToken(): Promise<string> {
    return this.contract.callStatic.lpToken();
  }

  balanceOf(account: string): Promise<BigNumber> {
    return this.contract.callStatic.balanceOf(account);
  }

  earned(account: string): Promise<BigNumber> {
    return this.contract.callStatic.earned(account);
  }

  releaseTime(): Promise<BigNumber> {
    return this.contract.callStatic.releaseTime();
  }

  rewardRate(): Promise<BigNumber> {
    return this.contract.callStatic.rewardRate();
  }

  totalSupply(): Promise<BigNumber> {
    return this.contract.callStatic.totalSupply();
  }

  async stake(amount: BigNumber): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.stake(amount);
    return this.contract.stake(amount, { gasLimit: gas.mul(GAS_MULTIPLIER) });
  }

  async exit(): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.exit();
    return this.contract.exit({ gasLimit: gas.mul(GAS_MULTIPLIER) });
  }
}

export default LongTermPool;
