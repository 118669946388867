import { useMemo } from 'react';
import {
  Box,
  Button,
  HStack,
  Skeleton,
  Stack,
  Text,
  Grid,
  GridItem,
} from '@chakra-ui/react';
import axios from 'axios';
import BN from 'bignumber.js';
import { find, get, map } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { displayPercentageValue } from 'pages/Markets/utils';
import IBEarnLogo from 'assets/img/ib-earn.png';
import IbWethEarnLogo from 'assets/img/ib-weth-earn.png';

type RewardPool = {
  name: string;
  icon: string;
  ratio: string;
  apr: string;
  link: string;
};

export const BeethovenPoolLink = (): JSX.Element => {
  const { isMobile } = useMediaQuery();
  const fetchVelodromeAPR = async (): Promise<string | undefined> => {
    const { data } = await axios({
      method: 'get',
      headers: { 'content-type': 'application/json' },
      url: 'https://api.velodrome.finance/api/v1/pairs',
    });

    const pairData = find(data.data, [
      'address',
      '0x279b59bc9c89c023d0f9b2984678b12909ef762d',
    ]);

    return new BN(pairData.apr).toString() || '0';
  };
  const fetchAPR = async (): Promise<string | undefined> => {
    const { data } = await axios({
      method: 'post',
      headers: { 'content-type': 'application/json' },
      url: 'https://backend-optimism-v2.beets-ftm-node.com/',
      data: JSON.stringify({
        operationName: 'GetPool',
        variables: {
          id: '0x785f08fb77ec934c01736e30546f87b4daccbe50000200000000000000000041',
        },
        query:
          'query GetPool($id: String!) { pool: poolGetPool(id: $id) { dynamicData { apr { total }}}}',
      }),
    });

    return get(data, 'data.pool.dynamicData.apr.total');
  };

  const { data: velodromeAPR } = useQuery<string | undefined>(
    ['velodromeAPR'],
    fetchVelodromeAPR,
    {
      refetchInterval: 30000,
    }
  );

  const { data: rewardAPR, isLoading } = useQuery<string | undefined>(
    ['beethovenAPR'],
    fetchAPR,
    {
      refetchInterval: 30000,
    }
  );

  const pools: RewardPool[] = useMemo(
    () => [
      {
        name: 'Velodrome Finance',
        icon: IbWethEarnLogo,
        ratio: '(IB: 50%, WETH: 50%)',
        apr: velodromeAPR ? `${new BN(velodromeAPR).toFormat(2)}%` : 'N/A',
        link: `https://app.velodrome.finance/liquidity/manage?address=0x279b59bc9c89c023d0f9b2984678b12909ef762d`,
      },
      {
        name: 'Beethoven X | Galactic Dragon (deprecated)',
        icon: IBEarnLogo,
        ratio: '(IB: 50%, rETH: 50%)',
        apr: rewardAPR ? displayPercentageValue(new BN(rewardAPR), 2) : 'N/A',
        link: `https://op.beets.fi/pool/0x785f08fb77ec934c01736e30546f87b4daccbe50000200000000000000000041`,
      },
    ],
    [rewardAPR, velodromeAPR]
  );

  return (
    <Box bgColor="background.darkGreen" p={{ base: 4, lg: 6 }}>
      <Text variant="headline2" color="primary.100" mb={4}>
        Pool2 Reward
      </Text>
      <Stack spacing={4}>
        {map(pools, (pool) => (
          <Grid
            id={pool.name}
            templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(7, 1fr)' }}
            rowGap={4}
            alignItems="center"
          >
            <GridItem colSpan={{ base: 1, md: 3 }}>
              <HStack gap={2}>
                <Box w="60px">
                  <img
                    style={{ width: '100%', height: '100%' }}
                    src={pool.icon}
                    alt="pool3"
                  />
                </Box>
                <Text>{pool.name}</Text>`
              </HStack>
            </GridItem>
            <GridItem colSpan={{ base: 1, md: 2 }}>
              <Text variant="subtitle3">{pool.ratio}</Text>
            </GridItem>
            <GridItem colSpan={1}>
              <HStack gap={1}>
                <Text variant="subtitle3">APR:</Text>
                {isLoading ? (
                  <Skeleton
                    h="20px"
                    w="60px"
                    startColor="#082106"
                    endColor="#303c2d"
                  />
                ) : (
                  <Text variant="subtitle3">{pool.apr ? pool.apr : 'N/A'}</Text>
                )}
              </HStack>
            </GridItem>
            <GridItem colSpan={1} textAlign="right">
              <Button
                variant="outline"
                size="xs"
                minW="5.25rem"
                w={isMobile ? 'full' : 'unset'}
                onClick={() => {
                  window.open(pool.link, '_blank');
                }}
              >
                Add Liquidity
              </Button>
            </GridItem>
          </Grid>
        ))}
      </Stack>
    </Box>
  );
};

export default BeethovenPoolLink;
