import { Button, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import RepayModal from 'components/Modals/RepayModal';
import Table from 'components/Table';
import TokenIconSymbol from 'components/TokenIconSymbol';
import useMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import {
  UserBorrowMarketData,
  useUserBorrowMarketData,
} from 'hooks/useUserBorrowMarketData';
import React, { useMemo } from 'react';
import { isWstEthAddress, StEthApyPopover } from './StEthApyPopover';
import UserMarket from './UserMarket';

export const UserBorrowMarket: React.FC = () => {
  const { isMobile } = useMediaQuery();
  const { borrowData, isLoading } = useUserBorrowMarketData();
  const { presentModal } = useModal();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<UserBorrowMarketData>();
    return [
      columnHelper.accessor('asset', {
        header: 'Asset',
        cell: (props) => (
          <TokenIconSymbol
            data-tag="allowRowEvents"
            symbol={props.getValue()}
          />
        ),
      }),
      columnHelper.accessor('apy', {
        header: 'APY',
        cell: ({
          row: {
            original: { apy, borrowRate, address },
          },
        }) => {
          return (
            <Stack spacing={1} alignItems="end">
              <Text>{apy}</Text>
              {isWstEthAddress(address) && (
                <StEthApyPopover supplyOrBorrowRate={borrowRate} />
              )}
            </Stack>
          );
        },
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('balance', {
        header: 'Balance',
        cell: (props) => props.getValue(),
        meta: { isNumeric: true },
      }),
      columnHelper.display({
        id: 'repayAction',
        header: '',
        cell: ({ row: { original } }) => (
          <Button
            size="xs"
            minW="5.25rem"
            onClick={() =>
              presentModal({
                children: <RepayModal marketAddress={original.address} />,
              })
            }
          >
            Repay
          </Button>
        ),
        meta: { isHidden: isMobile },
      }),
    ];
  }, [isMobile, presentModal]);

  return (
    <UserMarket
      header="Your Borrows"
      table={
        <Table
          columns={columns}
          data={borrowData}
          onRowClick={
            isMobile
              ? ({ original }) =>
                  presentModal({
                    children: <RepayModal marketAddress={original.address} />,
                  })
              : undefined
          }
        />
      }
      isLoading={isLoading}
    />
  );
};

export default UserBorrowMarket;
