import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fromUnixTime } from 'date-fns';

const useDateStringLocalizer = (options?: Intl.DateTimeFormatOptions) => {
  const { i18n } = useTranslation();

  return useCallback(
    (timestamp: number) => {
      let locale = 'en';
      switch (i18n.language) {
        case 'zh-Hant':
          locale = 'zh-Hant';
          break;
        case 'jp':
          locale = 'ja';
          break;
        case 'kr':
          locale = 'ko';
          break;
      }

      return fromUnixTime(timestamp).toLocaleDateString(locale, options);
    },
    [i18n.language, options]
  );
};

export default useDateStringLocalizer;
