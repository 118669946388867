import { Stack, Text } from '@chakra-ui/react';

interface BalanceCellProps {
  balance: string;
  usdValue: string;
}

const BalanceCell = ({ balance, usdValue }: BalanceCellProps): JSX.Element => {
  return (
    <Stack spacing={1}>
      <Text>{balance}</Text>
      <Text variant="body3" color="gray.300">
        {usdValue}
      </Text>
    </Stack>
  );
};

export default BalanceCell;
