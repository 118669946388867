import { alertAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers(alertAnatomy.keys);

const Alert = helpers.defineMultiStyleConfig({
  variants: {
    subtle: ({ colorScheme }) => ({
      container: {
        border: '1px solid',
        borderColor: `${colorScheme}.500`,
        borderRadius: 0,
        bgColor: 'background.header',
        boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.5)',
      },
      description: {
        fontWeight: 500,
        lineHeight: 6,
      },
    }),
  },
});

export default Alert;
