import { Box, Button, HStack, Stack, Text } from '@chakra-ui/react';
import IBStakeModal from 'components/Modals/IBStakeModal';
import IBUnstakeModal from 'components/Modals/IBUnstakeModal';
import useIBReward from 'hooks/useIBReward';
import { StackDirectionBreakPoint } from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import RewardTable from './RewardTable';

function IBReward(): JSX.Element {
  const { presentModal } = useModal();
  const {
    tokenRewardData: rewardData,
    allClaimableReward,
    isLoading,
    claimAllRewards,
    isClaimingAllRewards,
  } = useIBReward();

  return (
    <Box bgColor="background.darkGreen" p={{ base: 4, lg: 6 }}>
      <Stack
        direction={StackDirectionBreakPoint}
        justifyContent="space-between"
        mb="10"
      >
        <Text variant="headline2" color="primary.100">
          IB Reward
        </Text>
        <HStack gap={4} justify="space-between">
          <Text variant="subtitle3">
            IB Reward:{' '}
            {allClaimableReward.isLoading
              ? '...'
              : allClaimableReward.value.toFormat(2)}
          </Text>
          <Button
            size="xs"
            minW="5.25rem"
            variant="outline"
            disabled={allClaimableReward.isLoading || isClaimingAllRewards}
            onClick={() => claimAllRewards()}
          >
            Claim All
          </Button>
        </HStack>
      </Stack>
      <Box m={-4}>
        <RewardTable
          isLoading={isLoading}
          rewardData={rewardData}
          onStake={(stakingTokenAddress) =>
            presentModal({
              children: (
                <IBStakeModal stakingTokenAddress={stakingTokenAddress} />
              ),
            })
          }
          onUnstake={(rewardData) => {
            presentModal({
              children: <IBUnstakeModal rewardData={rewardData} />,
            });
          }}
        />
      </Box>
    </Box>
  );
}

export default IBReward;
