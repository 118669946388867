import { ProtocolContext } from 'providers/ProtocolProvider';
import { useContext, useMemo } from 'react';

export type Nav = {
  title: string;
  link: string;
  isExternal?: boolean;
};

export const useNavigationItems = (isHomePage = false) => {
  const { protocol: currentProtocol } = useContext(ProtocolContext);

  const navigationList = useMemo<Nav[]>(() => {
    if (isHomePage) {
      return [
        {
          title: 'Docs',
          isExternal: true,
          link: 'https://docs.ib.xyz/',
        },
        {
          title: 'GitHub',
          isExternal: true,
          link: 'https://github.com/ibdotxyz',
        },
      ];
    }
    const defaultNavigation: Nav[] = [
      {
        title: 'Lending',
        link: '/lending',
      },
      {
        title: 'Earn',
        link: `/earn`,
      },
      {
        title: 'veIB',
        link: '/veib',
      },
      {
        title: 'Bridge',
        link: '/bridge',
      },
    ];

    return currentProtocol.networkId === 250
      ? [
          ...defaultNavigation,
          {
            title: 'Bond',
            isExternal: true,
            link: 'https://pro.olympusdao.finance/#/bond/ib_ftm_bond',
          },
        ]
      : defaultNavigation;
  }, [currentProtocol.name, currentProtocol.networkId, isHomePage]);

  return navigationList;
};

export default useNavigationItems;
