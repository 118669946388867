import { useCallback, useContext, useMemo, useState } from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Stack,
  Button,
  Box,
  Switch,
} from '@chakra-ui/react';
import BalanceInput from 'components/BalanceInput';
import { commify, tokenPrice } from 'cream/utils';
import { BigNumber, utils } from 'ethers';
import useErc20Approve, { ApproveState } from 'hooks/useErc20Approve';
import useMarketData from 'hooks/useMarketData';
import useModal from 'hooks/useModal';
import useIBReward from 'hooks/useIBReward';
import useUserBorrowSummary from 'hooks/useUserBorrowSummary';
import useAlert, { AlertSeverity } from 'hooks/useAlert';
import useNativeBalance from 'hooks/useNativeBalance';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { CreamContext } from 'providers/CreamProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import Block from './components/Block';
import Info from './components/Info';
import ErrorMessage from './components/ErrorMessage';
import NumberedButton from '../NumberedButton';

interface IBStakeModalProps {
  stakingTokenAddress: string;
}

function IBStakeModal(props: IBStakeModalProps): JSX.Element {
  const { stakingTokenAddress } = props;
  const { protocol } = useContext(ProtocolContext);
  const { dismissModal } = useModal();
  const { showAlert } = useAlert();
  const { refresh } = useIBReward();
  const { cream } = useContext(CreamContext);
  const { addTx } = useContext(TxContext);
  const { connected } = useContext(ConnectionContext);
  const [stakeAmount, setStakeAmount] = useState<string>('0');
  const [isWrapped, setIsWrapped] = useState<boolean>(true);
  const { data: nativeBalance } = useNativeBalance();
  const [market, marketStats, userTokenStats] =
    useMarketData(stakingTokenAddress);
  const { basePrice } = useUserBorrowSummary();
  const { approveState, approveAll, allowance, isApproving } = useErc20Approve(
    market.underlyingAddress,
    protocol.stakingRewardHelperAddress
  );

  const amount = useMemo<BigNumber>(
    () => utils.parseUnits(stakeAmount || '0', market.underlyingDecimal),
    [market.underlyingDecimal, stakeAmount]
  );

  const isNative = useMemo<boolean>(
    () => market.isWrappedBaseAsset === true && !isWrapped,
    [isWrapped, market.isWrappedBaseAsset]
  );

  const userWalletBalance = useMemo<BigNumber>(() => {
    if (isNative) {
      return nativeBalance || BigNumber.from(0);
    }

    return userTokenStats.walletBalance;
  }, [isNative, nativeBalance, userTokenStats.walletBalance]);

  const isReady = useMemo<boolean>(() => {
    if (!connected) {
      return false;
    }

    if (!isNative && approveState !== ApproveState.APPROVED) {
      return false;
    }

    if (amount.lte(0)) {
      return false;
    }

    if (userWalletBalance.lt(amount)) {
      return false;
    }

    if (!isNative && allowance.lt(amount)) {
      return false;
    }

    return true;
  }, [allowance, amount, approveState, connected, isNative, userWalletBalance]);

  const errorMessage = useMemo(() => {
    if (amount.lt(0)) {
      return 'Invalid Amount';
    }

    if (userWalletBalance.lt(amount)) {
      return 'Insufficient Balance';
    }

    if (!isNative && allowance.lt(amount)) {
      return 'Insufficient Allowance';
    }

    if (amount.isZero()) {
      return '';
    }

    return '';
  }, [allowance, amount, isNative, userWalletBalance]);

  const handleStake = useCallback(async () => {
    try {
      if (!cream) {
        throw Error('staking error');
      }

      const tx = await cream.IBRewardStake(
        market.underlyingAddress,
        amount,
        isNative
      );

      if (!tx) {
        throw Error('staking error');
      }

      addTx(
        tx.hash,
        `Stake ${commify(stakeAmount)} ${
          isNative ? protocol.chainConfig.token : market.underlyingSymbol
        }`
      );
      dismissModal();
      await tx.wait(1);
    } catch (error) {
      console.warn('handleStake', error);
      showAlert({
        severity: AlertSeverity.Error,
        message: 'Stake Failed',
      });
    } finally {
      refresh();
    }
  }, [
    addTx,
    amount,
    cream,
    dismissModal,
    market.underlyingAddress,
    market.underlyingSymbol,
    refresh,
    showAlert,
    stakeAmount,
    isNative,
    protocol.chainConfig,
  ]);

  return (
    <ModalContent>
      <ModalHeader>
        Stake {isNative ? protocol.chainConfig.token : market.underlyingSymbol}
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <BalanceInput
            value={stakeAmount}
            maxBalance={userWalletBalance}
            symbol={
              isNative
                ? protocol.chainConfig.token || ''
                : market.underlyingSymbol
            }
            decimals={isNative ? 18 : market.underlyingDecimal}
            usdRate={tokenPrice(
              marketStats.underlyingPrice,
              market.underlyingDecimal,
              basePrice
            )}
            onChange={setStakeAmount}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          {market.isWrappedBaseAsset && (
            <Block>
              <Stack spacing={6}>
                <Info
                  title={`Stake ${protocol.chainConfig.token}`}
                  value={
                    <Box>
                      <Switch
                        isChecked={!isWrapped}
                        colorScheme="primary"
                        onChange={(event) =>
                          setIsWrapped(!event.target.checked)
                        }
                      />
                    </Box>
                  }
                />
              </Stack>
            </Block>
          )}
          <Stack spacing={4}>
            {isNative ? (
              <Button onClick={handleStake} disabled={!isReady}>
                Stake
              </Button>
            ) : (
              <>
                <NumberedButton
                  n={1}
                  onClick={() => approveAll()}
                  disabled={
                    (approveState === ApproveState.APPROVED &&
                      allowance.gte(amount)) ||
                    !connected
                  }
                  isLoading={isApproving}
                >
                  Approve
                </NumberedButton>
                <NumberedButton n={2} onClick={handleStake} disabled={!isReady}>
                  Stake
                </NumberedButton>
              </>
            )}
          </Stack>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
}

export default IBStakeModal;
