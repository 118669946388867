import { BigNumber } from 'ethers';
import { useCallback, useContext, useEffect, useState } from 'react';
import Erc20 from '../cream/contract/Erc20';
import { ConnectionContext } from '../providers/ConnectionProvider';
import useAlert, { AlertSeverity } from 'hooks/useAlert';

export enum ApproveState {
  UNKNOWN,
  NOT_APPROVED,
  APPROVED,
}

const useErc20Approve = (erc20Address: string, spender: string) => {
  const { signer, provider, connected, walletAddress } =
    useContext(ConnectionContext);

  const { showAlert } = useAlert();
  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [approveState, setApproveState] = useState(ApproveState.UNKNOWN);
  const [isApproving, setIsApproving] = useState<boolean>(false);

  const contract = useCallback(() => {
    return new Erc20(erc20Address, signer || provider);
  }, [signer, erc20Address, provider]);

  const fetchAllowance = useCallback(async () => {
    if (!connected || !walletAddress) {
      return;
    }

    const allowance = await contract().allowance(walletAddress, spender);
    const state = allowance.eq(0)
      ? ApproveState.NOT_APPROVED
      : ApproveState.APPROVED;

    setAllowance(allowance);
    setApproveState(state);
  }, [connected, walletAddress, contract, spender]);

  const approve = useCallback(
    async (amount: BigNumber) => {
      if (!connected || !walletAddress) {
        return;
      }

      try {
        setIsApproving(true);
        const tx = await contract().approve(spender, amount);
        await tx.wait();
        fetchAllowance();
      } finally {
        setIsApproving(false);
      }
    },
    [connected, walletAddress, contract, spender, fetchAllowance]
  );

  const approveAll = useCallback(async () => {
    if (!connected || !walletAddress) {
      return;
    }

    try {
      setIsApproving(true);
      const tx = await contract().approveAll(spender);
      await tx.wait();
      fetchAllowance();
    } catch (error) {
      showAlert({
        severity: AlertSeverity.Error,
        message: 'Approve Failed',
      });
    } finally {
      setIsApproving(false);
    }
  }, [connected, walletAddress, contract, spender, fetchAllowance, showAlert]);

  useEffect(() => {
    fetchAllowance().catch((err) =>
      console.error(`failed to fetch allowance: ${err.stack}`)
    );
  }, [fetchAllowance]);

  return {
    approve,
    approveAll,
    approveState,
    allowance,
    isApproving,
  };
};

export default useErc20Approve;
