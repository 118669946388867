import { useMemo } from 'react';
import { Button, Flex, HStack, Input, Stack, Text } from '@chakra-ui/react';
import BN from 'bignumber.js';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import TokenIconSymbol from './TokenIconSymbol';

interface BalanceInputProps {
  value: string;
  maxBalance: BigNumber;
  symbol: string;
  decimals: number;
  usdRate: number;
  onChange: (value: string) => void;
}

const BalanceInput = ({
  value,
  maxBalance,
  symbol,
  decimals,
  usdRate,
  onChange,
}: BalanceInputProps): JSX.Element => {
  const usdValue = useMemo(
    () => new BN(value || '0').multipliedBy(usdRate).toFormat(2),
    [usdRate, value]
  );

  return (
    <Stack spacing={2}>
      <HStack
        spacing={2}
        px={3}
        py={1}
        border="1px solid"
        borderColor="primary.100"
        justifyContent="space-between"
      >
        <Stack>
          <Input
            value={value}
            placeholder="0.00"
            variant="unstyled"
            type="number"
            inputMode="decimal"
            fontWeight="500"
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
          <Text fontSize="xs">${usdValue}</Text>
        </Stack>
        <TokenIconSymbol symbol={symbol} />
      </HStack>
      <Flex justifyContent="space-between">
        <Text>
          Balance: {new BN(formatUnits(maxBalance, decimals)).toFormat(4)}
        </Text>
        <Button
          variant="link"
          textDecoration="underline"
          onClick={() => {
            onChange(formatUnits(maxBalance, decimals));
          }}
        >
          Max
        </Button>
      </Flex>
    </Stack>
  );
};

export default BalanceInput;
