import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useClipboard,
} from '@chakra-ui/react';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { TxContext } from 'providers/TxProvider';
import React, { useContext, useMemo } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { RiFileCopyLine } from 'react-icons/ri';
import { shortAddress } from '../cream/utils';
import useExplorer from '../hooks/useExplorer';

interface AccountProps {
  walletAddress: string;
  ensName: string | null;
}

const Account = ({ walletAddress, ensName }: AccountProps): JSX.Element => {
  const { disconnectWallet } = useContext(ConnectionContext);
  const { openAddress } = useExplorer();
  const { onCopy } = useClipboard(walletAddress);

  return (
    <Stack spacing={4}>
      <Text variant="headline4" color="primary.100">
        Account
      </Text>
      <Flex direction="column">
        <Text variant="subtitle5" color="gray.500">
          Connected Wallet Address
        </Text>
        {ensName && (
          <Text variant="subtitle3" mt={2}>
            {ensName}
          </Text>
        )}
        <Button
          variant="link"
          rightIcon={<RiFileCopyLine />}
          color="inherit"
          mt={1}
          justifyContent="flex-start"
          onClick={() => onCopy()}
        >
          <Text variant="subtitle4">{shortAddress(walletAddress)}</Text>
        </Button>
      </Flex>
      <Button
        variant="link"
        rightIcon={<FaExternalLinkAlt />}
        mt={1}
        justifyContent="flex-start"
        onClick={() => openAddress(walletAddress)}
      >
        <Text variant="subtitle4">View on explorer</Text>
      </Button>
      <Button onClick={() => disconnectWallet()}>Disconnect</Button>
    </Stack>
  );
};

const TxButton = (): JSX.Element => {
  const { txs } = useContext(TxContext);
  const { walletAddress, ensName } = useContext(ConnectionContext);
  const pendingTxs = txs.filter((t) => !t.confirmed);

  const shortWalletAddress = useMemo<string>(() => {
    if (ensName) {
      return ensName;
    }
    if (!walletAddress) {
      return '';
    }
    return `${walletAddress.substring(0, 6)}...${walletAddress.substring(38)}`;
  }, [walletAddress, ensName]);

  if (!walletAddress) return <></>;

  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Button minW="6.5rem">{shortWalletAddress}</Button>
      </PopoverTrigger>
      <PopoverContent mt={10} bg="background.darkLinear" border="none">
        <PopoverBody p={4}>
          <Account walletAddress={walletAddress} ensName={ensName} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default TxButton;
