const VeIBClaimHelperABI = [
  {
    inputs: [
      { internalType: 'address[]', name: 'fee_dists', type: 'address[]' },
      { internalType: 'uint256[]', name: 'tokenIds', type: 'uint256[]' },
    ],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];
export default VeIBClaimHelperABI;
