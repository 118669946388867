import {
  JsonRpcProvider,
  JsonRpcSigner,
  Web3Provider,
} from '@ethersproject/providers';
import { useQuery } from '@tanstack/react-query';
import injectedModule from '@web3-onboard/injected-wallets';
import {
  init as initOnboard,
  useConnectWallet,
  useSetChain,
} from '@web3-onboard/react';
import walletConnectModule from '@web3-onboard/walletconnect';
import {
  getNetworkIdByHex,
  getNetworkIdByNumber,
  getProtocols,
  IronBankProtocol,
  onBoardChains,
} from 'cream/Protocols';
import { find, map } from 'lodash';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { ProtocolContext } from './ProtocolProvider';

const injected = injectedModule();
const walletConnect = walletConnectModule({
  projectId: 'bb110d59aaad5aad52924939a9d67da2',
  requiredChains: map(getProtocols(), (chain) => chain.networkId),
});

const onboard = initOnboard({
  wallets: [injected, walletConnect],
  chains: onBoardChains(),
  appMetadata: {
    name: 'Iron Bank',
    icon: '/ib.svg',
    description: 'Iron Bank',
  },
  accountCenter: {
    desktop: { enabled: false },
    mobile: { enabled: false },
  },
});

interface Context {
  connected: boolean;
  connectWallet(): void;
  disconnectWallet(): void;
  changeNetwork(chainId: number): void;
  walletAddress: string | null;
  ensName: string | null;
  networkId?: string;
  provider: JsonRpcProvider;
  signer: JsonRpcSigner | null;
  isSameNetwork: boolean;
}

export const ConnectionContext = createContext<Context>({
  connected: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  connectWallet: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  disconnectWallet: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeNetwork: () => {},
  walletAddress: null,
  ensName: null,
  networkId: '',
  provider: IronBankProtocol.defaultProvider,
  signer: null,
  isSameNetwork: false,
});

const ConnectionProvider = ({ children }: { children: React.ReactNode }) => {
  const { protocol, changeProtocol, protocols } = useContext(ProtocolContext);

  const [{ wallet }, connect, disconnect] = useConnectWallet();
  const [{ connectedChain }, setChain] = useSetChain();

  // since `changeProtocol` changes when `protocol` changes, we have to use a ref so that onboard subscription
  // uses the latest `changeProtocol`.
  const changeProtocolRef = useRef(changeProtocol);
  changeProtocolRef.current = changeProtocol;

  const connected = !!wallet;
  const provider = useMemo(() => {
    if (!wallet) {
      return protocol.defaultProvider;
    }
    return new Web3Provider(wallet.provider);
  }, [protocol, wallet]);

  const walletAddress = useMemo(() => {
    if (!wallet || !wallet.accounts[0]) {
      return null;
    }
    return wallet.accounts[0].address;
  }, [wallet]);

  const isSameNetwork = useMemo(() => {
    if (!connectedChain || !protocol) {
      return false;
    }

    if (!connectedChain.id || !protocol.networkId) {
      return false;
    }

    return connectedChain.id === getNetworkIdByNumber(protocol.networkId);
  }, [connectedChain, protocol]);

  const changeNetwork = useCallback(
    (chainId: number): void => {
      setChain({ chainId: getNetworkIdByNumber(chainId) });
    },
    [setChain]
  );

  useEffect(() => {
    if (!wallet || !connectedChain) {
      return;
    }

    if (isSameNetwork) {
      return;
    }

    const target = find(protocols, [
      'networkId',
      getNetworkIdByHex(connectedChain.id),
    ]);

    if (!target) {
      return;
    }

    changeProtocol(target);
  }, [
    changeProtocol,
    connectedChain,
    isSameNetwork,
    protocol,
    protocols,
    wallet,
  ]);

  useEffect(() => {
    const previouslyConnectedWalletLabel =
      window.localStorage.getItem('selectedWallet');
    if (previouslyConnectedWalletLabel) {
      onboard.connectWallet({
        autoSelect: {
          label: previouslyConnectedWalletLabel,
          disableModals: true,
        },
      });
    }
  }, []);

  // subscribe to wallet changes and save user selected wallet to local storage
  useEffect(() => {
    const walletSub = onboard.state.select('wallets');
    const { unsubscribe } = walletSub.subscribe((wallets) => {
      const connectedWalletLabel = wallets[0]?.label;
      if (connectedWalletLabel) {
        window.localStorage.setItem('selectedWallet', connectedWalletLabel);
      }
    });

    return () => {
      try {
        unsubscribe();
      } catch (e) {
        console.log('connection provider unsubscribe', e);
      }
    };
  }, []);

  const { data: ensName } = useQuery(
    ['ens-name', connectedChain, walletAddress],
    async (): Promise<string> => {
      if (
        !walletAddress ||
        !connectedChain ||
        getNetworkIdByHex(connectedChain.id) !== 1
      ) {
        return '';
      }

      const name = await provider.lookupAddress(walletAddress);
      return name || '';
    },
    {
      initialData: '',
      enabled: connected && !!walletAddress,
    }
  );

  const connectWallet = useCallback(async () => {
    await connect();
  }, [connect]);

  const disconnectWallet = useCallback(() => {
    if (wallet) {
      disconnect({ label: wallet.label });
    }
    window.localStorage.removeItem('selectedWallet');
  }, [disconnect, wallet]);

  const context = useMemo(
    () => ({
      connected,
      connectWallet,
      disconnectWallet,
      changeNetwork,
      walletAddress,
      ensName,
      networkId: connectedChain?.id,
      provider,
      signer: connected && walletAddress ? provider.getSigner() : null,
      isSameNetwork,
    }),
    [
      connected,
      connectWallet,
      disconnectWallet,
      changeNetwork,
      walletAddress,
      ensName,
      connectedChain,
      provider,
      isSameNetwork,
    ]
  );

  return (
    <ConnectionContext.Provider value={context}>
      {children}
    </ConnectionContext.Provider>
  );
};

export default ConnectionProvider;
