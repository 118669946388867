import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { CreamContext } from '../providers/CreamProvider';

const useIbPrice = () => {
  const { cream } = useContext(CreamContext);
  const { data: ibPrice, isLoading: isLoadingIbPrice } = useQuery(
    ['ib-price'],
    async () => {
      if (!cream) {
        return 0;
      }
      return await cream.getIBPrice();
    },
    {
      staleTime: 30 * 1000,
    }
  );

  return {
    ibPrice,
    isLoadingIbPrice,
  };
};

export default useIbPrice;
