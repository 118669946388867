import { popoverAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';
import { cssVar } from '@chakra-ui/theme-tools';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(popoverAnatomy.keys);

const $popperBg = cssVar('popper-bg');
const $arrowBg = cssVar('popper-arrow-bg');
const $arrowShadowColor = cssVar('popper-arrow-shadow-color');

const baseStyle = definePartsStyle({
  content: {
    borderRadius: '0',
    [$popperBg.variable]: '#0C1D14',
    [$arrowBg.variable]: $popperBg.reference,
  },
});

export default defineMultiStyleConfig({
  baseStyle,
  variants: {
    apy: {
      content: {
        px: 2,
        borderColor: 'primary.900',
        maxW: '10rem',
        [$arrowShadowColor.variable]: `#0F441D`,
      },
      header: {
        borderColor: 'primary.900',
        px: 0,
      },
      body: {
        px: 0,
      },
    },
  },
});
