import { statAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(statAnatomy.keys);

const baseStyle = definePartsStyle({
  label: {
    color: 'primary.100',
  },
});

const Stat = defineMultiStyleConfig({
  baseStyle,
  sizes: {},
  variants: {
    banner: {
      container: {
        pr: 2,
        flexGrow: 0,
      },
      label: {
        fontSize: 'md',
        fontWeight: 'bold',
        mb: 2,
        whiteSpace: 'nowrap',
      },
      number: {
        fontSize: '4xl',
      },
      helpText: {
        fontSize: 'sm',
        fontWeight: 400,
      },
    },
    card: {
      container: {
        flexGrow: 0,
      },
      label: {
        whiteSpace: 'nowrap',
        fontWeight: 500,
        fontsize: 'md',
        mb: 2,
      },
      number: {
        whiteSpace: 'nowrap',
        fontWeight: 700,
        fontsize: 'lg',
      },
    },
  },
  defaultProps: {
    variant: 'banner',
  },
});

export default Stat;
