const liquidityMiningABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'previousAdmin',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'address',
        name: 'newAdmin',
        type: 'address',
      },
    ],
    name: 'AdminChanged',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'beacon',
        type: 'address',
      },
    ],
    name: 'BeaconUpgraded',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'receiver',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'TransferReward',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'speed',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'end',
        type: 'uint256',
      },
    ],
    name: 'UpdateBorrowRewardSpeed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'borrower',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewards',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'borrowIndex',
        type: 'uint256',
      },
    ],
    name: 'UpdateBorrowerRewardIndex',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'bool',
        name: 'isDebtor',
        type: 'bool',
      },
    ],
    name: 'UpdateDebtor',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'receiver',
        type: 'address',
      },
    ],
    name: 'UpdateRewardReceiver',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'supplier',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'rewards',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'supplyIndex',
        type: 'uint256',
      },
    ],
    name: 'UpdateSupplierRewardIndex',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint256',
        name: 'speed',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'end',
        type: 'uint256',
      },
    ],
    name: 'UpdateSupplyRewardSpeed',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'implementation',
        type: 'address',
      },
    ],
    name: 'Upgraded',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
    ],
    name: 'addRewardToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'claimAllRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'cTokens',
        type: 'address[]',
      },
      {
        internalType: 'address[]',
        name: 'rewards',
        type: 'address[]',
      },
      {
        internalType: 'bool',
        name: 'borrowers',
        type: 'bool',
      },
      {
        internalType: 'bool',
        name: 'suppliers',
        type: 'bool',
      },
    ],
    name: 'claimRewards',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'reward',
        type: 'address',
      },
    ],
    name: 'claimSingleReward',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'market',
            type: 'address',
          },
          {
            internalType: 'bool',
            name: 'supply',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'borrow',
            type: 'bool',
          },
        ],
        internalType: 'struct LiquidityMining.UserMarkets[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'comptroller',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'debtors',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'cTokens',
        type: 'address[]',
      },
    ],
    name: 'getMultipleMarketRewardSpeeds',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'cToken',
            type: 'address',
          },
          {
            components: [
              {
                components: [
                  {
                    internalType: 'address',
                    name: 'rewardTokenAddress',
                    type: 'address',
                  },
                  {
                    internalType: 'string',
                    name: 'rewardTokenSymbol',
                    type: 'string',
                  },
                  {
                    internalType: 'uint8',
                    name: 'rewardTokenDecimals',
                    type: 'uint8',
                  },
                ],
                internalType: 'struct LiquidityMining.RewardTokenInfo',
                name: 'rewardToken',
                type: 'tuple',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'speed',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'end',
                    type: 'uint256',
                  },
                ],
                internalType: 'struct LiquidityMiningStorage.RewardSpeed',
                name: 'supplySpeed',
                type: 'tuple',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'speed',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'end',
                    type: 'uint256',
                  },
                ],
                internalType: 'struct LiquidityMiningStorage.RewardSpeed',
                name: 'borrowSpeed',
                type: 'tuple',
              },
            ],
            internalType: 'struct LiquidityMining.RewardSpeedInfo[]',
            name: 'rewardSpeeds',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct LiquidityMining.MarketRewardSpeed[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getBlockTimestamp',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
    ],
    name: 'getMarketRewardSpeeds',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'cToken',
            type: 'address',
          },
          {
            components: [
              {
                components: [
                  {
                    internalType: 'address',
                    name: 'rewardTokenAddress',
                    type: 'address',
                  },
                  {
                    internalType: 'string',
                    name: 'rewardTokenSymbol',
                    type: 'string',
                  },
                  {
                    internalType: 'uint8',
                    name: 'rewardTokenDecimals',
                    type: 'uint8',
                  },
                ],
                internalType: 'struct LiquidityMining.RewardTokenInfo',
                name: 'rewardToken',
                type: 'tuple',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'speed',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'end',
                    type: 'uint256',
                  },
                ],
                internalType: 'struct LiquidityMiningStorage.RewardSpeed',
                name: 'supplySpeed',
                type: 'tuple',
              },
              {
                components: [
                  {
                    internalType: 'uint256',
                    name: 'speed',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'start',
                    type: 'uint256',
                  },
                  {
                    internalType: 'uint256',
                    name: 'end',
                    type: 'uint256',
                  },
                ],
                internalType: 'struct LiquidityMiningStorage.RewardSpeed',
                name: 'borrowSpeed',
                type: 'tuple',
              },
            ],
            internalType: 'struct LiquidityMining.RewardSpeedInfo[]',
            name: 'rewardSpeeds',
            type: 'tuple[]',
          },
        ],
        internalType: 'struct LiquidityMining.MarketRewardSpeed',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
    ],
    name: 'getRewardTokenInfo',
    outputs: [
      {
        components: [
          {
            internalType: 'address',
            name: 'rewardTokenAddress',
            type: 'address',
          },
          {
            internalType: 'string',
            name: 'rewardTokenSymbol',
            type: 'string',
          },
          {
            internalType: 'uint8',
            name: 'rewardTokenDecimals',
            type: 'uint8',
          },
        ],
        internalType: 'struct LiquidityMining.RewardTokenInfo',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'getRewardTokenList',
    outputs: [
      {
        internalType: 'address[]',
        name: '',
        type: 'address[]',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'getRewardsAvailable',
    outputs: [
      {
        components: [
          {
            components: [
              {
                internalType: 'address',
                name: 'rewardTokenAddress',
                type: 'address',
              },
              {
                internalType: 'string',
                name: 'rewardTokenSymbol',
                type: 'string',
              },
              {
                internalType: 'uint8',
                name: 'rewardTokenDecimals',
                type: 'uint8',
              },
            ],
            internalType: 'struct LiquidityMining.RewardTokenInfo',
            name: 'rewardToken',
            type: 'tuple',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        internalType: 'struct LiquidityMining.RewardAvailable[]',
        name: '',
        type: 'tuple[]',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_admin',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '_comptroller',
        type: 'address',
      },
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardAccrued',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardBorrowSpeeds',
    outputs: [
      {
        internalType: 'uint256',
        name: 'speed',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'end',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardBorrowState',
    outputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardBorrowerIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardReceivers',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardSupplierIndex',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardSupplySpeeds',
    outputs: [
      {
        internalType: 'uint256',
        name: 'speed',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'start',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'end',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardSupplyState',
    outputs: [
      {
        internalType: 'uint256',
        name: 'index',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: 'timestamp',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    name: 'rewardTokens',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    name: 'rewardTokensMap',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'cTokens',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'speeds',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'starts',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'ends',
        type: 'uint256[]',
      },
    ],
    name: 'setRewardBorrowSpeeds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'rewardToken',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'cTokens',
        type: 'address[]',
      },
      {
        internalType: 'uint256[]',
        name: 'speeds',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'starts',
        type: 'uint256[]',
      },
      {
        internalType: 'uint256[]',
        name: 'ends',
        type: 'uint256[]',
      },
    ],
    name: 'setRewardSupplySpeeds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'receiver',
        type: 'address',
      },
    ],
    name: 'setRewardsReceiver',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'borrowers',
        type: 'address[]',
      },
    ],
    name: 'updateBorrowIndex',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address[]',
        name: 'accounts',
        type: 'address[]',
      },
    ],
    name: 'updateDebtors',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'cToken',
        type: 'address',
      },
      {
        internalType: 'address[]',
        name: 'suppliers',
        type: 'address[]',
      },
    ],
    name: 'updateSupplyIndex',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address',
      },
    ],
    name: 'upgradeTo',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newImplementation',
        type: 'address',
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes',
      },
    ],
    name: 'upgradeToAndCall',
    outputs: [],
    stateMutability: 'payable',
    type: 'function',
  },
];

export default liquidityMiningABI;
