import React, { createContext, useMemo, useState } from 'react';
import {
  DevProtocols,
  IronBankProtocol,
  ProductionProtocols,
  Protocol,
} from '../cream/Protocols';

interface Context {
  protocol: Protocol;
  protocols: Protocol[];
  changeProtocol(protocol: Protocol): void;
}

export const ProtocolContext = createContext<Context>({
  protocol: IronBankProtocol,
  protocols: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeProtocol: () => {},
});

const ProtocolProvider = ({ children }: { children: React.ReactNode }) => {
  const protocols = useMemo(() => {
    if (process.env.REACT_APP_TESTNET) {
      return DevProtocols;
    }
    return ProductionProtocols;
  }, []);

  const cachedProtocol = useMemo(() => {
    let protocol = IronBankProtocol;
    const previouslySelectedProtocol =
      window.localStorage.getItem('selectedProtocol');
    for (const p of protocols) {
      if (p.name === previouslySelectedProtocol) {
        protocol = p;
      }
    }
    return protocol;
  }, [protocols]);

  const [protocol, setProtocol] = useState<Protocol>(cachedProtocol);

  const changeProtocol = (newProtocol: Protocol) => {
    if (newProtocol === protocol) {
      return;
    }
    setProtocol(newProtocol);
    window.localStorage.setItem('selectedProtocol', newProtocol.name);
  };

  const context = {
    protocol,
    protocols,
    changeProtocol,
  };

  return (
    <ProtocolContext.Provider value={context}>
      {children}
    </ProtocolContext.Provider>
  );
};

export default ProtocolProvider;
