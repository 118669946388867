import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const primaryThin = defineStyle({
  h: 0,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: 'primary.100',
  opacity: 0.25,
});

export const dividerTheme = defineStyleConfig({
  variants: { primaryThin },
});

export default dividerTheme;
