import { useContext, useMemo, useState } from 'react';
import { Container, Stack, Text } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import BN from 'bignumber.js';
import { BigNumber, constants } from 'ethers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import useAlert, { AlertSeverity } from 'hooks/useAlert';
import useErc20Approve, { ApproveState } from 'hooks/useErc20Approve';
import useIbPrice from 'hooks/useIbPrice';
import BalanceInput from 'components/BalanceInput';
import ErrorMessage from 'components/Modals/components/ErrorMessage';
import NumberedButton from 'components/NumberedButton';
import SwitchNetworkCard from 'components/SwitchNetworkCard';
import { IbOFT__factory } from 'cream/contract/ABIs/types';
import { Erc20__factory } from 'cream/contract/ABIs/types';
import { FantomProtocol } from 'cream/Protocols';
import { toDigits } from 'cream/utils';

export const Bridge = () => {
  const { addTx } = useContext(TxContext);
  const { walletAddress, signer, provider } = useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);
  const { showAlert } = useAlert();
  const [inputAmount, setInputAmount] = useState<string>('0');

  const { ibPrice } = useIbPrice();

  const { approveState, allowance, approveAll, isApproving } = useErc20Approve(
    protocol.ibAddress,
    protocol.ibLayerZeroBridgeAddress
  );

  const ibOft = IbOFT__factory.connect(
    protocol.ibLayerZeroBridgeAddress,
    provider
  );

  const ibToken = Erc20__factory.connect(protocol.ibAddress, provider);

  const { data: userIBBalance, isSuccess: isIBBalanceSuccess } =
    useQuery<BigNumber>(
      ['ib-balance', walletAddress],
      async () => {
        if (!walletAddress) {
          return BigNumber.from(0);
        }
        return await ibToken.balanceOf(walletAddress);
      },
      { enabled: !!walletAddress, initialData: BigNumber.from(0) }
    );

  const { data: userBalance, isSuccess: isUserBalanceSuccess } = useQuery(
    ['user-balance', protocol.name],
    async () => {
      return signer?.getBalance();
    },
    { enabled: !!signer }
  );

  const amount: BigNumber = parseUnits(inputAmount || '0', 18);

  const { data: bridgeFee, isSuccess: isBridgeFeeSuccess } =
    useQuery<BigNumber>(
      ['ib-bridge-estimate-fee'],
      async () => {
        const estimate = await ibOft.estimateSendFee(
          111,
          constants.AddressZero,
          amount,
          false,
          '0x'
        );
        return estimate.nativeFee;
      },
      { initialData: BigNumber.from(0) }
    );

  const safeBridgeFee = bridgeFee.mul(11).div(10);

  const isSuccess =
    isIBBalanceSuccess && isUserBalanceSuccess && isBridgeFeeSuccess;

  const errorMessage = useMemo(() => {
    if (amount.lt(0)) {
      return 'Invalid Amount';
    }

    if (userIBBalance.lt(amount)) {
      return 'Insufficient IB Balance';
    }

    if (allowance.lt(amount)) {
      return 'Insufficient Allowance';
    }

    if (userBalance && bridgeFee) {
      if (userBalance.lt(bridgeFee)) {
        return 'Insufficient Bridge Fee';
      }
    }

    return '';
  }, [amount, userIBBalance, allowance, userBalance, bridgeFee]);

  const isValid = useMemo(() => {
    if (!isSuccess) {
      return false;
    }

    if (approveState !== ApproveState.APPROVED) {
      return false;
    }

    if (amount.lte(0)) {
      return false;
    }

    return errorMessage === '';
  }, [amount, approveState, errorMessage, isSuccess]);

  const { mutateAsync: migrate, isLoading: isMigrating } = useMutation({
    mutationFn: async () => {
      if (!signer || !isValid) throw Error('not ready');

      const tx = await ibOft
        .connect(signer)
        .migrate(amount, { value: safeBridgeFee });

      addTx(
        tx.hash,
        `Migrate ${BN(amount.toString()).toFormat(2)} IB to Optimism`
      );
      await tx.wait();
    },
  });

  const onMigrate = async () => {
    try {
      await migrate();
      showAlert({
        severity: AlertSeverity.Success,
        message: 'Migrate Success',
      });
    } catch {
      showAlert({
        severity: AlertSeverity.Error,
        message: 'Migrate Failed',
      });
    }
  };

  return (
    <Container maxW="lg" bgColor="background.light" py={4}>
      {protocol.networkId !== FantomProtocol.networkId ? (
        <SwitchNetworkCard targetNetwork={FantomProtocol} />
      ) : (
        <Stack bgColor="background.darkGreen" p={6} spacing={4}>
          <Text variant="headline2" color="primary.100" mb={6}>
            Bridge IB to Optimism
          </Text>
          <BalanceInput
            value={inputAmount}
            symbol="IB"
            decimals={18}
            maxBalance={userIBBalance || BigNumber.from(0)}
            onChange={setInputAmount}
            usdRate={BN(ibPrice || 0).toNumber()}
          />
          {errorMessage && <ErrorMessage message={errorMessage} />}
          <Stack spacing={1}>
            <Text textAlign="left">
              Bridge Fee: {toDigits(formatUnits(safeBridgeFee, 18), 2)} FTM
            </Text>
            <Text textAlign="left">Bridge Estimation: 2 ~ 5 mins</Text>
          </Stack>
          <Stack spacing={4}>
            <NumberedButton
              n={1}
              isLoading={isApproving}
              disabled={
                approveState === ApproveState.APPROVED && allowance.gte(amount)
              }
              onClick={() => approveAll()}
            >
              Approve
            </NumberedButton>
            <NumberedButton
              n={2}
              disabled={!isValid}
              isLoading={isMigrating}
              onClick={() => onMigrate()}
            >
              Migrate
            </NumberedButton>
          </Stack>
        </Stack>
      )}
    </Container>
  );
};

export default Bridge;
