import { BigNumber } from 'ethers';
import { useContext } from 'react';
import {
  Market,
  MarketStats,
  UserTokenStats,
  UserLiquidityRewards,
  LMRewardStats,
} from '../cream/Type';
import { sameAddress } from '../cream/utils';
import { MarketContext } from '../providers/MarketProvider';

const useMarketData = (
  marketAddress: string
): [
  Market,
  MarketStats,
  UserTokenStats,
  UserLiquidityRewards,
  LMRewardStats
] => {
  const {
    allMarketStats,
    allUserTokenStats,
    userLiquidityRewards,
    markets,
    lmRewardsStats,
  } = useContext(MarketContext);

  const idx = markets.findIndex((market) =>
    sameAddress(market.address, marketAddress)
  );
  const rewards = userLiquidityRewards.find((market) =>
    sameAddress(market.address, marketAddress)
  ) || {
    reward: BigNumber.from(0),
    address: marketAddress,
  };
  return [
    markets[idx],
    allMarketStats[idx],
    allUserTokenStats[idx],
    rewards,
    lmRewardsStats[idx],
  ];
};

export default useMarketData;
