import { useMemo } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { pullAt } from 'lodash';
import { utils } from 'ethers';
import { Box, Button, HStack, SkeletonText, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import BN from 'bignumber.js';
import { IBTokenRewardData } from 'hooks/useIBReward';
import { useMediaQuery } from 'hooks/useMediaQuery';
import Table from 'components/Table';
import TokenIconSymbol from 'components/TokenIconSymbol';
import BalanceCell from 'components/BalanceCell';

interface RewardTableProps {
  rewardData: IBTokenRewardData[];
  isLoading?: boolean;
  onStake: (stakingTokenAddress: string) => void;
  onUnstake: (rewardData: IBTokenRewardData) => void;
}

const formatAPY: (value: BN) => string = (value) =>
  value.shiftedBy(2).toFormat(2) + '%';

function RewardTable(props: RewardTableProps): JSX.Element {
  const { rewardData, onStake, onUnstake, isLoading = false } = props;
  const { isMobile } = useMediaQuery();

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<IBTokenRewardData>();
    let columns = [
      columnHelper.accessor('underlyingSymbol', {
        header: 'Asset',
        cell: (props) => <TokenIconSymbol symbol={props.getValue()} />,
      }),
      columnHelper.accessor('userStakedBalance', {
        header: () => (
          <Text>
            Stacked
            {isMobile ? <br /> : ' '}
            Balance
          </Text>
        ),
        cell: ({
          row: {
            original: {
              userStakedBalance,
              underlyingDecimal,
              userStakedBalanceUSD,
            },
          },
        }) => (
          <BalanceCell
            balance={new BN(
              utils.formatUnits(userStakedBalance, underlyingDecimal)
            ).toFormat(4)}
            usdValue={`$${userStakedBalanceUSD.toFormat(4)}`}
          />
        ),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('netAPY', {
        header: 'Net APY',
        cell: (props) => formatAPY(props.getValue()),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('stakingAPY', {
        header: 'Staking APY',
        cell: (props) => formatAPY(props.getValue()),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('nativeAPY', {
        header: 'Native APY',
        cell: (props) => formatAPY(props.getValue()),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('userClaimableIBAmount', {
        header: () =>
          isMobile ? (
            <Text>
              Claimable
              <br />
              Reward
            </Text>
          ) : (
            'Claimable Reward'
          ),
        cell: (props) => `${props.getValue().toFormat(2)} IB`,
        meta: { isNumeric: true },
      }),
      columnHelper.display({
        id: 'actions',
        header: '',
        cell: ({ row: { original } }) => (
          <HStack>
            <Button
              size="xs"
              minW="5.75rem"
              variant="outline"
              onClick={() => onStake(original.tokenAddress)}
            >
              Stake
            </Button>
            <Button
              size="xs"
              minW="6.75rem"
              variant="outline"
              disabled={original.userStakedBalance.isZero()}
              onClick={() => onUnstake(original)}
            >
              Unstake and Claim
            </Button>
          </HStack>
        ),
      }),
    ];

    // select only a subset of columns on mobile
    if (isMobile) {
      columns = pullAt(columns, [0, 1, 2, 5]);
      columns.push(
        columnHelper.display({
          id: 'actions',
          header: '',
          cell: ({ row }) =>
            row.getIsExpanded() ? <FaAngleUp /> : <FaAngleDown />,
        })
      );
    }

    return columns;
  }, [isMobile, onStake, onUnstake]);

  return (
    <Box w="full">
      {isLoading ? (
        <Box p={{ base: 4, lg: 6 }}>
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        </Box>
      ) : (
        <Table
          columns={columns}
          data={rewardData}
          py={4}
          onRowClick={
            isMobile
              ? (row, table) => {
                  table.resetExpanded();
                  row.toggleExpanded(!row.getIsExpanded());
                }
              : undefined
          }
          enableExpanding={isMobile}
          renderSubcomponent={({ original }) => (
            <HStack>
              <Button
                size="xs"
                minW="5.75rem"
                variant="outline"
                flex="1 1 0"
                onClick={() => onStake(original.tokenAddress)}
              >
                Stake
              </Button>
              <Button
                size="xs"
                minW="6.75rem"
                variant="outline"
                flex="1 1 0"
                disabled={original.userStakedBalance.isZero()}
                onClick={() => onUnstake(original)}
              >
                Unstake and Claim
              </Button>
            </HStack>
          )}
        />
      )}
    </Box>
  );
}

export default RewardTable;
