import { Tooltip, Box, Icon } from '@chakra-ui/react';
import { BsExclamationCircle } from 'react-icons/bs';

export const AvailableToolTip = () => {
  return (
    <Tooltip
      hasArrow
      placement="top"
      label="This is the total amount available to borrow. You can borrow based on your collateral and until the borrow limit or borrow cap is reached."
    >
      <Box as="span">
        <Icon as={BsExclamationCircle} boxSize="13px" ml={1} />
      </Box>
    </Tooltip>
  );
};

export default AvailableToolTip;
