import { Box, Button, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import BalanceCell from 'components/BalanceCell';
import BorrowModal from 'components/Modals/BorrowModal';
import Table from 'components/Table';
import TokenIconSymbol from 'components/TokenIconSymbol';
import { Category } from 'cream/Category';
import { displayBalance, getUnderlyingBalanceInUSD } from 'cream/utils';
import { BigNumber, ethers } from 'ethers';
import { AlertSeverity, useAlert } from 'hooks/useAlert';
import {
  BorrowMarketData,
  useBorrowMarketData,
} from 'hooks/useBorrowMarketData';
import useMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import { useUserBorrowSummary } from 'hooks/useUserBorrowSummary';
import { filter } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import AvailableToolTip from './AvailableToolTip';
import MarketCard from './MarketCard';
import StEthApyPopover, { isWstEthAddress } from './StEthApyPopover';

const BorrowMarket: React.FC = () => {
  const { isMobile } = useMediaQuery();
  const data = useBorrowMarketData();
  const { borrowLimitInNative, basePrice, totalBorrowBalanceInNative } =
    useUserBorrowSummary();
  const { presentModal } = useModal();
  const { showAlert } = useAlert();

  const getUserBorrowPowerInToken = useCallback(
    (market: BorrowMarketData) => {
      const { underlyingPrice } = market;
      if (underlyingPrice.isZero()) {
        return BigNumber.from(0);
      }

      let borrowPowerInNative = BigNumber.from(0);

      if (borrowLimitInNative.gt(totalBorrowBalanceInNative)) {
        borrowPowerInNative = borrowLimitInNative.sub(
          totalBorrowBalanceInNative
        );
      }

      return borrowPowerInNative
        .mul(ethers.constants.WeiPerEther)
        .div(market.underlyingPrice);
    },
    [borrowLimitInNative, totalBorrowBalanceInNative]
  );

  const getUserBorrowAvailable = useCallback(
    (market: BorrowMarketData) => {
      const userBorrowPowerInToken = getUserBorrowPowerInToken(market);
      const { borrowAvailable } = market;
      return userBorrowPowerInToken.lt(borrowAvailable)
        ? userBorrowPowerInToken
        : borrowAvailable;
    },
    [getUserBorrowPowerInToken]
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<BorrowMarketData>();
    return [
      columnHelper.accessor('asset', {
        header: 'Asset',
        cell: (props) => (
          <TokenIconSymbol
            data-tag="allowRowEvents"
            symbol={props.getValue()}
          />
        ),
      }),
      columnHelper.accessor('apy', {
        header: 'APY',
        cell: ({
          row: {
            original: { apy, borrowRate, address },
          },
        }) => {
          return (
            <Stack spacing={1} alignItems="end">
              <Text>{apy}</Text>
              {isWstEthAddress(address) && (
                <StEthApyPopover supplyOrBorrowRate={borrowRate} />
              )}
            </Stack>
          );
        },
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('liquidity', {
        header: () => (
          <Box>
            Available
            <AvailableToolTip />
          </Box>
        ),
        cell: ({ row: { original: market } }) => {
          const userBorrowAvailable = getUserBorrowAvailable(market);
          const displayUserBorrowAvailable = displayBalance(
            userBorrowAvailable,
            market.underlyingDecimal,
            2
          );
          const displayUserBorrowAvailableInUSD = getUnderlyingBalanceInUSD(
            userBorrowAvailable,
            market.underlyingDecimal,
            market.underlyingPrice
          );
          return (
            <BalanceCell
              balance={displayUserBorrowAvailable}
              usdValue={`$${displayUserBorrowAvailableInUSD}`}
            />
          );
        },
        meta: { isNumeric: true },
      }),
      columnHelper.display({
        id: 'borrowAction',
        header: '',
        cell: ({ row: { original } }) => (
          <Button
            size="xs"
            minW="5.25rem"
            onClick={() => {
              if (
                borrowLimitInNative.isZero() ||
                basePrice === 0 ||
                borrowLimitInNative.mul(basePrice).isZero()
              ) {
                showAlert({
                  message:
                    'To borrow you need to supply any asset to be used as collateral ',
                  severity: AlertSeverity.Warning,
                });
              } else {
                presentModal({
                  children: <BorrowModal marketAddress={original.address} />,
                });
              }
            }}
          >
            Borrow
          </Button>
        ),
        meta: { isHidden: isMobile },
      }),
    ];
  }, [
    basePrice,
    borrowLimitInNative,
    getUserBorrowAvailable,
    isMobile,
    presentModal,
    showAlert,
  ]);

  return (
    <MarketCard title="Assets to Borrow">
      <Table
        columns={columns}
        data={filter(data, ({ category, balance, asset }) => {
          if (asset === 'sUSD') {
            return false;
          }

          if (category !== Category.legacy) {
            return true;
          }

          return balance !== '0';
        })}
        headerProps={{ lineHeight: '2rem' }}
        enableHoverBgColor={isMobile}
        onRowClick={
          isMobile
            ? ({ original }) => {
                if (
                  borrowLimitInNative.isZero() ||
                  basePrice === 0 ||
                  borrowLimitInNative.mul(basePrice).isZero()
                ) {
                  showAlert({
                    message:
                      'To borrow you need to supply any asset to be used as collateral ',
                    severity: AlertSeverity.Warning,
                  });
                } else {
                  presentModal({
                    children: <BorrowModal marketAddress={original.address} />,
                  });
                }
              }
            : undefined
        }
      />
    </MarketCard>
  );
};

export default BorrowMarket;
