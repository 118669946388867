import { useContext, useMemo } from 'react';
import { BigNumber } from 'ethers';
import { reduce } from 'lodash';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import useUserBorrowSummary from 'hooks/useUserBorrowSummary';
import {
  borrowLimitPercentage,
  displayBalance,
  oracleNativeBalance,
  rateToApy,
  getUnderlyingBalanceInUSD,
} from 'cream/utils';

export type UserBorrowMarketData = {
  asset: string;
  address: string;
  apy: string;
  borrowRate: BigNumber;
  balance: string;
  balanceInUSD: string;
  limit: string;
};

interface UseUserBorrowMarketDataAPI {
  borrowData: UserBorrowMarketData[];
  isLoading: boolean;
}

export const useUserBorrowMarketData = (): UseUserBorrowMarketDataAPI => {
  const { markets, allMarketStats, allUserTokenStats, isLoading } =
    useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);
  const { borrowLimitInNative } = useUserBorrowSummary();

  const borrowData: UserBorrowMarketData[] = useMemo(
    () =>
      reduce(
        allUserTokenStats,
        (acc: UserBorrowMarketData[], userTokenStats, index) => {
          const marketStats = allMarketStats[index];
          const market = markets[index];

          if (userTokenStats.borrowBalance.eq(0)) {
            return acc;
          }

          const nativeBalance = oracleNativeBalance(
            userTokenStats.borrowBalance,
            marketStats.underlyingPrice
          );

          return acc.concat({
            asset: market.underlyingSymbol,
            address: market.address,
            apy: rateToApy(marketStats.borrowRate, protocol.blocksPerYear),
            borrowRate: marketStats.borrowRate,
            balance: displayBalance(
              userTokenStats.borrowBalance,
              market.underlyingDecimal,
              2
            ),
            balanceInUSD: getUnderlyingBalanceInUSD(
              userTokenStats.borrowBalance,
              market.underlyingDecimal,
              marketStats.underlyingPrice
            ),
            limit: borrowLimitPercentage(borrowLimitInNative, nativeBalance),
          });
        },
        []
      ),
    [markets, allUserTokenStats, allMarketStats, protocol, borrowLimitInNative]
  );

  return {
    borrowData,
    isLoading,
  };
};

export default useUserBorrowMarketData;
