import { HStack, Button } from '@chakra-ui/react';
import { add } from 'date-fns';
import { map, initial } from 'lodash';
import { TimeUnit } from 'cream/Type';

type SelectOptions = {
  label: string;
  value: number;
  unit: TimeUnit;
};

interface QuickDurationPickerProps {
  onChange: (value: Date) => void;
  showMax?: boolean;
}

function QuickDurationPicker(props: QuickDurationPickerProps): JSX.Element {
  const { onChange, showMax = true } = props;

  const quickDuration: SelectOptions[] = [
    {
      label: '1 month',
      value: 1,
      unit: TimeUnit.Months,
    },
    {
      label: '3 months',
      value: 3,
      unit: TimeUnit.Months,
    },
    {
      label: '6 months',
      value: 6,
      unit: TimeUnit.Months,
    },
    {
      label: '1 year',
      value: 1,
      unit: TimeUnit.Years,
    },
    {
      label: '4 years',
      value: 4,
      unit: TimeUnit.Years,
    },
  ];

  return (
    <HStack>
      {map(
        showMax ? quickDuration : initial(quickDuration),
        ({ label, value, unit }, idx) => {
          const newLockTime = add(new Date(), { [unit]: value });
          return (
            <Button
              variant="shortcut"
              size="xs"
              key={`${idx}-${unit}-${value}`}
              onClick={() => onChange(newLockTime)}
            >
              {label}
            </Button>
          );
        }
      )}
    </HStack>
  );
}

export default QuickDurationPicker;
