import { useContext } from 'react';
import { sameAddress } from '../cream/utils';
import { ProtocolContext } from '../providers/ProtocolProvider';

const useIsNativeContract = (marketAddress: string) => {
  const { protocol } = useContext(ProtocolContext);
  return sameAddress(protocol.nativeContractAddress, marketAddress);
};

export default useIsNativeContract;
