import { extendTheme, theme as baseTheme } from '@chakra-ui/react';
import Alert from './components/Alert';
import Button from './components/Button';
import Container from './components/Container';
import DatePicker from './components/DatePicker';
import Divider from './components/Divider';
import Link from './components/Link';
import Menu from './components/Menu';
import Modal from './components/Modal';
import Popover from './components/Popover';
import Stat from './components/Stat';
import Switch from './components/Switch';
import Table from './components/Table';
import Tabs from './components/Tabs';
import Text from './components/Text';
import Tooltip from './components/ToolTip';

export const chakraTheme = extendTheme({
  styles: {
    global: {
      'html, body': {
        backgroundColor: 'background.light',
      },
    },
  },
  colors: {
    primary: {
      100: '#96FFB1',
      300: '#68E98A',
      500: '#25AA48',
      700: '#187430',
      900: '#0F441D',
    },
    secondary: {
      100: '#FFE3B2',
      300: '#FCD78B',
      500: '#FAC748',
      700: '#FAC748',
      900: '#EBA134',
    },
    background: {
      header: '#0E0E0F',
      dark: '#19201E',
      light: '#262E2C',
      darkGreen: '#182420',
      darkGray: '#1A191C',
      popup: '#0C1D14',
      greenLinear: 'linear-gradient(118.15deg, #39B54A 16.62%, #009444 75.4%);',
      darkLinear: 'linear-gradient(180deg, #0E0E0F 0%, #03170D 100%);',
      redLinear:
        'linear-gradient(83.13deg, rgba(181, 57, 57, 0.1) 48.16%, rgba(255, 18, 18, 0.1) 58.17%);',
    },
    text: {
      disabled: '#758096',
    },
    gray: {
      100: '#F9FAFD',
      300: '#E9ECF1',
      500: '#949DB1',
      700: '#3B4250',
      900: '#151A26',
    },
    green: {
      ...baseTheme.colors.green,
      500: '#96FFB1',
    },
    red: {
      ...baseTheme.colors.red,
      500: '#FF7373',
    },
    orange: {
      ...baseTheme.colors.orange,
      500: '#FAC748',
    },
  },
  sizes: {
    container: {
      base: '343px',
      lg: '1080px',
    },
  },
  breakpoints: {
    lg: '67.5em', // 1080px
  },
  fontSizes: {
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    md: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.75rem', // 28px
    '4xl': '2rem', // 32px
    '5xl': '3rem', // 48px
  },
  components: {
    Alert,
    Button,
    Container,
    DatePicker,
    Divider,
    Link,
    Menu,
    Modal,
    Popover,
    Stat,
    Switch,
    Table,
    Tabs,
    Tooltip,
    Text,
  },
});

export default chakraTheme;
