import { useContext, useMemo } from 'react';
import { BigNumber, ethers } from 'ethers';
import BN from 'bignumber.js';
import { reduce } from 'lodash';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import {
  oracleNativeBalance,
  rateToApy,
  getNetRate,
  underlyingBalance,
} from 'cream/utils';

export type UserSupplySummary = {
  totalSupplyBalanceInUSD: BN;
  totalSupplyBalanceInNative: BigNumber;
  netAPY: string;
  netSupplyAPY: string;
  basePrice: number;
  totalCollateralInNative: BigNumber;
};

export const useUserSupplySummary = (): UserSupplySummary => {
  const { protocol } = useContext(ProtocolContext);
  const { markets, allMarketStats, allUserTokenStats, basePrice } =
    useContext(MarketContext);

  const totalSupplyRateInNative = useMemo<BigNumber>(
    () =>
      reduce(
        markets,
        (acc, market, index) => {
          const userTokenStats = allUserTokenStats[index];
          const marketStats = allMarketStats[index];
          if (userTokenStats.crTokenBalance.isZero()) {
            return acc;
          }

          const nativeSupplyBalance = oracleNativeBalance(
            userTokenStats.underlyingBalance,
            marketStats.underlyingPrice
          );

          const nativeSupplyRate = nativeSupplyBalance.mul(
            marketStats.supplyRate
          );

          return acc.add(nativeSupplyRate);
        },
        BigNumber.from(0)
      ),
    [allMarketStats, allUserTokenStats, markets]
  );

  const { totalSupplyBalanceInNative, totalRateInNative } = useMemo(
    () =>
      reduce(
        markets,
        ({ totalSupplyBalanceInNative, totalRateInNative }, market, index) => {
          const userTokenStats = allUserTokenStats[index];
          const marketStats = allMarketStats[index];

          if (
            userTokenStats.crTokenBalance.eq(0) &&
            userTokenStats.borrowBalance.eq(0)
          ) {
            return {
              totalSupplyBalanceInNative,
              totalRateInNative,
            };
          }

          const nativeSupplyBalance = oracleNativeBalance(
            userTokenStats.underlyingBalance,
            marketStats.underlyingPrice
          );

          const nativeSupplyRate = nativeSupplyBalance.mul(
            marketStats.supplyRate
          );

          const nativeBorrowBalance = oracleNativeBalance(
            userTokenStats.borrowBalance,
            marketStats.underlyingPrice
          );

          const nativeBorrowRate = nativeBorrowBalance.mul(
            marketStats.borrowRate
          );

          return {
            totalSupplyBalanceInNative:
              totalSupplyBalanceInNative.add(nativeSupplyBalance),
            totalRateInNative: totalRateInNative
              .add(nativeSupplyRate)
              .sub(nativeBorrowRate),
          };
        },
        {
          totalSupplyBalanceInNative: BigNumber.from(0),
          totalRateInNative: BigNumber.from(0),
        }
      ),
    [allMarketStats, allUserTokenStats, markets]
  );

  const netAPY = useMemo(() => {
    const netRate = getNetRate(totalSupplyBalanceInNative, totalRateInNative);
    return rateToApy(netRate, protocol.blocksPerYear, 2, true);
  }, [protocol.blocksPerYear, totalRateInNative, totalSupplyBalanceInNative]);

  const netSupplyAPY = useMemo<string>(() => {
    const netRate = getNetRate(
      totalSupplyBalanceInNative,
      totalSupplyRateInNative
    );
    return rateToApy(netRate, protocol.blocksPerYear, 2, true);
  }, [
    protocol.blocksPerYear,
    totalSupplyBalanceInNative,
    totalSupplyRateInNative,
  ]);

  const totalCollateralInNative = useMemo<BigNumber>(
    () =>
      reduce(
        markets,
        (acc, market, index) => {
          const userTokenStats = allUserTokenStats[index];
          const marketStats = allMarketStats[index];
          if (
            !userTokenStats.collateralEnabled ||
            userTokenStats.crTokenBalance.isZero()
          ) {
            return acc;
          }

          const collateralUnderlyingBalance = underlyingBalance(
            userTokenStats.collateralBalance,
            marketStats.exchangeRate
          );
          const collateralNativeBalance = oracleNativeBalance(
            collateralUnderlyingBalance,
            marketStats.underlyingPrice
          );

          return acc.add(collateralNativeBalance);
        },
        BigNumber.from(0)
      ),
    [allMarketStats, allUserTokenStats, markets]
  );

  const totalSupplyBalanceInUSD = useMemo<BN>(() => {
    return new BN(
      ethers.utils.formatUnits(totalSupplyBalanceInNative, 18)
    ).multipliedBy(basePrice);
  }, [basePrice, totalSupplyBalanceInNative]);

  return {
    totalSupplyBalanceInUSD,
    totalSupplyBalanceInNative,
    netAPY,
    netSupplyAPY,
    basePrice,
    totalCollateralInNative,
  };
};

export default useUserSupplySummary;
