import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    px: {
      base: 4,
      lg: 0,
    },
    maxW: {
      base: '100%',
      lg: 'container.lg',
    },
  },
});
