import { Stack } from '@chakra-ui/react';
import React from 'react';

const Block = ({ children }: { children: React.ReactNode }): JSX.Element => {
  return (
    <Stack bgColor="background.light" p={6} spacing={6}>
      {children}
    </Stack>
  );
};

export default Block;
