import { useCallback, useState } from 'react';
import {
  Box,
  Text,
  Stack,
  Input,
  InputGroup,
  useStyleConfig,
  chakra,
} from '@chakra-ui/react';
import { addDays, addSeconds, getUnixTime } from 'date-fns';
import DatePicker from 'react-date-picker';
import useDateStringLocalizer from 'hooks/useDateStringLocalizer';
import QuickDurationPicker from 'components/QuickDurationPicker';
import { roundToThursday } from 'cream/utils';
import { fourYearsInSecond } from 'cream/constants';

interface StakeDurationPickerProps {
  stakeAmount?: string;
  oldLockDate?: Date;
  lockDate: Date;
  onChange: (date: Date) => void;
  isShowBalance?: boolean;
}

const ChakraDatePicker = chakra(DatePicker);

function StakeDurationPicker(props: StakeDurationPickerProps): JSX.Element {
  const { oldLockDate, lockDate, onChange } = props;
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const datePickerStyle = useStyleConfig('DatePicker');

  const lockTimeDisplay = useDateStringLocalizer({
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  const onDateSelect = useCallback(
    (date: Date) => onChange(roundToThursday(date)),
    [onChange]
  );

  return (
    <Box>
      <Stack spacing={2}>
        <Text variant="headline4" color="primary.100">
          Stake until
        </Text>
        <InputGroup onClick={() => setShowCalendar(!showCalendar)}>
          <Input
            value={lockTimeDisplay(getUnixTime(lockDate))}
            inputMode="text"
            color="primary.100"
            border="1px solid"
            borderRadius="0"
            borderColor="primary.100"
            bgGradient="linear-gradient(180deg, background.header 0%, #03170D 100%)"
            cursor="pointer"
            h="56px"
            isReadOnly={true}
          />
        </InputGroup>
        <ChakraDatePicker
          __css={datePickerStyle}
          value={lockDate}
          minDate={
            oldLockDate ? addDays(oldLockDate, 7) : addDays(new Date(), 7)
          }
          maxDate={addSeconds(new Date(), fourYearsInSecond)}
          isOpen={showCalendar}
          onCalendarClose={() => setShowCalendar(false)}
          onChange={(value: Date) => onDateSelect(value)}
          calendarType="US"
        />
        <QuickDurationPicker onChange={onDateSelect} />
      </Stack>
    </Box>
  );
}

export default StakeDurationPicker;
