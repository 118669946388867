import { useToast } from '@chakra-ui/react';

export enum AlertSeverity {
  Error = 'error',
  Warning = 'warning',
  Info = 'info',
  Success = 'success',
  Loading = 'loading',
}

interface AlertParams {
  title?: string;
  severity: AlertSeverity;
  message: string;
}

interface UseAlert {
  showAlert: (alert: AlertParams) => void;
}

export const useAlert = (): UseAlert => {
  const toast = useToast();
  const showAlert = ({ title = '', message, severity }: AlertParams) => {
    toast({
      position: 'top',
      title,
      description: message,
      status: severity,
      duration: 3000,
      variant: 'subtle',
    });
  };
  return { showAlert };
};

export default useAlert;
