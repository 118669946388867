import React from 'react';
import { Box, Text, SkeletonText } from '@chakra-ui/react';

interface UserMarketsProps {
  header: string;
  table: React.ReactNode;
  isLoading?: boolean;
}

const UserMarket: React.FC<UserMarketsProps> = ({
  header,
  table,
  isLoading = false,
}) => {
  return (
    <Box>
      <Text variant="moduleTitle" mb={6}>
        {header}
      </Text>
      <Box>
        {isLoading ? (
          <Box style={{ padding: '16px' }}>
            <SkeletonText spacing={4} noOfLines={4} />
          </Box>
        ) : (
          table
        )}
      </Box>
    </Box>
  );
};

export default UserMarket;
