import BN from 'bignumber.js';
import { commify } from '../../cream/utils';
import { RawMarketData } from 'hooks/useMarketSummary';

export function rateToAPY(ratePerBlock: string, blocksPerYear: number): BN {
  const bn = BN.clone({ POW_PRECISION: 6 });
  const rate = new bn(ratePerBlock);
  const apy = rate.plus(1).pow(blocksPerYear).minus(1);
  return apy;
}

export function supplyToUSDValue(
  supply: string,
  exchangeRate: string,
  underlyingPrice: string,
  basePrice: number
): BN {
  const value = new BN(supply)
    .multipliedBy(exchangeRate)
    .multipliedBy(underlyingPrice)
    .multipliedBy(basePrice);
  return value;
}

export function borrowToUSDValue(
  borrow: string,
  underlyingPrice: string,
  basePrice: number
): BN {
  const value = new BN(borrow)
    .multipliedBy(underlyingPrice)
    .multipliedBy(basePrice);
  return value;
}

export function reserveToUSDValue(
  reserve: string,
  underlyingPrice: string,
  basePrice: number
): BN {
  const value = new BN(reserve)
    .multipliedBy(underlyingPrice)
    .multipliedBy(basePrice);
  return value;
}

export function displayUSDValue(value: BN, digit: number): string {
  return commify(value.toFixed(digit));
}

export function displayPercentageValue(
  value: BN | number,
  digit: number
): string {
  return new BN(value).multipliedBy(100).toNumber().toFixed(digit) + '%';
}

const symbolAlt: { [key: string]: string } = {
  'ydai+yusdc+yusdt+ytusd': 'yCRV',
  'yydai+yusdc+yusdt+ytusd': 'yUSD',
};

// symbolAltName returns alternative name of given symbol that is more commonly used.
// if the symbol is already common, return as is.
function symbolAltName(symbol: string) {
  return symbolAlt[symbol.toLowerCase()] || symbol;
}

export function parseNameAndSymbol(market: RawMarketData) {
  let underlyingName = market.underlying_name;
  let underlyingSymbol = symbolAltName(market.underlying_symbol);
  if (market.symbol.includes('crUNI-V2')) {
    underlyingSymbol = market.symbol.split('cr')[1];
    underlyingName = market.name.split('Cream ')[1];
  } else if (market.symbol.includes('crSLP')) {
    underlyingSymbol = market.symbol.split('cr')[1];
    underlyingName = market.name.split('Cream ')[1];
  } else if (market.symbol.includes('crCAKE')) {
    underlyingSymbol = market.symbol.split('cr')[1];
    underlyingName = market.name.split('Cream ')[1];
  }
  if (underlyingName === 'FTT') {
    [underlyingSymbol, underlyingName] = [underlyingName, underlyingSymbol];
  }
  return [underlyingName, underlyingSymbol];
}
