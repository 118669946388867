import React from 'react';
import {
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  Button,
} from '@chakra-ui/react';
import { Market } from 'cream/Type';
import useApprove from 'hooks/useApprove';
import useModal from 'hooks/useModal';
import Block from 'components/Modals/components/Block';
interface ResetAllowanceModalProps {
  market: Market;
}

const ResetAllowanceModal: React.FC<ResetAllowanceModalProps> = ({
  market,
}) => {
  const { dismissModal } = useModal();
  const { resetApprove } = useApprove(market);

  return (
    <ModalContent>
      <ModalHeader>Reset Allowance</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Block>
          <Text>
            {`You need to reset allowance to 0 before approving ${market.underlyingSymbol}. This is a required step due to smart contract design.`}
          </Text>
        </Block>
        <Button
          onClick={() => {
            resetApprove();
            dismissModal();
          }}
        >
          Reset Allowance
        </Button>
      </ModalBody>
    </ModalContent>
  );
};

export default ResetAllowanceModal;
