import { useContext, useCallback } from 'react';
import { Switch } from '@chakra-ui/react';
import { TxContext } from 'providers/TxProvider';
import useCream from 'hooks/useCream';
import { useAlert, AlertSeverity } from 'hooks/useAlert';

interface CollateralSwitchProps {
  enabled: boolean; // switch is on or off
  marketAddress: string;
  disabled: boolean; // switch is disabled or not
}

export const CollateralSwitch: React.FC<CollateralSwitchProps> = ({
  enabled,
  marketAddress,
  disabled,
}) => {
  const cream = useCream();
  const { addTx } = useContext(TxContext);
  const { showAlert } = useAlert();

  const toggleEnableCollateral = useCallback(async () => {
    if (!cream || disabled) {
      return;
    }

    try {
      if (enabled) {
        const response = await cream.disableCollateral(marketAddress);
        addTx(response.hash, `Disable collateral`);
      } else {
        const response = await cream.enableCollateral(marketAddress);
        addTx(response.hash, `Enable collateral`);
      }
    } catch (error) {
      showAlert({
        message: 'Update Collateral Failed',
        severity: AlertSeverity.Error,
      });
      console.error(error);
    }
  }, [cream, disabled, enabled, marketAddress, addTx, showAlert]);

  return (
    <Switch
      isDisabled={disabled}
      isChecked={enabled}
      onChange={() => toggleEnableCollateral()}
    />
  );
};

export default CollateralSwitch;
