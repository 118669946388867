import {
  Button,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { Protocol } from 'cream/Protocols';
import { map, filter } from 'lodash';
import { ConnectionContext } from 'providers/ConnectionProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { useCallback, useContext, useMemo } from 'react';
import { FaChevronDown, FaExclamationCircle } from 'react-icons/fa';

const NetworkSwitch = (): JSX.Element => {
  const {
    protocol: currentProtocol,
    protocols,
    changeProtocol,
  } = useContext(ProtocolContext);
  const { changeNetwork, isSameNetwork, connected } =
    useContext(ConnectionContext);

  const selectedNetwork = useMemo<Protocol | null>(() => {
    if (!connected) {
      return currentProtocol;
    }

    return isSameNetwork ? currentProtocol : null;
  }, [connected, currentProtocol, isSameNetwork]);

  const handleNetworkSelect = useCallback(
    (protocol: Protocol) => {
      if (connected) {
        changeNetwork(protocol.networkId);
      } else {
        changeProtocol(protocol);
      }
    },
    [connected, changeNetwork, changeProtocol]
  );

  return (
    <Menu>
      <MenuButton
        as={Button}
        color={selectedNetwork ? 'primary.100' : 'red.500'}
        minW={`${selectedNetwork ? '10.625' : '15'}rem`}
        variant="ghost"
        iconSpacing={2}
        rightIcon={<Icon as={FaChevronDown} boxSize={4} />}
        leftIcon={
          selectedNetwork ? (
            <Image src={selectedNetwork.icon} boxSize="24px" mr={1} />
          ) : (
            <Icon
              as={FaExclamationCircle}
              boxSize="18px"
              color="red.500"
              mr={1}
            />
          )
        }
      >
        {selectedNetwork?.name || 'Unsupported Chain'}
      </MenuButton>
      <MenuList>
        {map(
          filter(
            protocols,
            ({ networkId }) => networkId !== currentProtocol.networkId
          ),
          (protocol) => (
            <MenuItem
              key={protocol.networkId}
              fontSize="sm"
              onClick={() => handleNetworkSelect(protocol)}
              icon={<Image src={protocol.icon} boxSize="24px" mr={1} />}
            >
              {protocol.name}
            </MenuItem>
          )
        )}
      </MenuList>
    </Menu>
  );
};

export default NetworkSwitch;
