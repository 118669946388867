import {
  Button,
  HStack,
  Image,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  SkeletonText,
  Stack,
  Text,
} from '@chakra-ui/react';
import BN from 'bignumber.js';
import TokenIconSymbol from 'components/TokenIconSymbol';
import { BigNumber } from 'ethers';
import { formatEther } from 'ethers/lib/utils';
import { useContext } from 'react';
import { sameAddress } from '../../../cream/utils';
import useStEthAPR from '../../../hooks/useStEthAPR';
import useTokenIcon from '../../../hooks/useTokenIcon';
import { ProtocolContext } from '../../../providers/ProtocolProvider';
import { rateToAPY } from '../../Markets/utils';

export function isWstEthAddress(address: string) {
  return sameAddress('0xbC6B6c837560D1fE317eBb54E105C89f303d5AFd', address);
}

interface StEthApyPopoverProps {
  supplyOrBorrowRate: BigNumber;
}

export const StEthApyPopover = ({
  supplyOrBorrowRate,
}: StEthApyPopoverProps) => {
  const stEthIcon = useTokenIcon('stEth');

  const { data = 0, isLoading } = useStEthAPR();

  const { protocol } = useContext(ProtocolContext);

  const stEthApr = new BN(data);
  const supplyOrBorrowApy = rateToAPY(
    formatEther(supplyOrBorrowRate),
    protocol.blocksPerYear
  );

  return (
    <Popover placement="top" variant="apy">
      <PopoverTrigger>
        <Button
          w="max-content"
          size="sm"
          rightIcon={<Image src={stEthIcon} alt={'ib'} boxSize={4} />}
          variant="link"
          iconSpacing={1}
        >
          <SkeletonText isLoaded={!isLoading} noOfLines={1}>
            <Text variant="body3">{stEthApr.shiftedBy(2).toFormat(2)}%</Text>
          </SkeletonText>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverHeader>
            <HStack justify="space-between">
              <Text variant="subtitle4">NET APY:</Text>
              <Text variant="subtitle4" color="primary.100">
                {supplyOrBorrowApy.plus(stEthApr).shiftedBy(2).toFormat(2)}%
              </Text>
            </HStack>
          </PopoverHeader>
          <PopoverArrow />
          <PopoverBody>
            <Stack spacing={2}>
              <HStack justify="space-between">
                <Text variant="body3">Supply APY:</Text>
                <Text variant="body3" color="primary.100">
                  {supplyOrBorrowApy.shiftedBy(2).toFormat(2)}%
                </Text>
              </HStack>
              <HStack justify="space-between">
                <TokenIconSymbol fontSize="xs" symbol="stETH" />
                <HStack>
                  <Text variant="body3" as="span">
                    APR{' '}
                  </Text>
                  <Text variant="body3" as="span" color="primary.100">
                    <SkeletonText as="span" isLoaded={!isLoading} noOfLines={1}>
                      {stEthApr.shiftedBy(2).toFormat(2)}%
                    </SkeletonText>
                  </Text>
                </HStack>
              </HStack>
              <Text variant="body3">
                Net APY is calculated based on the underlying stETH. It includes
                Lido Staked ETH APR earned by validators.
              </Text>
            </Stack>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export default StEthApyPopover;
