import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

// This function creates a set of function that helps us create multipart component styles.
const helpers = createMultiStyleConfigHelpers([
  'header',
  'overlay',
  'dialogContainer',
  'dialog',
  'closeButton',
  'body',
  'footer',
]);

const responsiveVariant = helpers.definePartsStyle({
  header: {
    fontWeight: 700,
    fontSize: '3xl',
    color: 'primary.100',
    textAlign: 'center',
    p: 0,
    mb: 6,
  },
  dialog: {
    backgroundColor: 'background.dark',
    p: 4,
    w: '30rem',
    '@media screen and (max-width: 30rem)': {
      my: 0,
      minH: '$100vh',
      borderRadius: 0,
    },
  },
  body: {
    p: 0,
  },
  closeButton: {
    top: 5,
    insetEnd: 4,
    color: 'primary.100',
    fontSize: 'md',
  },
});

const Modal = helpers.defineMultiStyleConfig({
  sizes: {
    default: {}, // to override chakra's sizes
  },
  variants: {
    responsive: responsiveVariant,
  },
  defaultProps: { size: 'default', variant: 'responsive' },
});

export default Modal;
