import { Category } from 'cream/Category';
import {
  displayBalance,
  distributionApy,
  getUnderlyingBalanceInUSD,
  rateToApy,
  sameAddress,
  totalBorrowsInUsd,
} from 'cream/utils';
import { BigNumber } from 'ethers';
import { reduce } from 'lodash';
import { MarketContext } from 'providers/MarketProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { useContext, useMemo } from 'react';

export type BorrowMarketData = {
  asset: string;
  address: string;
  apy: string;
  distributionApy: string;
  borrow: BigNumber;
  borrowRate: BigNumber;
  borrowCap: BigNumber;
  borrowAvailable: BigNumber;
  cash: BigNumber;
  liquidity: string;
  liquidityInUSD: string;
  category: Category;
  balance: string;
  underlyingPrice: BigNumber;
  underlyingDecimal: number;
};

export const useBorrowMarketData = (): BorrowMarketData[] => {
  const {
    markets,
    allMarketStats,
    allUserTokenStats,
    lmRewardsStats,
    basePrice,
  } = useContext(MarketContext);
  const { protocol } = useContext(ProtocolContext);

  const data = useMemo(
    () =>
      reduce(
        allMarketStats,
        (acc: BorrowMarketData[], marketStats, index) => {
          // Delist EURS
          // TODO: remove from markets after user withdraw and repay
          if (
            sameAddress(
              marketStats.address,
              '0xA8caeA564811af0e92b1E044f3eDd18Fa9a73E4F'
            )
          ) {
            return acc;
          }
          const userTokenStats = allUserTokenStats[index];
          const market = markets[index];
          const speeds = lmRewardsStats[index].rewardSpeeds;
          const totalBorrows = totalBorrowsInUsd(
            marketStats.borrow,
            marketStats.underlyingPrice,
            market.underlyingDecimal,
            basePrice
          );

          const borrowAvailable = (() => {
            const { cash, borrow, borrowCap } = marketStats;
            if (borrowCap.eq(0)) return cash;
            const borrowGap = borrowCap.sub(borrow);
            return cash.lt(borrowGap) ? cash : borrowGap;
          })();

          return acc.concat({
            address: market.address,
            apy: rateToApy(marketStats.borrowRate, protocol.blocksPerYear),
            distributionApy: distributionApy(speeds, totalBorrows, false),
            borrow: marketStats.borrow,
            borrowRate: marketStats.borrowRate,
            borrowCap: marketStats.borrowCap,
            borrowAvailable: borrowAvailable,
            cash: marketStats.cash,
            asset: market.underlyingSymbol,
            liquidity: displayBalance(
              marketStats.cash,
              market.underlyingDecimal,
              2
            ),
            liquidityInUSD: getUnderlyingBalanceInUSD(
              marketStats.cash,
              market.underlyingDecimal,
              marketStats.underlyingPrice
            ),
            category: market.category,
            balance: displayBalance(
              userTokenStats.borrowBalance,
              market.underlyingDecimal,
              2
            ),
            underlyingPrice: marketStats.underlyingPrice,
            underlyingDecimal: market.underlyingDecimal,
          });
        },
        []
      ),
    [
      allMarketStats,
      allUserTokenStats,
      markets,
      lmRewardsStats,
      basePrice,
      protocol,
    ]
  );

  return data;
};

export default useBorrowMarketData;
