import { Box, Button, Icon, Stack, Text } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import BN from 'bignumber.js';
import BalanceCell from 'components/BalanceCell';
import SupplyModal from 'components/Modals/SupplyModal';
import Table from 'components/Table';
import TokenIconSymbol from 'components/TokenIconSymbol';
import { Category } from 'cream/Category';
import { BigNumber, ethers } from 'ethers';
import useMediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import {
  SupplyMarketData,
  useSupplyMarketData,
} from 'hooks/useSupplyMarketData';
import { chain } from 'lodash';
import React, { useMemo, useState } from 'react';
import { FaRegCheckCircle } from 'react-icons/fa';
import ApyPopover from './ApyPopover';
import MarketCard from './MarketCard';
import StEthApyPopover, { isWstEthAddress } from './StEthApyPopover';

export const getUnderlyingBalanceInUSD = (
  underlyingBalance: BigNumber,
  underlyingDecimal: number,
  underlyingPrice: BigNumber
): string => {
  if (underlyingBalance.isZero() || underlyingPrice.isZero()) {
    return '0';
  }

  return new BN(ethers.utils.formatUnits(underlyingBalance, underlyingDecimal))
    .multipliedBy(
      ethers.utils.formatUnits(underlyingPrice, 36 - underlyingDecimal)
    )
    .toFormat(2);
};

const SupplyMarket: React.FC = () => {
  const { isMobile } = useMediaQuery();
  const data = useSupplyMarketData();
  const { presentModal } = useModal();
  const [isShowZero, setIsShowZero] = useState(true);

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<SupplyMarketData>();
    return [
      columnHelper.accessor('asset', {
        header: 'Asset',
        cell: (props) => <TokenIconSymbol symbol={props.getValue()} />,
      }),
      columnHelper.accessor('apy', {
        header: 'APY',
        cell: ({
          row: {
            original: { apy, stakingRewardData, supplyRate, address },
          },
        }) => {
          return (
            <Stack spacing={1} alignItems="end">
              <Text>{apy}</Text>
              {stakingRewardData && (
                <ApyPopover rewardData={stakingRewardData} />
              )}
              {isWstEthAddress(address) && (
                <StEthApyPopover supplyOrBorrowRate={supplyRate} />
              )}
            </Stack>
          );
        },
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('wallet', {
        id: 'wallet balance',
        header: () => (
          <Text>
            Wallet
            <br />
            Balance
          </Text>
        ),
        cell: ({
          row: {
            original: { wallet, walletBalanceInUSD },
          },
        }) => (
          <BalanceCell
            balance={wallet.toFormat(2)}
            usdValue={`$${walletBalanceInUSD}`}
          />
        ),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('isCollateral', {
        id: 'collateral',
        header: () => (
          <Text textAlign="center">
            Can be
            <br />
            Collateral
          </Text>
        ),
        cell: (props) => (
          <Box textAlign="center">
            {props.getValue() ? (
              <Icon as={FaRegCheckCircle} color="primary.100" boxSize="20px" />
            ) : (
              '--'
            )}
          </Box>
        ),
      }),
      columnHelper.display({
        id: 'supplyAction',
        header: '',
        cell: ({ row: { original } }) => (
          <Button
            size="xs"
            minW="5.25rem"
            onClick={() =>
              presentModal({
                children: (
                  <SupplyModal
                    marketAddress={original.address}
                    isNative={original.isNative}
                  />
                ),
              })
            }
          >
            Supply
          </Button>
        ),
        meta: { isHidden: isMobile },
      }),
    ];
  }, [isMobile, presentModal]);

  const formattedData = useMemo(
    () =>
      chain(data)
        .filter(({ wallet, category }) => {
          if (category !== Category.legacy && isShowZero) {
            return true;
          }
          return !wallet.isZero();
        })
        .filter((m) => {
          return m.asset !== 'sUSD';
        })
        .orderBy(
          ({ walletBalanceInUSD }) => new BN(walletBalanceInUSD).toNumber(),
          'desc'
        )
        .value(),
    [data, isShowZero]
  );

  return (
    <MarketCard
      title="Assets to Supply"
      isShowZero={isShowZero}
      onZeroChecked={(checked) => {
        setIsShowZero(checked);
      }}
    >
      <Table
        columns={columns}
        data={formattedData}
        templateColumns={
          isMobile ? 'auto 1fr 1fr auto' : 'auto 1fr 1fr auto auto'
        }
        enableHoverBgColor={isMobile}
        onRowClick={
          isMobile
            ? ({ original }) =>
                presentModal({
                  children: (
                    <SupplyModal
                      marketAddress={original.address}
                      isNative={original.isNative}
                    />
                  ),
                })
            : undefined
        }
      />
    </MarketCard>
  );
};

export default SupplyMarket;
