import { TransactionResponse } from '@ethersproject/abstract-provider';
import { JsonRpcProvider } from '@ethersproject/providers';
import axios from 'axios';
import BN from 'bignumber.js';
import IBStakingReward from 'cream/contract/IBStakingRewards';
import VeIB from 'cream/contract/VeIB';
import VeIBFeeDistributor from 'cream/contract/VeIBFeeDistributor';
import { BigNumber, Contract, ethers, Signer } from 'ethers';
import { Configuration } from './Configuration';
import {
  BnbUsdPriceEndpoint,
  CreamUsdPriceEndpoint,
  EthUsdPriceEndpoint,
  IbUSDPriceEndpoint,
  MaticUsdPriceEndpoint,
} from './constants';
import LpStakingAbi from './contract/ABIs/lpStaking';
import SpookyLpAbi from './contract/ABIs/spookyLP';
import { CErc20__factory, Erc20__factory } from './contract/ABIs/types';
import CErc20 from './contract/CErc20';
import CompoundLens from './contract/CompoundLens';
import Comptroller from './contract/Comptroller';
import CreamETH2 from './contract/CreamETH2';
import CreamETH2Oracle from './contract/CreamETH2Oracle';
import Erc20 from './contract/Erc20';
import LiquidityMining from './contract/LiquidityMining';
import LongTermPool from './contract/LongTermPool';
import Maximillion from './contract/Maximillion';
import MultiCall from './contract/MultiCall';
import PriceOracle from './contract/PriceOracle';
import SpookyLP from './contract/SpookyLP';
import VeIBClaimHelper from './contract/VeIBClaimHelper';
import { FantomProtocol } from './Protocols';
import {
  CreamETH2Stats,
  IBRewardClaimable,
  IBStakingInfo,
  IBUserStaked,
  LMRewardStats,
  LongTermPoolStats,
  Market,
  MarketStats,
  RewardSpeedInfo,
  UnclaimLMReward,
  UserLiquidityRewards,
  UserLongTermPoolStats,
  UserTokenStats,
  VeIBReward,
} from './Type';

export class Cream {
  config: Configuration;
  comptroller: Comptroller;
  lens: CompoundLens;
  multiCall: MultiCall;
  provider: JsonRpcProvider | Signer;

  constructor(config: Configuration, provider: JsonRpcProvider | Signer) {
    this.provider = provider;
    this.config = config;
    this.comptroller = new Comptroller(config.comptrollerAddress, provider);
    this.lens = new CompoundLens(config.lensAddress, provider);
    this.multiCall = new MultiCall(config.multiCallAddress, provider);
  }

  connect(signer: Signer) {
    this.provider = signer;
    this.comptroller.connect(signer);
  }

  async getBasePrice(): Promise<number> {
    return this.config.protocol.getBasePrice();
  }

  async getLMRewardTokenPrice(symbol: string): Promise<number> {
    if (symbol === 'IB') {
      return this.getIBPrice();
    }

    let endpoint;
    switch (symbol.toLowerCase()) {
      case 'bnb':
        endpoint = BnbUsdPriceEndpoint;
        break;
      case 'cream':
        endpoint = CreamUsdPriceEndpoint;
        break;
      case 'matic':
        endpoint = MaticUsdPriceEndpoint;
        break;
      default:
        return 0;
    }

    const { data } = await axios.get(endpoint);
    for (const symbol in data) {
      return Number(data[symbol].usd);
    }
    return 0;
  }

  async getIBPrice(): Promise<number> {
    const { data } = await axios.get<{ 'iron-bank': { usd: number } }>(
      IbUSDPriceEndpoint
    );
    return data['iron-bank'].usd;
  }

  async getUnderlyingSymbol(iToken: string) {
    const cErc20 = CErc20__factory.connect(iToken, this.provider);
    const underlying = await cErc20.underlying();
    const erc20 = Erc20__factory.connect(underlying, this.provider);
    return erc20.symbol();
  }

  /**
   * Lens functions
   */

  async getUserStats(
    address: string,
    markets: Market[]
  ): Promise<UserTokenStats[]> {
    const cTokenAddresses = markets.map((market) => market.address);

    const cTokenBalancesAll = await this.lens.cTokenBalancesAll(
      cTokenAddresses,
      address
    );

    const userTokenStats = cTokenBalancesAll.map((balance) => ({
      address: balance.cToken,
      walletBalance: balance.tokenBalance,
      crTokenBalance: balance.balanceOf,
      underlyingBalance: balance.balanceOfUnderlying,
      borrowBalance: balance.borrowBalanceCurrent,
      nativeTokenBalance: balance.nativeTokenBalance,
      collateralEnabled: balance.collateralEnabled,
      collateralBalance: balance.collateralBalance,
    }));
    return userTokenStats;
  }

  async getMarketStats(markets: Market[]): Promise<MarketStats[]> {
    const cTokenAddresses = markets.map((market) => market.address);

    const cTokenMetadataAll = await this.lens.cTokenMetadataAll(
      cTokenAddresses
    );

    const marketStats = cTokenMetadataAll.map<MarketStats>((metadata) => ({
      supply: metadata.totalSupply,
      borrow: metadata.totalBorrows,
      cash: metadata.totalCash,
      reserves: metadata.totalReserves,
      address: metadata.cToken,
      exchangeRate: metadata.exchangeRateCurrent,
      supplyRate: metadata.supplyRatePerBlock,
      borrowRate: metadata.borrowRatePerBlock,
      collateralFactor: metadata.collateralFactorMantissa,
      supplyPaused: metadata.supplyPaused,
      borrowPaused: metadata.borrowPaused,
      underlyingPrice: metadata.underlyingPrice,
      supplyCap: metadata.supplyCap,
      borrowCap: metadata.borrowCap,
      collateralCap: metadata.collateralCap,
      totalCollateralTokens: metadata.totalCollateralTokens,
      version: metadata.version,
    }));

    return marketStats;
  }

  async getUserLiquidityRewards(
    account: string,
    markets: Market[]
  ): Promise<UserLiquidityRewards[]> {
    return [];
  }

  /**
   * CErc20 functions
   */

  async supply(
    market: Market,
    amount: BigNumber,
    isNative: boolean
  ): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);

    if (isNative) {
      return cTokenInterface.mintNative(amount);
    } else {
      return cTokenInterface.mint(amount);
    }
  }

  async redeem(
    market: Market,
    amount: BigNumber,
    isNative: boolean
  ): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);

    return isNative
      ? cTokenInterface.redeemNative(amount)
      : cTokenInterface.redeem(amount);
  }

  async redeemUnderlying(
    market: Market,
    underlyingTokenAmount: BigNumber,
    isNative: boolean
  ): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);

    return isNative
      ? cTokenInterface.redeemUnderlyingNative(underlyingTokenAmount)
      : cTokenInterface.redeemUnderlying(underlyingTokenAmount);
  }

  async borrow(
    market: Market,
    amount: BigNumber,
    isNative: boolean
  ): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);

    return isNative
      ? cTokenInterface.borrowNative(amount)
      : cTokenInterface.borrow(amount);
  }

  async repayNativeFull(
    borrower: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    // Maximillion could handle repay full amount of native token.
    const maximillion = new Maximillion(
      this.config.protocol.maximillionAddress,
      this.provider
    );
    return maximillion.repayBehalf(borrower, amount);
  }

  async repay(
    market: Market,
    amount: BigNumber,
    isNative: boolean
  ): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);

    return isNative
      ? cTokenInterface.repayBorrowNative(amount)
      : cTokenInterface.repayBorrow(amount);
  }

  async claimLiquidityRewards(
    market: Market,
    account: string
  ): Promise<TransactionResponse> {
    const cTokenInterface = new CErc20(market.address, this.provider);
    throw new Error('Invalid network');
  }

  /**
   * Comptroller functions
   */

  async enableCollateral(marketAddress: string): Promise<TransactionResponse> {
    return this.comptroller.enterMarkets([marketAddress]);
  }

  async disableCollateral(marketAddress: string): Promise<TransactionResponse> {
    return this.comptroller.exitMarket(marketAddress);
  }

  /**
   * Long-term pools functions
   */

  async getLongTermPoolStats(): Promise<LongTermPoolStats[]> {
    const pools = this.config.protocol.longTermPools;
    return Promise.all(
      pools.map((pool) => this.getSinglePoolStats(pool.address, pool.years))
    );
  }

  async getUserLongTermPoolStat(
    account: string,
    poolAddress: string
  ): Promise<UserLongTermPoolStats> {
    return this.getUserSingleLongTermPoolStats(poolAddress, account);
  }

  async getUserLongTermPoolStats(
    account: string
  ): Promise<UserLongTermPoolStats[]> {
    const pools = this.config.protocol.longTermPools;
    return Promise.all(
      pools.map((pool) =>
        this.getUserSingleLongTermPoolStats(pool.address, account)
      )
    );
  }

  async stakeToLongTermPool(
    poolAddress: string,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const pool = new LongTermPool(poolAddress, this.provider);
    return pool.stake(amount);
  }

  async exitFromLongTermPool(
    poolAddress: string
  ): Promise<TransactionResponse> {
    const pool = new LongTermPool(poolAddress, this.provider);
    return pool.exit();
  }

  /**
   * CreamETH2 functions
   */

  async getCreamETH2Stats(address: string): Promise<CreamETH2Stats> {
    const crETH2 = new CreamETH2(address, this.provider);
    const oracleAddress = await crETH2.oracle();
    const oracle = new CreamETH2Oracle(oracleAddress, this.provider);
    const [totalSupply, accumulated, exchangeRate] = await Promise.all([
      crETH2.totalSupply(),
      crETH2.accumulated(),
      oracle.exchangeRate(),
    ]);
    return {
      totalSupply,
      accumulated,
      exchangeRate,
    };
  }

  async getUserCreamETH2Balance(
    address: string,
    account: string
  ): Promise<BigNumber> {
    const crETH2 = new CreamETH2(address, this.provider);
    return await crETH2.balanceOf(account);
  }

  /**
   * LiquidityMining functions
   */

  async getLMRewardsStats(markets: Market[]): Promise<LMRewardStats[]> {
    const cTokenAddresses = markets.map((market) => market.address);
    const liquidityMiningAddress = this.config.protocol.liquidityMiningAddress;
    if (liquidityMiningAddress.length === 0) {
      return markets.map<LMRewardStats>((market) => ({
        cToken: market.address,
        rewardSpeeds: [],
      }));
    }
    const liquidityMining = new LiquidityMining(
      liquidityMiningAddress,
      this.provider
    );
    const allMarketRewardsSpeeds =
      await liquidityMining.getMultipleMarketRewardSpeeds(cTokenAddresses);
    if (allMarketRewardsSpeeds.length === 0) {
      return markets.map<LMRewardStats>((market) => ({
        cToken: market.address,
        rewardSpeeds: [],
      }));
    }
    const rewardTokens = allMarketRewardsSpeeds[0].rewardSpeeds.map(
      (speed) => speed.rewardToken
    );
    const prices = await Promise.all(
      rewardTokens.map((token) =>
        this.getLMRewardTokenPrice(token.rewardTokenSymbol)
      )
    );

    const lmRewardStats = new Array<LMRewardStats>(markets.length);
    for (let i = 0; i < markets.length; i++) {
      const rewardSpeeds = new Array<RewardSpeedInfo>(rewardTokens.length);
      for (let j = 0; j < rewardTokens.length; j++) {
        rewardSpeeds[j] = {
          rewardToken: {
            rewardTokenAddress: rewardTokens[j].rewardTokenAddress,
            rewardTokenSymbol: rewardTokens[j].rewardTokenSymbol,
            rewardTokenDecimals: rewardTokens[j].rewardTokenDecimals,
            rewardTokenUSDPrice: prices[j],
          },
          supplySpeed: allMarketRewardsSpeeds[i].rewardSpeeds[j].supplySpeed,
          borrowSpeed: allMarketRewardsSpeeds[i].rewardSpeeds[j].borrowSpeed,
        };
      }
      lmRewardStats[i] = {
        rewardSpeeds,
      };
    }
    return lmRewardStats;
  }

  async getUserUnclaimLMRewards(account: string): Promise<UnclaimLMReward[]> {
    const liquidityMiningAddress = this.config.protocol.liquidityMiningAddress;
    if (liquidityMiningAddress.length === 0) {
      return [];
    }
    const liquidityMining = new LiquidityMining(
      liquidityMiningAddress,
      this.provider
    );
    const availableRewards = await liquidityMining.getRewardsAvailable(account);
    const prices = await Promise.all(
      availableRewards.map((reward) =>
        this.getLMRewardTokenPrice(reward.rewardToken.rewardTokenSymbol)
      )
    );

    const unclaimRewards = availableRewards.map<UnclaimLMReward>(
      (reward, index) => ({
        rewardToken: {
          rewardTokenAddress: reward.rewardToken.rewardTokenAddress,
          rewardTokenSymbol: reward.rewardToken.rewardTokenSymbol,
          rewardTokenDecimals: reward.rewardToken.rewardTokenDecimals,
          rewardTokenUSDPrice: prices[index],
        },
        amount: reward.amount,
      })
    );

    return unclaimRewards;
  }

  async getUserRewardMarkets(account: string): Promise<string[]> {
    const liquidityMiningAddress = this.config.protocol.liquidityMiningAddress;
    if (liquidityMiningAddress.length === 0) {
      return [];
    }

    const liquidityMiningRewardAddress =
      this.config.protocol.liquidityMiningRewardAddress;
    if (liquidityMiningRewardAddress.length === 0) {
      return [];
    }

    const liquidityMining = new LiquidityMining(
      liquidityMiningAddress,
      this.provider
    );
    const relatedMarkets = await liquidityMining.getUserRelatedMarkets(
      account,
      liquidityMiningRewardAddress
    );
    const userMarkets = [];
    for (const market of relatedMarkets) {
      if (market.supply || market.borrow) {
        userMarkets.push(market.market);
      }
    }
    return userMarkets;
  }

  async claimLMRewards(
    address: string,
    cTokens: string[]
  ): Promise<TransactionResponse> {
    const liquidityMiningAddress = this.config.protocol.liquidityMiningAddress;
    if (liquidityMiningAddress.length === 0) {
      throw new Error('Empty address');
    }

    const liquidityMiningRewardAddress =
      this.config.protocol.liquidityMiningRewardAddress;
    if (liquidityMiningRewardAddress.length === 0) {
      throw new Error('Empty address');
    }

    const liquidityMining = new LiquidityMining(
      liquidityMiningAddress,
      this.provider
    );
    return liquidityMining.claimRewards(
      address,
      cTokens,
      [liquidityMiningRewardAddress],
      true,
      true
    );
  }

  /**
   * IB Staking Reward functions
   */

  async IBRewardStakingInfo(): Promise<IBStakingInfo[]> {
    if (!this.config.protocol.stakingRewardHelperAddress) {
      return [];
    }
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    return ibReward.getStakingInfo();
  }

  async IBRewardUserStaked(account: string): Promise<IBUserStaked[]> {
    if (!this.config.protocol.stakingRewardHelperAddress) {
      return [];
    }
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    return ibReward.getUserStaked(account);
  }

  async IBRewardStake(
    underlyingAddress: string,
    amount: BigNumber,
    isNative = false
  ): Promise<TransactionResponse> {
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    return isNative
      ? ibReward.stakeNative(amount)
      : ibReward.stake(underlyingAddress, amount);
  }

  async IBRewardUnstake(
    stakingTokenAddress: string,
    isNative = false
  ): Promise<TransactionResponse> {
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    const stakingRewards = await ibReward.getStakingRewards(
      stakingTokenAddress
    );

    return ibReward.exit([stakingRewards], isNative);
  }

  async IBRewardPeriodFinish(
    stakingTokenAddress: string,
    rewardTokenAddress: string
  ): Promise<BigNumber | null> {
    if (!this.config.protocol.stakingRewardHelperAddress) {
      return null;
    }

    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    const stakingRewardsAddress = await ibReward.getStakingRewards(
      stakingTokenAddress
    );

    return ibReward.getPeriodFinish(stakingRewardsAddress, rewardTokenAddress);
  }

  async IBRewardEarned(
    account: string,
    stakingTokenAddress: string,
    rewardTokenAddress: string
  ): Promise<BigNumber> {
    if (!this.config.protocol.stakingRewardHelperAddress) {
      return BigNumber.from(0);
    }
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    const stakingRewardsAddress = await ibReward.getStakingRewards(
      stakingTokenAddress
    );
    return ibReward.getEarned(
      account,
      stakingRewardsAddress,
      rewardTokenAddress
    );
  }

  async claimAllRewards(): Promise<TransactionResponse> {
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    return ibReward.claimAllRewards();
  }

  async getClaimableIBRewards(
    walletAddress: string,
    tokenAddresses: string[]
  ): Promise<IBRewardClaimable[]> {
    if (!this.config.protocol.stakingRewardHelperAddress) {
      return [];
    }
    const ibReward = new IBStakingReward(this.config.protocol, this.provider);
    return ibReward.getUserClaimableRewards(walletAddress, tokenAddresses);
  }

  /**
   * veIB functions
   */

  async veIBStake(
    amount: BigNumber,
    lockDuration: number
  ): Promise<TransactionResponse> {
    const veIB = new VeIB(this.config.protocol, this.provider);
    return veIB.stake(amount, BigNumber.from(lockDuration));
  }

  async veIBUnstake(tokenId: BigNumber): Promise<TransactionResponse> {
    const veIB = new VeIB(this.config.protocol, this.provider);
    return veIB.withdraw(tokenId);
  }

  async veIBIncreaseUnlockTime(
    tokenId: BigNumber,
    duration: number
  ): Promise<TransactionResponse> {
    const veIB = new VeIB(this.config.protocol, this.provider);
    return veIB.increaseUnlockTime(tokenId, BigNumber.from(duration));
  }

  async veIBIncreaseAmount(
    tokenId: BigNumber,
    amount: BigNumber
  ): Promise<TransactionResponse> {
    const veIB = new VeIB(this.config.protocol, this.provider);
    return veIB.increaseAmount(tokenId, amount);
  }

  async veIBClaim(
    distributors: string[],
    tokenIds: number[]
  ): Promise<TransactionResponse> {
    const { veIBClaimHelper } = this.config.protocol;
    const contract = new VeIBClaimHelper(veIBClaimHelper, this.provider);
    return contract.claim(distributors, tokenIds);
  }

  async veIBClaimable(address: string): Promise<VeIBReward[]> {
    if (this.config.protocol === FantomProtocol) {
      return [];
    }

    const feeDistributors = [
      {
        // TODO: check before deploy
        address: '0x0Ca0f068edad122f09a39f99E7E89E705d6f6Ace',
        decimals: 18,
        // TODO: check before deploy
        symbol: 'yvCurve-IronBank',
      },
    ];
    return await Promise.all(
      feeDistributors.map(async (fd) => {
        const contract = new VeIBFeeDistributor(fd.address, this.provider);

        const usdPrice = await this.getVeIBClaimablePrice(fd.symbol);
        const claimable = await contract.claimable(address);
        const usdValue = new BN(
          ethers.utils.formatUnits(claimable, fd.decimals)
        )
          .multipliedBy(usdPrice)
          .toNumber();

        return {
          usdValue,
          address: fd.address,
          symbol: fd.symbol,
          decimals: fd.decimals,
          claimable: claimable,
        };
      })
    );
  }

  private async getVeIBClaimablePrice(symbol: string): Promise<number> {
    if (this.config.protocol === FantomProtocol) {
      return 0;
    }

    // TODO: check before deploy
    const priceOracleAddress = '0x647A539282e8456A64DFE28923B7999b66091488';
    switch (symbol) {
      // TODO: check before deploy
      case 'yvCurve-IronBank': {
        const oracle = new PriceOracle(priceOracleAddress, this.provider);
        const price = await oracle.getUnderlyingPrice(
          // TODO: check before deploy
          '0x45406ba53bB84Cd32A58e7098a2D4D1b11B107F6'
        );
        const ethPriceData = await axios.get(EthUsdPriceEndpoint);
        const ethPrice = ethPriceData.data.ethereum.usd;
        return new BN(price.toString())
          .div(1e18)
          .multipliedBy(ethPrice)
          .toNumber();
      }
      default:
        return 0;
    }
  }

  /**
   * LP Staking
   */

  async getLPBalance(address: string): Promise<BigNumber> {
    const lpContract = new SpookyLP(
      '0xcb10ef295021a7af61ac78375026a8e5af588db9',
      this.provider
    );
    return lpContract.balanceOf(address);
  }

  async getLPStakingReward(address: string): Promise<BigNumber> {
    const contract = new Contract(
      '0xF4Db0c5a0862fc8322C4D9B2085adEf7A111fdA5',
      LpStakingAbi,
      this.provider
    );
    return contract.earned(address);
  }

  async getLPStaked(address: string): Promise<BigNumber> {
    const contract = new Contract(
      '0xF4Db0c5a0862fc8322C4D9B2085adEf7A111fdA5',
      LpStakingAbi,
      this.provider
    );
    return contract.balanceOf(address);
  }

  async getLPStakeApy(): Promise<number> {
    try {
      const contract = new Contract(
        '0xF4Db0c5a0862fc8322C4D9B2085adEf7A111fdA5',
        LpStakingAbi,
        this.provider
      );
      const lpContract = new Contract(
        '0xcb10ef295021a7af61ac78375026a8e5af588db9',
        SpookyLpAbi,
        this.provider
      );
      const ibToken = new Erc20(this.config.protocol.ibAddress, this.provider);

      const rewardRate = await contract.rewardRate();
      const totalStaked = await contract.totalSupply();
      const periodFinish = await contract.periodFinish();

      if (Date.now() / 1000 > Number(periodFinish.toString())) {
        return 0;
      }

      const ibInLP = await ibToken.balanceOf(lpContract.address);
      const lpTotalSupply = await lpContract.totalSupply();

      const lpPrice =
        (Number(ibInLP.toString()) * 2) / Number(lpTotalSupply.toString());

      const poolYearReward = Number(rewardRate.mul(3600 * 24 * 365).toString());
      return poolYearReward / lpPrice / Number(totalStaked.toString());
    } catch (error) {
      console.error('getLPStakeApy', error);
      return 0;
    }
  }

  async stakeLP(amount: BigNumber): Promise<TransactionResponse> {
    const contract = new Contract(
      '0xF4Db0c5a0862fc8322C4D9B2085adEf7A111fdA5',
      LpStakingAbi,
      this.provider
    );
    return contract.stake(amount);
  }

  async claimLPStakingReward(): Promise<TransactionResponse> {
    const contract = new Contract(
      '0xF4Db0c5a0862fc8322C4D9B2085adEf7A111fdA5',
      LpStakingAbi,
      this.provider
    );
    return contract.getReward();
  }

  async exitAndClaimLPStaking(): Promise<TransactionResponse> {
    const contract = new Contract(
      '0xF4Db0c5a0862fc8322C4D9B2085adEf7A111fdA5',
      LpStakingAbi,
      this.provider
    );
    return contract.exit();
  }

  /**
   * Private functions
   */

  private async getSinglePoolStats(
    poolAddress: string,
    duration: number
  ): Promise<LongTermPoolStats> {
    const pool = new LongTermPool(poolAddress, this.provider);
    const [lpTokenAddress, endDate, rewardRate, totalSupplyInPool] =
      await Promise.all([
        pool.lpToken(),
        pool.releaseTime(),
        pool.rewardRate(),
        pool.totalSupply(),
      ]);
    return {
      poolAddress,
      lpTokenAddress,
      endDate,
      rewardRate,
      totalSupplyInPool,
      lockPeriod: duration,
    };
  }

  private async getUserSingleLongTermPoolStats(
    poolAddress: string,
    account: string
  ): Promise<UserLongTermPoolStats> {
    const pool = new LongTermPool(poolAddress, this.provider);
    const lpTokenAddress = await pool.lpToken();
    const lpToken = new Erc20(lpTokenAddress, this.provider);
    const [allowance, walletBalance, lpDecimals, stakedBalance, earnedBalance] =
      await Promise.all([
        lpToken.allowance(account, poolAddress),
        lpToken.balanceOf(account),
        lpToken.decimals(),
        pool.balanceOf(account),
        pool.earned(account),
      ]);
    return {
      poolAddress,
      allowance,
      walletBalance,
      lpDecimals,
      stakedBalance,
      earnedBalance,
    };
  }
}

export default Cream;
