import { Box, Flex, Text } from '@chakra-ui/react';

interface InfoProps {
  title: React.ReactNode;
  value: React.ReactNode;
}

const Info = ({ title, value }: InfoProps): JSX.Element => {
  return (
    <Flex justifyContent="space-between">
      <Box>{title}</Box>
      <Text as="div" variant="headline4" color="primary.100">
        {value}
      </Text>
    </Flex>
  );
};

export default Info;
