import { Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import { TransactionResponse } from '@ethersproject/abstract-provider';
import { GAS_MULTIPLIER } from './constants';
import LiquidityMiningABI from './ABIs/liquidityMining';
import { UserMarket, RewardAvailable, MarketRewardSpeed } from './types';

class LiquidityMining {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, LiquidityMiningABI, provider);
    this.address = address;
  }

  async claimAllRewards(account: string): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimAllRewards(account);
    return this.contract.claimAllRewards(account, {
      gasLimit: gas.mul(GAS_MULTIPLIER),
    });
  }

  async claimRewards(
    account: string,
    cTokens: string[],
    rewards: string[],
    borrowers: boolean,
    suppliers: boolean
  ): Promise<TransactionResponse> {
    const gas = await this.contract.estimateGas.claimRewards(
      account,
      cTokens,
      rewards,
      borrowers,
      suppliers
    );
    return this.contract.claimRewards(
      account,
      cTokens,
      rewards,
      borrowers,
      suppliers,
      { gasLimit: gas.mul(GAS_MULTIPLIER) }
    );
  }

  getUserRelatedMarkets(
    account: string,
    reward: string
  ): Promise<UserMarket[]> {
    return this.contract.callStatic.claimSingleReward(account, reward);
  }

  getRewardsAvailable(address: string): Promise<RewardAvailable[]> {
    return this.contract.callStatic.getRewardsAvailable(address);
  }

  getMultipleMarketRewardSpeeds(
    cTokens: string[]
  ): Promise<MarketRewardSpeed[]> {
    return this.contract.callStatic.getMultipleMarketRewardSpeeds(cTokens);
  }
}

export default LiquidityMining;
