import { HStack, Image, Text } from '@chakra-ui/react';
import React from 'react';
import useTokenIcon from 'hooks/useTokenIcon';

interface TokenIconSymbolProps {
  symbol: string;
  iconOnly?: boolean;
  fontSize?: string;
}

const TokenIconSymbol: React.FC<TokenIconSymbolProps> = ({
  symbol,
  iconOnly = false,
  fontSize = 'md',
}) => {
  const icon = useTokenIcon(symbol);

  return (
    <HStack alignItems="center" spacing={2}>
      {icon ? <Image src={icon} alt={symbol} boxSize={5} /> : <Text>😐</Text>}
      {!iconOnly && (
        <Text fontSize={fontSize} variant="subtitle3" whiteSpace="nowrap">
          {symbol}
        </Text>
      )}
    </HStack>
  );
};

export default TokenIconSymbol;
