import { BigNumber } from 'ethers';
import { useQueries } from '@tanstack/react-query';
import React, { createContext, useContext, useMemo } from 'react';
import {
  LMRewardStats,
  Market,
  MarketStats,
  UserLiquidityRewards,
  UserTokenStats,
} from '../cream/Type';
import { ConnectionContext } from './ConnectionProvider';
import { CreamContext } from './CreamProvider';
import { ProtocolContext } from './ProtocolProvider';

interface Context {
  markets: Market[];
  allMarketStats: MarketStats[];
  allUserTokenStats: UserTokenStats[];
  userLiquidityRewards: UserLiquidityRewards[];
  lmRewardsStats: LMRewardStats[];
  basePrice: number;
  isLoading: boolean;
}

const DefaultContext: Context = {
  markets: [],
  allMarketStats: [],
  allUserTokenStats: [],
  userLiquidityRewards: [],
  lmRewardsStats: [],
  basePrice: 0,
  isLoading: false,
};

export const MarketContext = createContext<Context>(DefaultContext);

const initMarketStats = (markets: Market[]) => {
  return markets.map<MarketStats>(() => ({
    address: '',
    supply: BigNumber.from(0),
    borrow: BigNumber.from(0),
    cash: BigNumber.from(0),
    reserves: BigNumber.from(0),
    borrowRate: BigNumber.from(0),
    exchangeRate: BigNumber.from(0),
    supplyRate: BigNumber.from(0),
    borrowPaused: false,
    collateralFactor: BigNumber.from(0),
    supplyPaused: false,
    underlyingPrice: BigNumber.from(0),
    supplyCap: BigNumber.from(0),
    borrowCap: BigNumber.from(0),
    collateralCap: BigNumber.from(0),
    totalCollateralTokens: BigNumber.from(0),
    version: 0,
  }));
};

const initLMRewardStats = (markets: Market[]) => {
  return markets.map<LMRewardStats>(() => ({
    rewardSpeeds: [],
  }));
};

const initUserStats = (markets: Market[]) => {
  return markets.map<UserTokenStats>(() => ({
    address: '',
    collateralEnabled: false,
    crTokenBalance: BigNumber.from(0),
    underlyingBalance: BigNumber.from(0),
    walletBalance: BigNumber.from(0),
    borrowBalance: BigNumber.from(0),
    collateralBalance: BigNumber.from(0),
    nativeTokenBalance: BigNumber.from(0),
  }));
};

const initUserLiquidityRewards = (markets: Market[]) => {
  return markets.map<UserLiquidityRewards>(() => ({
    reward: BigNumber.from(0),
    address: '',
  }));
};

const MarketProvider = ({ children }: { children: React.ReactNode }) => {
  const { walletAddress, connected } = useContext(ConnectionContext);
  const { protocol } = useContext(ProtocolContext);
  const { cream } = useContext(CreamContext);

  const markets = protocol.markets;
  const lpMarkets = protocol.lpMarkets;

  const initContext: Context = useMemo(
    () => ({
      markets,
      allMarketStats: initMarketStats(markets),
      allUserTokenStats: initUserStats(markets),
      userLiquidityRewards: initUserLiquidityRewards(lpMarkets),
      lmRewardsStats: initLMRewardStats(markets),
      basePrice: 0,
      isLoading: true,
    }),
    [lpMarkets, markets]
  );

  const [
    { data: allMarketStats, isLoading: isLoadingMarketStats },
    { data: lmRewardsStats, isLoading: isLoadingLmRewardStats },
    { data: allUserTokenStats, isLoading: isLoadingUserTokenStats },
    { data: userLiquidityRewards, isLoading: isLoadingUserLiquidityRewards },
    { data: basePrice, isLoading: isLoadingBasePrice },
  ] = useQueries({
    queries: [
      {
        queryKey: ['market-stats', protocol.networkId],
        queryFn: async (): Promise<MarketStats[]> => {
          if (!cream) {
            return initMarketStats(markets);
          }
          return await cream.getMarketStats(markets);
        },
        refetchInterval: protocol.refreshRate * 1000,
        initialData: initMarketStats(markets),
        enabled: !!cream,
      },
      {
        queryKey: ['lm-reward-stats', protocol.networkId],
        queryFn: async (): Promise<LMRewardStats[]> => {
          if (!cream) {
            return initLMRewardStats(markets);
          }

          return await cream.getLMRewardsStats(markets);
        },
        refetchInterval: protocol.refreshRate * 1000,
        initialData: initLMRewardStats(markets),
        enabled: !!cream,
      },
      {
        queryKey: ['user-token-stats', walletAddress, protocol.networkId],
        queryFn: async (): Promise<UserTokenStats[]> => {
          if (!walletAddress || !markets || !cream) {
            return initUserStats(markets);
          }

          return await cream.getUserStats(walletAddress, markets);
        },
        refetchInterval: protocol.refreshRate * 1000,
        initialData: initUserStats(markets),
        enabled: !!cream && connected,
      },
      {
        queryKey: ['user-liquidity-rewards', walletAddress, protocol.networkId],
        queryFn: async (): Promise<UserLiquidityRewards[]> => {
          if (!cream || !walletAddress) {
            return initUserLiquidityRewards(lpMarkets);
          }

          return await cream.getUserLiquidityRewards(walletAddress, lpMarkets);
        },
        refetchInterval: protocol.refreshRate * 1000,
        initialData: initUserLiquidityRewards(lpMarkets),
        enabled: !!cream && connected,
      },
      {
        queryKey: ['base-price', protocol.networkId],
        queryFn: async (): Promise<number> => {
          if (!cream) {
            return 0;
          }
          return await cream.getBasePrice();
        },
        refetchInterval: protocol.refreshRate * 1000,
        initialData: 0,
        enabled: !!cream,
      },
    ],
  });

  const marketContext = useMemo<Context>(() => {
    if (!cream) {
      return initContext;
    }

    return {
      markets,
      isLoading:
        isLoadingMarketStats ||
        isLoadingUserTokenStats ||
        isLoadingLmRewardStats ||
        isLoadingUserLiquidityRewards ||
        isLoadingBasePrice,
      allMarketStats: allMarketStats || initMarketStats(markets),
      lmRewardsStats: lmRewardsStats || initLMRewardStats(markets),
      allUserTokenStats: allUserTokenStats || initUserStats(markets),
      userLiquidityRewards:
        userLiquidityRewards || initUserLiquidityRewards(lpMarkets),
      basePrice: basePrice || 0,
    };
  }, [
    cream,
    isLoadingMarketStats,
    isLoadingUserTokenStats,
    isLoadingLmRewardStats,
    isLoadingBasePrice,
    isLoadingUserLiquidityRewards,
    allMarketStats,
    markets,
    lmRewardsStats,
    allUserTokenStats,
    userLiquidityRewards,
    lpMarkets,
    basePrice,
    initContext,
  ]);

  return (
    <MarketContext.Provider value={marketContext}>
      {children}
    </MarketContext.Provider>
  );
};

export default MarketProvider;
