import { Tooltip, Box, Icon } from '@chakra-ui/react';
import { BsExclamationCircle } from 'react-icons/bs';

export const CollateralToolTip = () => {
  return (
    <Tooltip
      hasArrow
      placement="top"
      label="To decide  whether to use a supplied asset as collateral. An asset used as collateral will affect your borrow limit."
    >
      <Box as="span">
        <Icon as={BsExclamationCircle} boxSize="13px" ml={1} />
      </Box>
    </Tooltip>
  );
};

export default CollateralToolTip;
