import { useMediaQuery } from 'hooks/useMediaQuery';
import DesktopNavBar from 'components/Navigation/DesktopNavbar';
import MobileNavbar from 'components/Navigation/MobileNavbar';

const TopBar = () => {
  const { isMobile } = useMediaQuery();
  return isMobile ? <MobileNavbar /> : <DesktopNavBar />;
};

export default TopBar;
