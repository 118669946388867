import { BytesLike, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import MultiCallABI from './ABIs/multiCall';
import { Call } from './types';

class MultiCall {
  private contract: Contract;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, MultiCallABI, provider);
  }

  async executeCalls(calls: Call[]): Promise<Array<any>> {
    const abiInterfaces = calls.map((call) => Contract.getInterface(call.abi));
    const args = calls.map((call, index) => [
      call.address.toLowerCase(),
      abiInterfaces[index].encodeFunctionData(call.fn, call.params),
    ]);

    let result: Array<any>;

    try {
      const [, ret] = await this.contract.callStatic.aggregate(args, {});
      result = ret.map((data: BytesLike, i: number) =>
        abiInterfaces[i].decodeFunctionResult(calls[i].fn, data)
      );
    } catch (e) {
      return Promise.reject(e);
    }

    return result; // TODO: might need to convert the result array into key-value object
  }
}

export default MultiCall;
