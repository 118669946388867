import { Box, Button, Flex, SkeletonText, Text } from '@chakra-ui/react';
import { createColumnHelper, Row } from '@tanstack/react-table';
import VeIBManageModal from 'components/Modals/VeIBManageModal';
import VeIBStakeModal from 'components/Modals/VeIBStakeModal';
import Table from 'components/Table';
import { FantomProtocol } from 'cream/Protocols';
import { displayBalance } from 'cream/utils';
import { format, isPast } from 'date-fns';
import { BigNumber } from 'ethers';
import useAlert, { AlertSeverity } from 'hooks/useAlert';
import useCream from 'hooks/useCream';
import MediaQuery from 'hooks/useMediaQuery';
import useModal from 'hooks/useModal';
import { useVeIB, VeIB as VeIBType } from 'hooks/useVeIB';
import { find } from 'lodash';
import { ProtocolContext } from 'providers/ProtocolProvider';
import { TxContext } from 'providers/TxProvider';
import { useCallback, useContext, useMemo } from 'react';
import VeIBCard from './VeIBCard';

const UserVeIB = (): JSX.Element => {
  const { addTx } = useContext(TxContext);
  const { protocol } = useContext(ProtocolContext);
  const { presentModal } = useModal();
  const { showAlert } = useAlert();
  const {
    isLoadingUserVeIBList: isLoading,
    userClaimables,
    userIBBalance,
    userVeIBList,
    ibPrice,
  } = useVeIB();
  const { isMobile } = MediaQuery();
  const cream = useCream();

  const handleUnstake = useCallback(
    async (tokenId: BigNumber) => {
      try {
        const response = await cream?.veIBUnstake(tokenId);
        if (response) {
          addTx(response.hash, 'Unstake IB from veIB');
        }
      } catch (error) {
        showAlert({ severity: AlertSeverity.Error, message: 'Unstake Failed' });
      }
    },
    [addTx, cream, showAlert]
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<VeIBType>();
    const defaultColumns = [
      columnHelper.accessor('tokenId', {
        header: 'NFT ID',
      }),
      columnHelper.accessor('veIBBalance', {
        header: 'veIB Balance',
        cell: (props) => displayBalance(props.getValue(), 18, 2),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('lockedIBBalance', {
        header: 'IB staked',
        cell: (props) => displayBalance(props.getValue(), 18, 2),
        meta: { isNumeric: true },
      }),
      columnHelper.accessor('lockedEnd', {
        header: 'Unlock Date',
        cell: (props) =>
          isPast(props.getValue())
            ? 'Unlocked'
            : format(props.getValue(), 'MMM dd, yyyy'),
      }),
    ];

    return isMobile
      ? defaultColumns
      : [
          ...defaultColumns,
          columnHelper.display({
            id: 'actions',
            header: '',
            cell: ({ row: { original } }) => {
              if (!userVeIBList) {
                return null;
              }

              if (isPast(original.lockedEnd)) {
                return (
                  <Button
                    size="xs"
                    onClick={() => handleUnstake(original.tokenId)}
                  >
                    Unlock
                  </Button>
                );
              }

              return protocol.networkId === FantomProtocol.networkId ? (
                <Button
                  size="xs"
                  onClick={() => {
                    if (!userVeIBList) {
                      return;
                    }
                    // presentModal({
                    //   children: (
                    //     <VeIBMigrateModal
                    //       userClaimables={userClaimables}
                    //       veIB={original}
                    //     />
                    //   ),
                    // });
                  }}
                  disabled={true}
                >
                  Migrate
                </Button>
              ) : (
                <Button
                  size="xs"
                  onClick={() => {
                    const veData = find(userVeIBList, (item) =>
                      original.tokenId.eq(item.tokenId)
                    );
                    if (veData) {
                      presentModal({
                        children: (
                          <VeIBManageModal
                            userIBBalance={userIBBalance}
                            veIBStats={veData}
                            ibPrice={ibPrice}
                          />
                        ),
                      });
                    }
                  }}
                  disabled={protocol.networkId === FantomProtocol.networkId}
                >
                  Manage
                </Button>
              );
            },
          }),
        ];
  }, [
    handleUnstake,
    ibPrice,
    isMobile,
    presentModal,
    protocol.networkId,
    userClaimables,
    userIBBalance,
    userVeIBList,
  ]);

  const handleRowClick = useCallback(
    (row: Row<VeIBType>) => {
      const veIB = row.original;

      if (!userClaimables) {
        return;
      }

      if (isPast(veIB.lockedEnd)) {
        handleUnstake(veIB.tokenId);
        return;
      }

      if (protocol.networkId === FantomProtocol.networkId) {
        // presentModal({
        //   children: (
        //     <VeIBMigrateModal userClaimables={userClaimables} veIB={veIB} />
        //   ),
        // });
        return;
      }

      const veData = find(userVeIBList, (item) =>
        veIB.tokenId.eq(item.tokenId)
      );
      if (!veData) {
        return;
      }

      presentModal({
        children: (
          <VeIBManageModal
            userIBBalance={userIBBalance}
            veIBStats={veData}
            ibPrice={ibPrice}
          />
        ),
      });
    },
    [
      handleUnstake,
      ibPrice,
      presentModal,
      protocol.networkId,
      userClaimables,
      userIBBalance,
      userVeIBList,
    ]
  );

  return (
    <VeIBCard>
      <Flex justifyContent="space-between" mb={5}>
        <Text variant="headline2" color="primary.100">
          veIB
        </Text>
        {protocol.networkId !== FantomProtocol.networkId && (
          <Button
            size="xs"
            minW="5.75rem"
            disabled={isLoading}
            onClick={() => {
              presentModal({
                children: (
                  <VeIBStakeModal
                    userIBBalance={userIBBalance}
                    ibPrice={ibPrice}
                  />
                ),
              });
            }}
          >
            Create Lock
          </Button>
        )}
      </Flex>
      <Box>
        {isLoading ? (
          <SkeletonText mt="4" noOfLines={4} spacing="4" />
        ) : (
          <Table
            columns={columns}
            data={userVeIBList}
            onRowClick={isMobile ? handleRowClick : undefined}
          />
        )}
      </Box>
    </VeIBCard>
  );
};

export default UserVeIB;
