import { BigNumber, ethers } from 'ethers';
import { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import Erc20 from '../cream/contract/Erc20';
import { Market } from '../cream/Type';
import { ConnectionContext } from '../providers/ConnectionProvider';
import useIsNativeContract from './useIsNativeContract';
import useModal from './useModal';
import { useAlert, AlertSeverity } from 'hooks/useAlert';
import ResetAllowanceModal from 'components/Modals/ResetAllowanceModal';

export enum ApproveState {
  UNKNOWN,
  NOT_APPROVED,
  APPROVED,
}

const useApprove = (market: Market) => {
  const { signer, provider, connected, walletAddress } =
    useContext(ConnectionContext);
  const { presentModal } = useModal();
  const { showAlert } = useAlert();

  const [allowance, setAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [approveState, setApproveState] = useState(ApproveState.UNKNOWN);
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const isNative = useIsNativeContract(market.address);

  const { address: spender, underlyingAddress } = market;

  const contract = useMemo(() => {
    return new Erc20(underlyingAddress, signer || provider);
  }, [signer, underlyingAddress, provider]);

  const fetchAllowance = useCallback(async () => {
    if (!connected || !walletAddress) {
      return;
    }

    let allowance: BigNumber;
    let state: ApproveState;
    if (isNative) {
      allowance = await provider.getBalance(walletAddress);
      state = ApproveState.APPROVED;
    } else {
      allowance = await contract.allowance(walletAddress, spender);
      state = allowance.eq(0)
        ? ApproveState.NOT_APPROVED
        : ApproveState.APPROVED;
    }

    setAllowance(allowance);
    setApproveState(state);
  }, [connected, walletAddress, isNative, provider, contract, spender]);

  const resetApprove = useCallback(async () => {
    if (!connected || !walletAddress || isNative) {
      return;
    }

    await contract.approve(spender, BigNumber.from(0));
  }, [connected, contract, isNative, spender, walletAddress]);

  const approve = useCallback(
    async (amount: BigNumber) => {
      if (!connected || !walletAddress || isNative) {
        return;
      }

      if (approveState === ApproveState.APPROVED && market.zeroAllowance) {
        presentModal({
          children: <ResetAllowanceModal market={market} />,
        });
        return;
      }

      try {
        setIsApproving(true);
        const approveTx = await contract.approve(spender, amount);
        await approveTx.wait();
        fetchAllowance();
      } finally {
        setIsApproving(false);
      }
    },
    [
      connected,
      walletAddress,
      isNative,
      approveState,
      market,
      contract,
      spender,
      presentModal,
      fetchAllowance,
    ]
  );

  const approveAll = useCallback(async () => {
    try {
      await approve(ethers.constants.MaxUint256);
    } catch (error) {
      showAlert({ message: 'Approve Failed', severity: AlertSeverity.Error });
    }
  }, [approve, showAlert]);

  useEffect(() => {
    fetchAllowance().catch((err) =>
      console.error(`failed to fetch allowance: ${err.stack}`)
    );
  }, [fetchAllowance]);

  return {
    isApproving,
    approve,
    approveAll,
    approveState,
    allowance,
    resetApprove,
  };
};

export default useApprove;
