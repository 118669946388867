import { Checkbox, Stack, Text } from '@chakra-ui/react';
import { StackDirectionBreakPoint } from 'hooks/useMediaQuery';
import React from 'react';

interface MarketCardProps {
  title: string;
  children: React.ReactNode;
  isShowZero?: boolean;
  onZeroChecked?: (checked: boolean) => void;
}

const MarketCard: React.FC<MarketCardProps> = ({
  title,
  children,
  isShowZero = false,
  onZeroChecked,
}) => {
  return (
    <Stack spacing={6} bgColor="#182420" p={{ base: 4, lg: 6 }}>
      <Stack
        direction={StackDirectionBreakPoint}
        justify="space-between"
        alignItems="center"
      >
        <Text variant="moduleTitle" color="primary.100">
          {title}
        </Text>
        {onZeroChecked && (
          <Checkbox
            colorScheme="primary"
            isChecked={isShowZero}
            onChange={(event) => onZeroChecked(event.target.checked)}
          >
            <Text color="gray.100" variant="subtitle5">
              Show Asset with 0 Wallet Balance
            </Text>
          </Checkbox>
        )}
      </Stack>
      {children}
    </Stack>
  );
};

export default MarketCard;
