import { Box, Button, ButtonProps, Spinner } from '@chakra-ui/react';

interface NumberedButtonProps {
  n: number;
  children: React.ReactNode;
}

const NumberedButton = ({
  n,
  children,
  isLoading,
  ...props
}: NumberedButtonProps & ButtonProps): JSX.Element => {
  const { disabled } = props;

  return (
    <Button {...props}>
      <Box pos="relative" w="full">
        {isLoading ? (
          <Box display="flex">
            <Spinner color="primary.100" />
          </Box>
        ) : (
          <Number n={n} disabled={disabled} />
        )}
      </Box>
      <Box pos="absolute" m="auto">
        {children}
      </Box>
    </Button>
  );
};

interface NumberProps {
  n: number;
  disabled?: boolean;
}

const Number = ({ n, disabled }: NumberProps): JSX.Element => {
  return (
    <Box
      border="2px solid"
      borderColor={disabled ? 'gray.700' : 'gray.100'}
      borderRadius="full"
      w={6}
      h={6}
    >
      {n}
    </Box>
  );
};

export default NumberedButton;
