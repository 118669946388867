import { BigNumber, Contract, Signer } from 'ethers';
import { JsonRpcProvider } from '@ethersproject/providers';
import CreamETH2OracleABI from './ABIs/creamETH2Oracle';

class CreamETH2Oracle {
  private contract: Contract;

  address: string;

  constructor(address: string, provider: JsonRpcProvider | Signer) {
    this.contract = new Contract(address, CreamETH2OracleABI, provider);
    this.address = address;
  }

  exchangeRate(): Promise<BigNumber> {
    return this.contract.callStatic.exchangeRate();
  }
}

export default CreamETH2Oracle;
