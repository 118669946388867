import { useCallback, useContext, useMemo, useState } from 'react';
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Stack,
  Box,
  Switch,
} from '@chakra-ui/react';
import BN from 'bignumber.js';
import { utils } from 'ethers';
import { useIBReward, IBTokenRewardData } from 'hooks/useIBReward';
import useModal from 'hooks/useModal';
import useMarketData from 'hooks/useMarketData';
import useAlert, { AlertSeverity } from 'hooks/useAlert';
import { CreamContext } from 'providers/CreamProvider';
import { TxContext } from 'providers/TxProvider';
import { ProtocolContext } from 'providers/ProtocolProvider';
import TokenIconSymbol from 'components/TokenIconSymbol';
import Block from './components/Block';
import Info from './components/Info';

interface IBUnstakeModalProps {
  rewardData: IBTokenRewardData;
}

function IBUnstakeModal(props: IBUnstakeModalProps) {
  const { rewardData } = props;
  const { cream } = useContext(CreamContext);
  const { addTx } = useContext(TxContext);
  const { protocol } = useContext(ProtocolContext);
  const { dismissModal } = useModal();
  const { showAlert } = useAlert();
  const { refresh } = useIBReward();
  const [market] = useMarketData(rewardData.tokenAddress);
  const [isWrapped, setIsWrapped] = useState<boolean>(true);

  const isNative = useMemo(() => {
    if (!market.isWrappedBaseAsset) {
      return false;
    }

    return !isWrapped;
  }, [isWrapped, market.isWrappedBaseAsset]);

  const handleUnstake = useCallback(async () => {
    try {
      if (!cream) {
        throw new Error('Unstake error');
      }

      const tx = await cream.IBRewardUnstake(rewardData.tokenAddress, isNative);

      if (!tx) {
        throw new Error('Unstake error');
      }

      addTx(tx.hash, `Unstake and Claim IB reward`);
      dismissModal();
      await tx.wait(1);
    } catch (error) {
      console.warn('handleUnstake', error);
      showAlert({
        severity: AlertSeverity.Error,
        message: 'Unstake Failed',
      });
    } finally {
      refresh();
    }
  }, [
    addTx,
    cream,
    dismissModal,
    isNative,
    refresh,
    rewardData.tokenAddress,
    showAlert,
  ]);

  return (
    <ModalContent>
      <ModalHeader>Unstake and claim</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Stack spacing={6}>
          <Block>
            <Stack spacing={6}>
              <Info
                title={
                  <TokenIconSymbol
                    symbol={
                      isNative
                        ? protocol.chainConfig.token || ''
                        : rewardData.underlyingSymbol
                    }
                  />
                }
                value={new BN(
                  utils.formatUnits(
                    rewardData.userStakedBalance,
                    isNative ? 18 : rewardData.underlyingDecimal
                  )
                ).toFormat(4)}
              />
              <Info
                title={<TokenIconSymbol symbol="IB" />}
                value={rewardData.userClaimableIBAmount.toFormat(4)}
              />
            </Stack>
          </Block>
          {market.isWrappedBaseAsset && (
            <Block>
              <Stack spacing={6}>
                <Info
                  title={`Collect as ${protocol.chainConfig.token}`}
                  value={
                    <Box>
                      <Switch
                        isChecked={!isWrapped}
                        colorScheme="primary"
                        onChange={(event) =>
                          setIsWrapped(!event.target.checked)
                        }
                      />
                    </Box>
                  }
                />
              </Stack>
            </Block>
          )}
          <Button onClick={() => handleUnstake()}>Unstake and claim</Button>
        </Stack>
      </ModalBody>
    </ModalContent>
  );
}

export default IBUnstakeModal;
